import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink, Outlet, useOutletContext } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import currencyFormatter from '../../utils/currencyFormatter';
import ClickablePaper from '../../components/ClickablePaper';
import { safeMoment } from '../../utils/dates';

export default function PayoutsList(props) {
  const { payouts, history } = useOutletContext()

  return (
    <Box>
      <Stack spacing={1}>
        {
          _.map(payouts, (payout, i) => (
            <ClickablePaper
              key={i}
              variant="outlined"
              sx={{p: 2}}
              onClick={() => {
                history.push(`./${payout.id}`)
              }}>
              <Stack direction="row" justifyContent="space-between">
                <Box>
                  {
                    !!payout.merchant?.name ?
                      <Typography variant="body1" fontWeight={600}>
                        {
                          currencyFormatter(payout.amount)
                        } Gift Card for {
                          payout.merchant?.name
                        }
                      </Typography> :
                      <Typography variant="body1" fontWeight={600}>
                        {
                          currencyFormatter(payout.amount)
                        } Visa card
                      </Typography>

                  }

                  <Stack direction="row" spacing={2}>
                    <Box>
                      <Typography variant="body2">
                        Created {
                          safeMoment(payout.created_at).format("MM/DD/YY")
                        }
                      </Typography>
                    </Box>
                  </Stack>
                </Box>

                <Chip label={
                  <Typography variant="body4" fontWeight={700} textTransform="uppercase">
                    {payout.status}
                  </Typography>
                } />
              </Stack>
            </ClickablePaper>
          )
        )}
      </Stack>

      <Outlet />
    </Box>
  );
}
