import _ from "lodash";
import moment from "moment";
import * as React from "react";
import {
  Link as RouterLink,
} from "react-router-dom";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { ChartColumnIncreasing, CircleHelp } from 'lucide-react'

import Link from "../../components/Link";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import * as time from "../../utils/time";
import * as dates from "../../utils/dates";
import { fShortenNumber } from '../../utils/format-number';
import currencyFormatter from "../../utils/currencyFormatter";
import ClickablePaper from "../../components/ClickablePaper";

import MerchantsList from '../shop/List';

import { usePostHog } from "posthog-js/react";

export default function OffersCreate(props) {
  const {
    createOffer,
    feedItem,
    proposition,
    pick,
    deletePick,
    picks,
    merchantOffers,
    open,
    onClose,
    history
  } = props;

  const [saving, setSaving] = React.useState(false);
  const [merchantId, setMerchantId] = React.useState(null);

  const posthog = usePostHog();

  const probability = _.get(
    proposition,
    ["propositionProbabilities", 0, "probability"], 0
  )

  const prize = (pick == null || probability == null) ?
    0 :
    _.ceil(pick.revenue_cents / probability) * 10


  const save = async () => {
    try {
      setSaving(proposition.id);

      const results = await createOffer({
        proposition_id: proposition.id,
        feed_item_id: feedItem.feedItem.id,
        merchant_id: merchantId
      })

      if (_.get(results, ["errors", "message"]) == "NO_PICKS") {
        history.push(`/app/earn`)
      } else if (!!results.errors) {
        alert(results.errors.message)
      } else if (picks.length > 1) {
        deletePick(pick.id)
        history.push(`/app/activities/${results.id}?n=1`)
        onClose();
      } else {
        deletePick(pick.id)
        history.push(`/app/earn?p=1`)
      }
    } catch(err) {
      alert(err.message)
    } finally {
      setSaving(null)
    }
  };

  if (proposition == null) return <Box />

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

          <Stack spacing={1} direction="row">
            <ChartColumnIncreasing />
            <Typography variant="body3" fontWeight={700} textTransform="uppercase" >
              {
                _.ceil(probability * 100)
              }%
            </Typography>
          </Stack>

        <Box mt={2}>
          <Typography variant="body1" fontWeight={500} lineHeight={1.3} mb={0.5}>
            {feedItem.title}
          </Typography>

          <Typography
            variant="h2"
            fontWeight={500}
            lineHeight={1.2}>

            <b>{fShortenNumber(prize)} points</b> if {
              proposition.winDescription
            }.
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{px: 2}}>
        <Alert color="primary">
          <Link color="secondary" variant="body1" fontWeight={600} component={RouterLink} to="/app/merchants">
            Points win you free shopping here.
          </Link>
        </Alert>

      </DialogContent>
      <DialogActions sx={{
        p: 3
      }}>
        <LoadingButton
          onClick={save}
          loading={saving}
          variant="contained"
          size="large">
          Confirm Pick
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
