import _ from "lodash";
import moment from 'moment';
import { useSwipeable } from 'react-swipeable';
import * as React from "react";
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';
import request from '../../utils/request';
import withRouter from '../../utils/withRouter';
import { fShortenNumber } from '../../utils/format-number';
import currencyFormatter from '../../utils/currencyFormatter';
import * as offerActions from '../../actions/offers';
import * as pickActions from '../../actions/picks';
import * as cardActions from '../../actions/cards';
import FullScreenDialog from '../../components/FullScreenDialog';

import OffersCreate from '../offers/Create';
import OffersCreateError from '../offers/CreateError';

import {usePostHog} from "posthog-js/react";

function FeedItemDetails(props) {
  const {
    cards,
    fetchCards,
    feedItems,
    feedItem,
    offers,
    createOffer,
    picks,
    teams,
    user,
    params,
    history
  } = props;

  const posthog = usePostHog();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [saving, setaAving] = React.useState(false);
  const [offerOpen, setOfferOpen] = React.useState(false);
  const [proposition, setProposition] = React.useState(null);
  const [binaryProposition, setBinaryProposition] = React.useState(null);

  const userFavoriteTeams = _.chain(user.favorites)
    .filter(f => f.favoritable_type === "Team")
    .map(f => f.favoritable_id)
    .value()

  const favoriteTeam = _.find(
    feedItem.feedable?.sportsEventTeams,
    t => _.includes(userFavoriteTeams, t.team.id)
  )

  const availablePropositions = _.chain(
    feedItem.propositions
  ).reject(
    p => _.includes(_.map(offers, o => o.proposition.id), p.id)
  ).reject(
    p =>  _.get(
      p,
      ["propositionProbabilities", 0, "probability"], 0
    ) == 0
  ).value()

  React.useEffect(() => {
    if (!!favoriteTeam) {
      setProposition(
        _.chain(availablePropositions)
          .filter(p => p.teamId == favoriteTeam.teamId)
          .sample()
          .value() ||
          _.sample(availablePropositions)
      )
    } else {
      setProposition(_.sample(availablePropositions))
    }
  }, []);


  const { story, title, startAt, imageUrl, feedableType, propositions } = feedItem;
  const started = time.getTimeTill(startAt) == "Expired";

  const pick = _.last(picks);

  const probability = _.get(
    proposition,
    ["propositionProbabilities", 0, "probability"], 0
  )

  const prize = (pick == null || probability == null) ?
    0 :
    _.ceil(pick.revenue_cents / probability) * 10

  const onClose = () => history.replace("../../");

  const background = _.get(
    favoriteTeam,
    ["team", "primaryColor"],
    "rgb(0, 0, 0)"
  )

  const binary = _.find(propositions, p => {
    return _.includes(["yes", "no"], _.lowerCase(p.winDescription))
  }) != null

  const yesBinaryProposition = !!binary && _.find(propositions, p => {
    return _.lowerCase(p.winDescription) == "yes"
  })

  const yesBinaryPropositionProbability = _.get(
      yesBinaryProposition,
      ["propositionProbabilities", 0, "probability"], 0
  )

  const yesBinaryPropositionPrize = (pick == null || yesBinaryPropositionProbability == null) ?
    0 :
    _.ceil(pick.revenue_cents / yesBinaryPropositionProbability) * 10


  const noBinaryProposition = !!binary && _.find(propositions, p => {
    return _.lowerCase(p.winDescription) == "no"
  })

  const noBinaryPropositionProbability = _.get(
    noBinaryProposition,
    ["propositionProbabilities", 0, "probability"], 0
  )

  const noBinaryPropositionPrize = (pick == null || noBinaryPropositionProbability == null) ?
    0 :
    _.ceil(pick.revenue_cents / noBinaryPropositionProbability) * 10


  const binaryOffer = _.find(
    offers,
    o => o.proposition_id == noBinaryProposition?.id ||
      o.proposition_id == yesBinaryProposition?.id
  );

  return (
    <Box>
      <Box>
        <Paper
          variant="outlined"
          sx={{
            p: 3,
            borderRadius: 2,
          }}>
            <Box>
              <Stack direction="row" spacing={3} alignItems="start" alignItems="center">
                <img
                  src={imageUrl}
                  style={{
                    borderRadius: 8,
                    height: 80,
                    width: 120
                  }} />

                <Stack spacing={0.5} alignItems="start">
                  {
                      <Chip size="small" label={
                        <Typography variant="body4" fontWeight={700} textTransform="uppercase">
                          {feedItem.feedable.category || "sports"}
                        </Typography>
                      } />

                  }

                  <Typography mt={0.5} variant="body3">
                    Pick before {
                      dates.tzAwareDateTime(startAt, "MM/DD, h:mm A")
                    }
                  </Typography>
                </Stack>
              </Stack>

              <Box mt={3} mb={3}>
                {
                  binary ?
                    <Box>
                      <Typography variant={mobile ? "h1" : "h2"} fontWeight={500} lineHeight={1.3}>
                        {title}
                      </Typography>
                    </Box> :
                    <Box>
                      <Typography variant="h5" fontWeight={600} sx={{
                        lineHeight: 1.3,
                      }}>
                        {
                          title
                        }
                      </Typography>

                      <Typography variant={mobile ? "h1" : "h2"} fontWeight={500} lineHeight={1.3}>
                        <b>{!!prize ? `${fShortenNumber(prize)} points` : "Save"}</b> if {
                          proposition?.winDescription
                        }
                      </Typography>
                    </Box>
                }
              </Box>

              {
                binary ?
                  <Stack direction={{
                    sm: "row",
                    xs: "column"
                  }} spacing={{
                    xs: 1,
                    sm: 2
                  }} sx={{mb: 2}}>
                    <Stack spacing={2} sx={{flexGrow: 1}}>
                      <Button fullWidth color="primary" variant="contained" size="large" onClick={() => {
                        if (pick == null) {
                          history.push("/app/earn")
                        } else {
                          setOfferOpen(true);
                          setBinaryProposition(yesBinaryProposition);
                        }
                      }}>
                        Pick Yes {
                          !!yesBinaryPropositionPrize &&
                          `(${fShortenNumber(yesBinaryPropositionPrize)} pts)`
                        }
                      </Button>
                    </Stack>

                    <Stack spacing={2} sx={{flexGrow: 1}}>
                      <Button fullWidth color="secondary" variant="contained" size="large" onClick={() => {
                        if (pick == null) {
                          history.push("/app/earn")
                        } else {
                          setBinaryProposition(noBinaryProposition);
                          setOfferOpen(true);
                        }
                      }}>
                        Pick No {
                          !!noBinaryPropositionPrize &&
                          `(${fShortenNumber(noBinaryPropositionPrize)} pts)`
                        }
                      </Button>
                    </Stack>
                  </Stack> :
                  <Stack direction={{
                    sm: "row",
                    xs: "column"
                  }} spacing={{
                    xs: 1,
                    sm: 2
                  }} spacing={{
                    xs: 1,
                    sm: 2
                  }} sx={{mb: 2}}>
                      <Button fullWidth variant="contained" size="large"
                        onClick={() => {
                          if (pick == null) {
                            history.push("/app/earn")
                          } else {
                            setOfferOpen(true)
                          }
                      }}>
                        Pick
                      </Button>
                      <Button fullWidth variant="outlined" size="large" component={RouterLink} to={`./${feedItem.feedItem.id}`}>
                        More choices
                      </Button>
                  </Stack>


              }
            </Box>
        </Paper>
      </Box>

      <OffersCreate
        {...props}
        pick={pick}
        open={offerOpen}
        onClose={() => setOfferOpen(false)}
        createOffer={createOffer}
        feedItem={feedItem}
        proposition={
          binaryProposition || proposition
        } />
    </Box>

  )
}


const select = $$state => _.pick($$state, ["user", "offers", "cards", "teams", "feedItems", "picks", "merchantOffers"]);
export default connect(select, {
  ...offerActions,
  ...cardActions,
  ...pickActions
})(withRouter(FeedItemDetails));
