import _ from 'lodash';
import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {usePostHog} from "posthog-js/react";

import request from '../utils/request';
import TextField from './TextField';

const validationSchema = yup.object({
  number: yup
    .string()
    .required(),
});

const FidelConnect = ({ user, onSuccess, onExit }) => {
  const [card, setCard] = React.useState("");

  const posthog = usePostHog();

  const formik = useFormik({
    initialValues: {
      number: "",
    },
    validationSchema,
    onSubmit: async (values) => {
        posthog.capture("manual_card_entry", {
          user_id: user.id,
        });

        return alert("Sorry there was an error with number entry, please use Plaid connect.")

      // try {
      //   const { data } = await requestz.post("cards", values)
      //   if (data.errors) {
      //     alert(data.errors.message)
      //   } else {
      //     alert("We've been notified!")
      //     toggleOpen()
      //   }
      // } catch(err) {
      //   alert(err.message)
      // }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} id="plaid-errors-form">
      <Stack spacing={2}>
        <TextField
          label="Enter card number"
          fullWidth
          name="number"
          value={formik.values.number}
          onChange={formik.handleChange}
          error={formik.touched.number && Boolean(formik.errors.number)}
          helperText={formik.touched.number && formik.errors.number} />

          <Button fullWidth variant="contained" type="submit">
            Save
          </Button>
      </Stack>
    </form>
  )
};

export default FidelConnect;
