import _ from "lodash";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Confetti from 'react-confetti';
import * as React from "react";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { ShoppingBasket, Activity, Camera, Soup, Zap, ChevronRight } from 'lucide-react';

import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import FullScreenDialog from '../../components/FullScreenDialog';
import * as cardActions from '../../actions/cards';
import * as userActions from '../../actions/user';
import * as alertActions from '../../actions/alerts';
import request from '../../utils/request';
import currencyFormatter from '../../utils/currencyFormatter';
import * as dates from "../../utils/dates";
import withRouter from '../../utils/withRouter';

export default function OffersRewarded(props) {
  const navigate = useNavigate();

  const [reward, setReward] = React.useState(null)
  const [loading, setLoading] = React.useState(true);
  const [isExploding, setIsExploding] = React.useState(false);

  const onClose = () => setReward(null);

  React.useEffect(() => {
    const reward = window.localStorage.getItem("reward");

    if (!!reward) {
      window.localStorage.removeItem("reward");
      setReward(reward)
    }
  }, []);

  if (!reward) return <Box />

  return (
    <Dialog open={true} fullWidth maxWidth="xs" onClose={onClose}>
      {
        false &&
          <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
        />
      }

      <DialogTitle sx={{p: 3}}>
        <Box>
          {
            reward == "true" ?
              <Box>
                <Typography variant="h2" fontWeight={600}>
                  Start Saving NOW
                </Typography>
              </Box> :
              <Box>
                <Typography variant="h2" fontWeight={600}>
                  Nice Choice! Win More...
                </Typography>
              </Box>
          }

          <Typography variant="h4" lineHeight={1.2} fontWeight={400}>
            Gravy means saving when cool things happen in sports,
            news, and culture.
          </Typography>

        </Box>
      </DialogTitle>
      <DialogContent sx={{p: 3}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ClickablePaper
              variant="outlined"
              sx={{px: 2, py: 4}}
              onClick={() => {
                window.open("https://app.itsgravy.co/")
              }}>
                <Box>
                  <Stack direction="row" justifyContent="space-between">
                    <Box>
                      <Avatar sx={{bgcolor: "#ff634a"}}>
                        <Camera />
                      </Avatar>

                      <Typography mt={1} variant="body1" fontWeight={600}>
                        Upload Shopping Receipts
                      </Typography>
                    </Box>
                    <ChevronRight />
                  </Stack>
                </Box>

                <Typography variant="body2" color="text.secondary">
                  Up to 100% cash back on purchases
                </Typography>
            </ClickablePaper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{p: 3}}>
        <Button variant="contained" onClick={() => {
          window.open("https://app.itsgravy.co")
        }}>
          Check it out
        </Button>

        <Button onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )

}
