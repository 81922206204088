import _ from 'lodash';
import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default function MarketingTerms(props) {
  return (
    <Container maxWidth="md" sx={{mt: 3, mb: 3}}>
      <Box>
        <Typography variant="body1" fontWeight={600}>
          Terms of Service
        </Typography>

        <Typography variant="body2" fontWeight={600}>
          Last updated: April 9, 2024
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2">
          These TailRisk Terms of Service (“Terms”) are a legal agreement between you and TailRisk, Inc. ("TailRisk" or "we"), that govern your access to and use of the Services (as defined below). Please review these Terms before you decide whether to accept them and continue with the registration process.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          By accepting this Agreement, you represent that you are:
        </Typography>
      </Box>

      <Box mt={1}>
        <Typography variant="body2">
          A business or private entity duly authorized to do business in the country or countries where you operate; and
          You, your employees, officers, representatives, and other agents accessing the Services are at least 18 years of age, capable of entering into a legally binding agreement, and duly authorized to access the Services and to legally bind you to these Terms and all transactions conducted under their respective usernames and passwords.
          By agreeing to these Terms, you authorize TailRisk to charge your Payment Instrument when you incur transactions or fees using any of the Services offered hereunder. These Terms are divided into the following parts:
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          We reserve the right to change, modify, add / or remove all or part of these Terms at any time and in our sole discretion.
        </Typography>
      </Box>

      <Box mt={1} mb={3}>
        <Typography variant="body2">
          If we make changes to these Terms, we will provide notice of such changes by posting such changes on our websites or mobile applications and updating the “Last Updated” date at the top of these Terms. We encourage you to review these Terms each time you make a purchase through TailRisk to ensure you understand the terms and conditions that apply to such purchase. Your continued use of the Services indicates your acceptance of any changes to the Terms.
        </Typography>
      </Box>

      <Divider />

      <Box mt={3}>
        <Typography variant="body1" fontWeight={600}>
          Definitions
        </Typography>

        <Typography variant="body2">
          Definitions appearing in these Terms of Service
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          A. Payment Instrument
        </Typography>

        <Typography variant="body2">
          A blockchain wallet address, credit card, debit card, ACH-enabled checking or savings account, and/or other financial account that is registered in your TailRisk account.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          B. Payout Instrument
        </Typography>

        <Typography variant="body2">
          A disbursement to ACH-enabled checking or savings account, debit card, PayPal account, gift card, and/or other financial account that is registered in a TailRisk account.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          C. Platform
        </Typography>

        <Typography variant="body2">
          An integrated online service that enables you to order, view and manage orders through either a hosted website or API connection to the Service.
        </Typography>
      </Box>

      <Box mt={2} mb={3}>
        <Typography variant="body2" fontWeight={600}>
          I. Website
        </Typography>

        <Typography variant="body2">
          The TailRisk.ai website and any successor thereto.
        </Typography>
      </Box>

      <Divider />

      <Box mt={3} mb={3}>
        <Box>
          <Typography variant="body1" fontWeight={600}>
            The TailRisk Services
          </Typography>
          <Typography variant="body2">
            TailRisk provides smart tools to online daters.
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Enrollment
          </Typography>
          <Typography variant="body2">
            TailRisk may reject enrollment and refuse access to or use of our Services to anyone at any time, in its sole discretion.
            ‍
            After due diligence is complete and if you are approved, we will open an Account for you, and you will set the Account username and password. Usernames and passwords are confidential and should only be shared with your trusted employees and officers on a need-to-know basis. Usernames and passwords must not be distributed or disclosed by you or your employees, officers, representatives, and other agents to third parties. You are responsible for all activity on your Account, regardless if such activity was initiated or authorized by you.

            During the period of time that you maintain an Account with us, you agree to maintain a valid email address where we may deliver information, notifications and disclosures to you. You will promptly notify us of any change in your email address. You understand that our ability to provide you this information is dependent upon this maintenance of a valid email address.                    ‍
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Account Transactions and Disputes
          </Typography>
          <Typography variant="body2">
            You may access your Account and place and manage orders through the Platform. So long as your Account with us is active, you may also access your order history for at least the previous 12 months. You are responsible for monitoring your Account activity and reporting any erroneous or unauthorized transactions or other activity to us. We must hear from you
            in writing at help@TailRisk.ai no later than 5 days after the date of the transaction or activity at issue,
            and you must provide the following information: (i) your name; (ii) a description of the error or unauthorized activity, and an explanation as to why you believe it is an error or why you need more information; (iii) the dollar amount of the suspected error; and (iv) your Account username .
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Fees
          </Typography>
          <Typography variant="body2">
            You agree to pay all fees applicable for a given order and other TailRisk Services, as revised from time to time. The then-applicable fees will be displayed to you at checkout.
            We may change fees at any time, with or without notice to you, and your continued use of the Service constitutes your acceptance of any such change.
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Restricting Access to Account
          </Typography>
          <Typography variant="body2">
            We may restrict access to your Account to allow us to investigate the use of your Account, resolve any pending dispute, in response to a court order or if otherwise requested by law enforcement or any governmental entity, for the time that it takes for us to complete any pending investigation or resolve a pending dispute.
          </Typography>
        </Box>
      </Box>

      <Divider />

      <Box mt={3}>
        <Box>
          <Typography variant="body1" fontWeight={600}>
            Additional Terms
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Disclaimer of Liability
          </Typography>
          <Typography variant="body2" gutterBottom>
            You agree to release us, our affiliates, and our respective agents, contractors, officers and employees, from all claims, demands and damages (actual and consequential) arising out of or in any way connected with a dispute. You agree that you will not involve us in any litigation or other dispute arising out of or related to any transaction, agreement, or arrangement with any order Vendor or other third party in connection with the Service. If you attempt to do so,
            (i) you shall pay all costs and attorneys' fees of us, our affiliates, and shall provide indemnification as set forth below, and (ii) the jurisdiction for any such litigation or dispute shall be limited as set forth below.
          </Typography>


          <Typography variant="body2" gutterBottom>
            If you are a California resident, you hereby expressly waive California Civil Code 1542, which states: "A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if not known by him must have materially affected his settlement with the debtor."
            Each party acknowledges that the other party has entered into these Terms relying on the limitations of liability stated herein and that those limitations are an essential basis of the bargain between the parties. ‍
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Indemnification
          </Typography>
          <Typography variant="body2" gutterBottom>
            You agree to indemnify, defend and hold harmless us, our subsidiaries and other affiliates, and our and their directors, officers, owners,
            agents, co-branders or other partners, employees, information providers, licensors, licensees,
            consultants, contractors and other applicable third parties (including without limitation,
            Payment, or Payout Vendors) (collectively "Indemnified Parties") from and against any and all
            claims, demands, causes of action, debt or liability, including reasonable attorneys’ fees,
            including without limitation attorneys’ fees and costs incurred by the Indemnified Parties
            arising out of, related to, or which may arise from:
          </Typography>

          <Typography variant="body2" gutterBottom>
            1. Any breach or non-compliance by you of any term of these Terms or any other terms posted on the
            Website;
          </Typography>
          <Typography variant="body2" gutterBottom>
            2. Any dispute or litigation caused by your actions or omissions; or
            your negligence or violation or alleged violation of any applicable law or rights of a third party.
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Termination or Suspension
          </Typography>
          <Typography variant="body2" gutterBottom>
            We reserve the right to close your Account or take other appropriate actions if you violate these Terms or any policies or terms on the
            Website in whole or part.We may modify or close your Account for any reason or no reason at any time with or without notice, and without liability of any kind or nature to you or any third party. We may also suspend your access to your Account if you (a) have violated these Terms, (b) pose an
            unacceptable risk to us, or (c) provide any false, incomplete, inaccurate, or misleading information or
             otherwise engage in fraudulent or illegal conduct.
          </Typography>

          <Typography variant="body2" gutterBottom>
            If your Account is terminated for any reason or no reason you agree:
            (a) to immediately stop using your Account;
            (b) that we reserve the right, but have no obligation,
            to delete your information and Account data stored on our
            servers; and (c) that we shall not be liable to you or
            any third party for termination of your account or deletion
            of your information or Account data.You may terminate or
            close your Account at any time by providing written notice to us.
            Upon closure of an Account, any pending orders will be
            fulfilled, but you will not be able to place any new related
            orders or change any pending orders.
            Notwithstanding any termination, you will remain liable
            for all amounts due in connection with your Account,
            including Purchase Amounts up to and including
            the date of termination.
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Privacy
          </Typography>
          <Typography variant="body2" gutterBottom>
            Please refer to our Privacy Policy for information about how we collect, use and disclose information and data
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Privacy
          </Typography>
          <Typography variant="body2" gutterBottom>
            Please refer to our Privacy Policy for information about how we collect, use and disclose information and data
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Proprietary Rights
          </Typography>
          <Typography variant="body2" gutterBottom>
            As between TailRisk and you, TailRisk retains all right, title and interest in and to the Services, TailRisk Materials, and all intellectual property rights therein (the “IP”). You will not acquire any ownership interest or license rights in or to the Services, TailRisk Materials, intellectual property rights in any of the foregoing. If any right, title or interest in and to any IP is deemed to vest in you, you hereby assign and agree to
            assign to us all worldwide right, title, and interest in and to such IP. All rights not otherwise stated in these Terms are reserved to TailRisk. The rights granted to you under these Terms are non-exclusive and nothing will limit the ability of
            TailRisk to market, sell, offer for sale, license or otherwise exploit the Services, TailRisk Materials, or other IP to any third parties or to appoint or authorize any other person or entity to do the same.
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Severability
          </Typography>
          <Typography variant="body2" gutterBottom>
            If any provision of these Terms shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions.
          </Typography>
        </Box>


        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Entire Agreement, Severability, Affiliates
          </Typography>
          <Typography variant="body2" gutterBottom>
            These Terms constitute the entire agreement between you and TailRisk with respect to your use of TailRisk and the Services and supersedes any other agreement, proposals and communications, written or oral, between TailRisk and you with respect to the subject matter hereof. If a court should find that one or more rights or provisions contained herein are invalid, you agree that the remainder of the Terms shall be enforceable.
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Severability
          </Typography>
          <Typography variant="body2" gutterBottom>
            If any provision of these Terms shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions.
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Third Party Fees
          </Typography>
          <Typography variant="body2" gutterBottom>
            You are responsible for any fees charged by your telecommunications provider, Payment Instrument, Reward Vendor or any other third party in connection with your use of the Services.
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Responsibility for Taxes
          </Typography>
          <Typography variant="body2" gutterBottom>
            The reporting and payment of any applicable taxes arising from the use of the Services is your responsibility. You hereby agree to comply with any and all applicable tax laws in connection with your use of the Services.
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            No Endorsement of Products
          </Typography>
          <Typography variant="body2" gutterBottom>
            We do not represent or endorse, and shall not be responsible for:

            the reliability or performance of any related Vendor or third party service provider;
            the safety, quality, accuracy, reliability, integrity or legality of any order or any part of the Service;
            We hereby disclaim any liability or responsibility for errors or omissions in any content
            in the Services. We reserve the right, but shall have no responsibility,
            to edit, modify, refuse to post or remove any content, in whole or in part,
            that in its sole and absolute discretion is objectionable, erroneous, illegal,
            fraudulent or otherwise in violation of these Terms.
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Disclaimer
          </Typography>
          <Typography variant="body2" gutterBottom>
            THE SERVICES, INCLUDING ALL CONTENT, SOFTWARE, FUNCTIONS, MATERIALS, AND INFORMATION MADE AVAILABLE ON, PROVIDED IN CONNECTION WITH OR ACCESSIBLE THROUGH THE SERVICES, ARE PROVIDED "AS IS." TO THE FULLEST EXTENT PERMISSIBLE BY LAW, WE, OUR SUBSIDIARIES AND OTHER AFFILIATES, VENDORS, AND THEIR AGENTS (COLLECTIVELY, "TailRisk PARTIES"), MAKE NO REPRESENTATION OR WARRANTY OF ANY KIND WHATSOEVER FOR THE SERVICES OR THE CONTENT, MATERIALS, INFORMATION AND FUNCTIONS MADE ACCESSIBLE BY THE SOFTWARE USED ON OR ACCESSED THROUGH THE SERVICES, OR FOR ANY BREACH OF SECURITY ASSOCIATED WITH THE TRANSMISSION OF SENSITIVE INFORMATION THROUGH THE SERVICES. EACH TailRisk PARTY DISCLAIMS WITHOUT LIMITATION, ANY WARRANTY OF ANY KIND WITH RESPECT TO THE SERVICES, NONINFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. THE TailRisk PARTIES DO NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE. THE TailRisk PARTIES SHALL NOT BE RESPONSIBLE FOR ANY SERVICE INTERRUPTIONS, INCLUDING, BUT NOT LIMITED TO, SYSTEM FAILURES OR OTHER INTERRUPTIONS THAT MAY AFFECT THE SERVICES.
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Force Majeure
          </Typography>
          <Typography variant="body2" gutterBottom>
            No TailRisk Party shall have any liability for any failure or delay resulting from any condition beyond the reasonable control of such party, including but not limited to governmental action or acts of terrorism, earthquake, fire, flood or other acts of God, labor conditions, power failures and Internet disturbances.
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Notice
          </Typography>
          <Typography variant="body2" gutterBottom>
            You agree that all statements, notices and other communications to you may be made by mail, email, postings on the Website or other reasonable means. We may also provide notices of changes to the Terms or other matters by displaying links to notices on the Website. Notice to TailRisk may be made by mail to:
          </Typography>
          <Typography variant="body2">
            <div>TailRisk, Inc</div>
            <div>101 Colorado Street, Austin, TX 78701</div>
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Assignment
          </Typography>
          <Typography variant="body2" gutterBottom>
            You may not assign these Terms or any rights or obligations hereunder, by operation of law or otherwise, without our prior written approval and any such attempted assignment shall be void. We reserve the right to freely assign these Terms and the rights and obligations hereunder, to any third party without notice or consent. Subject to the foregoing, these Terms shall be binding upon and inure to the benefit of the parties hereto, their successors and permitted assigns.
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" fontWeight={600}>
            Survivial
          </Typography>
          <Typography variant="body2" gutterBottom>
            The failure of TailRisk to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right or provision. If any provision of these Terms shall be adjudged by any court of competent jurisdiction to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that these Terms shall otherwise remain in full force and effect and remain enforceable between the parties. Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section. These Terms, including the Reward Vendor terms and conditions and other terms governing the Services referenced herein, constitute the entire agreement between you and TailRisk with respect to the use of the Services. These Terms are not intended and shall not be construed to create any rights or remedies in any parties other than you and TailRisk, and no other person shall assert any rights as a third party beneficiary hereunder.
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}
