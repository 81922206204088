import _ from "lodash";
import { Helmet } from 'react-helmet';
import * as React from "react";
import Promise from 'bluebird';
import { Outlet, Navigate, Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import withRouter from '../../utils/withRouter';
import * as userGroupActions from '../../actions/userGroups';

import TopNavBar from '../marketing/TopNavBar';
import Footer from '../marketing/Footer';
import Loader from '../../components/Loader';
import Link from '../../components/Link';

function UserGroupsMain(props) {
  const {
    user,
    userGroups,
    fetchUserGroups,
    history,
  } = props;

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  const fetchData = async () => {
    if (userGroups.length == 0) {
      await fetchUserGroups()
    }
  }

  const breadcrumbs = [
    <Link
      color="primary"
      underline="hover"
      component={RouterLink}
      to="../">
      <Typography variant="body3" fontWeight={600}>
        Account
      </Typography>
    </Link>,
    <Typography variant="body3" color="text.secondary" fontWeight={600}>
      Groups
    </Typography>,
  ];

  return (
    <Box>
      <Container maxWidth="md">
        <Box mb={2}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
        </Box>

        {
          loading ?
            <Loader /> :
            <Outlet />
        }
      </Container>
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user", "userGroups"]);
export default connect(select, {
  ...userGroupActions,
})(withRouter(UserGroupsMain));
