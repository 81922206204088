import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Alert from '../../components/Alert';

export default function ReceiptsDetails(props) {
  const { open, onClose } = props;

  const naviage = useNavigate()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box mt={2}>
          <Typography variant="h2" fontWeight={600}>
            Pour Gravy on receipts
          </Typography>

          <Typography variant="body1" color="text.secondary" fontWeight={500}>
            Up to 100% cash back on your purchases when cool things happen in the sports and culture.
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <Button
          href="https://app.itsgravy.co/"
          target='_blank'
          size="large"
          variant="contained"
          color="primary">
          Open
        </Button>
      </DialogActions>
    </Dialog>

  );
}
