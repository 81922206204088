import _ from "lodash";
import * as React from 'react';
import { Outlet, Navigate } from "react-router-dom";
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import withRouter from '../../utils/withRouter';

import Footer from './Footer';

function OnboardingLeagues(props) {
  const {
    user,
    saveFavorites,
    leagues,
    history,
  } = props;

  const activeLeagues =  _.reject(
    leagues,
    l => _.includes(["mls", "wnba", "atp"], _.lowerCase(l.abbrv))
  );

  const favoriteLeagues = _.chain(user)
    .get(["favorites"], [])
    .filter(f => f.favoritable_type == "League")
    .map(f => f.favoritable_id)
    .value();

  const formik = useFormik({
    initialValues: {
      leagues: favoriteLeagues
    },
    enableReinitialize: true,
    onSubmit: async ({leagues}) => {
      try {
        const results = await saveFavorites(
          _.map(leagues, id => {
            return {
              favoritable_id: id,
              favoritable_type: "League"
            }
          })
        )

        if (results.errors) {
          alert(results.errors.message)
        } else {
          history.push(`../teams`)
        }
      } catch(err) {
        alert(err.message)
      }
    },
  });

  if (user.id && user.status != "unregistered") return <Navigate to="/app" />;

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={2}>
          <Typography variant="body1" fontWeight={600}>
            Which sports do you care about?
          </Typography>

          <Typography variant="body2" color="text.secondary" fontWeight={500}>
            Pick as many as you'd like.
          </Typography>
        </Box>

        <Box mb={2}>
        </Box>

          <List direction="column">
            {
              _.map(activeLeagues, (league, i) => {
                  return (
                    <ListItem key={i} disablePadding divider={i < activeLeagues.length - 1}>
                      <ListItemButton onClick={() => {
                        formik.setFieldValue(
                          "leagues",
                          _.xor(formik.values.leagues, [league.id])
                        )
                      }}>
                        <ListItemIcon>
                          <Checkbox
                            color="secondary"
                            disableRipple
                            edge="start"
                            checked={_.includes(formik.values.leagues, league.id)}
                            tabIndex={-1}
                          />
                        </ListItemIcon>

                        <ListItemText>
                          <Typography variant="body2" color="text.primary" fontWeight={500}>
                            {
                              _.upperCase(league.abbrv)
                            }
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  )
                })
            }
          </List>

        <Footer
          disabled={formik.values.leagues.length == 0}
          loading={formik.isSubmitting}
          progress={33} />
      </form>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "leagues");
export default connect(select, {
})(withRouter(OnboardingLeagues));
