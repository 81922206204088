import _ from "lodash";
import * as React from "react";
import { Outlet } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export default function HelpMain(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box py={6}>
      <Container maxWidth="lg">
        <Typography textAlign="center" color="primary" style={{
          fontSize: mobile ? "1.3em" : "2.3em",  
          fontWeight: 600,
          lineHeight: "1.5",
          letterSpacing: "-0.03rem"
        }}>
          You've got questions
        </Typography>

        <Typography variant="h3" textAlign="center" fontWeight={500}>
          We've got answers
        </Typography>

        <Typography mt={2} variant="body2" textAlign="center" fontWeight={500}>
          You can also email us <Link color="primary" underline="hover" href={"mailto:help@itsgravy.co"}>help@itsgravy.co</Link>
        </Typography>

        <Box mb={6} mt={{
          xs: 2,
          md: 6
        }}>
          <Outlet />
        </Box>
      </Container>
    </Box>
  );
}
