import _ from "lodash";
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { ThumbsDown, ThumbsUp } from 'lucide-react';

import * as dates from "../../utils/dates";
import currencyFormatter from "../../utils/currencyFormatter";
import request from "../../utils/request";
import { fShortenNumber } from '../../utils/format-number';

export default function OffersCard(props) {
  const {
    id,
    title,
    image_url,
    expires_at,
    prize,
    proposition,
    created_at,
    user,
    offer,
    status,
    createAlert
  } = props;

  const emojis = {
    won: "🎉",
    lost: "💔",
    activated: "🤞",
    verified: "🤞"
  }

  const comment = async direction => {
    try {
      const { data } = await request.post(`offers/${id}/comment`, {
        direction
      })

      if (!data.errors) {
        createAlert({
          title: `Gravy told @${offer.user.username} you ${direction} this pick :)`
        })
      }

    } catch(err) {

    }
  }

  const love = async () => {
    comment("loved")
  }

  const hate = async () => {
    comment("hated")
  }

  console.log(offer.prize)
  return (
    <Paper variant="outlined" sx={{
      p: 2
    }}>
      <Stack direction="row" spacing={{
        sm: 2,
        xs: 1
      }}>
        <Typography style={{fontSize: 30}}>
          {
            emojis[status]
          }
        </Typography>

        <Box>
          <Typography
            variant="body2"
            fontWeight={700}>
            {
              offer?.prize?.description
            }
          </Typography>

          <Typography
            variant="body2"
            fontWeight={500}>
            If {
              _.capitalize(proposition.title)
            }
          </Typography>
          <Typography
            variant="body2">
            {title}
          </Typography>

          {
            offer.user.username != user.username &&
              <Typography
                variant="body2">
                @{ offer.user.username } on {
                  dates.tzAwareDateTime(created_at, "MM/DD")
                }
              </Typography>
          }

        </Box>
      </Stack>

      {
        user.username != offer.user.username &&
          <Stack direction="row" justifyContent="end">
            <IconButton onClick={hate}>
              <ThumbsDown size={16} />
            </IconButton>
            <IconButton>
              <ThumbsUp size={16} onClick={love} />
            </IconButton>
          </Stack>
      }
    </Paper>
  );
}
