import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";
import * as alerts from './alerts';

export const fetchOffers = () => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`offers`, {
      params: {
        limit: 50,
        offset: 0,
      }
    });

    if (!data.errors) {
      dispatch({
        type: actionTypes.offers.INDEX,
        payload: {
          offers: _.map(data, c => {
            return {
              id: c.id,
              ...c.attributes
            }
          })
        }
      });
    }

    return data;
  }
};

export const createOffer = (payload) => {
  return async (dispatch, getState) => {
    const { data } = await request.post(`offers`, payload)

    if (!data.errors && data.id) {
      dispatch({
        type: actionTypes.offers.CREATE,
        payload: {
          offer: {
            id: data.id,
            ...data.attributes
          }
        }
      });
    }

    return data
  }
}


export const deleteOffer = (id) => {
  return async (dispatch, getState) => {
    const { data } = await request.delete(`offers/${id}`);

    if (!data.errors) {
      dispatch({
        type: actionTypes.offers.DELETE,
        payload: {
          offer: {
            id,
          }
        }
      });
    }

    return data
  }
}