import _ from "lodash";
import moment from 'moment';
import * as React from "react";
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { ChevronRight } from 'lucide-react'

import Link from '../../components/Link';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import FullScreenDialog from '../../components/FullScreenDialog';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';
import request from '../../utils/request';
import currencyFormatter from '../../utils/currencyFormatter';

import Favorites from '../favorites/Teams';
import AuthButton from '../user/AuthButton';

export default function RewardsFeed(props) {
  const {
    reward,
    user,
    merchantOffers,
    history,
  } = useOutletContext();

  const onClose = () => history.push(`/rewards/${reward.token}`, {replace: true});

  console.log(merchantOffers)
  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box mt={2}>
          <Typography variant="h3" fontWeight={600}>
            Which gift card brand would you like?
          </Typography>
          <Typography variant="h4" fontWeight={400} lineHeight={1.3}>
            You'll get $25 of this gift card if you win.
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {
            _.map(merchantOffers, (mo, i) => {
              const logo = mo.merchant.logo_url ||
                _.get(mo.merchant, ["logo", "secure_url"]);

              return (
                <Grid item xs={6} md={4} key={i}>
                  <ClickablePaper variant="outlined" p={2} sx={{
                    height: {
                      xs: 200,
                      md: 200
                    },
                    display: "flex",
                  }} onClick={() => {
                    history.push(`./${mo.id}`)
                  }}>
                    <Stack alignItems="center" justifyContent="center" sx={{flexGrow: 1}} spacing={2}>
                      <img src={logo} style={{
                        height: 80,
                        borderRadius: 5
                      }} />

                      <Button variant="outlined" size="small">
                        Pick
                      </Button>
                    </Stack>
                  </ClickablePaper>
                </Grid>
              )
            })
          }
        </Grid>
      </DialogContent>
      <DialogActions sx={{
        p: 3,
      }}>
        <Button onClick={history.goBack}>
          Back
        </Button>
      </DialogActions>
    </Dialog>

  )
}
