import "./utils/instrument.ts";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/fonts.css';

import App from './App';
// import reportWebVitals from './reportWebVitals';

import {LicenseInfo} from '@mui/x-license';
import {PostHogProvider} from "posthog-js/react";

LicenseInfo.setLicenseKey('09060189102726fbb37eb37e3d94c283Tz04OTgxOCxFPTE3NDY2MzAwOTIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const options = {
    api_host: "https://us.i.posthog.com"
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <PostHogProvider
        apiKey={"phc_KuWAsSevYrjObkDxVxyJos2nVfXW5hdyud1XbjWtvp4"}
        options={options}
    >
        <App/>
    </PostHogProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
