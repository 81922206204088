import _ from "lodash";
import Promise from 'bluebird';
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet } from "react-router-dom";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Typography from '@mui/material/Typography';

import * as payoutActions from '../../actions/payouts';
import withRouter from '../../utils/withRouter';
import Link from '../../components/Link';

import List from './List';

function PayoutsMain(props) {
  const { payouts, fetchPayouts, history } = props;

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  const fetchData = async () => {
    if (payouts.length == 0) fetchPayouts()
  }

  const breadcrumbs = [
    <Link
      color="primary"
      underline="hover"
      component={RouterLink}
      to="../">
      <Typography variant="body3" fontWeight={600}>
        Account
      </Typography>
    </Link>,
    <Typography variant="body3" color="text.secondary" fontWeight={600}>
      Savings
    </Typography>,
  ];

  return (
    <Box pb={10}>
      <Container maxWidth="sm">
        <Box mb={2}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
        </Box>

        <Outlet context={{
          payouts,
          history
        }} />
      </Container>
    </Box>
  );
}


const select = $$state => _.pick($$state, "payouts");
export default connect(select, {
  ...payoutActions
})(withRouter(PayoutsMain));
