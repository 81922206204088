import _ from "lodash";
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';

import request from '../../utils/request';
import * as environment from '../../environment';

function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (elements == null) return;

    setIsLoading(true)

    const response = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      confirmParams: {
        // return_url
      }
    });

    if (response.error) {
      alert(response.error.message)
    } else {
      props.onSubmit(response)
    }

    setIsLoading(false)
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement onReady={() => {}}/>
      <Box mt={2}>
        <LoadingButton type="submit" fullWidth variant="contained" loading={isLoading}>
          Add Card
        </LoadingButton>
      </Box>
    </form>
  );
}

const stripePromise = loadStripe(environment.stripeApiKey);

export default function CreditCardNew(props) {
  const [loaded, setLoaded] = useState(false);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if (!clientSecret) {
      request.post("payment_methods", {})
        .then(response => {
          setClientSecret(response.data.client_secret)
        });
    }
  }, []);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  if (!clientSecret) return <Box sx={{minWidth: 300}} />

  return (
    <Paper variant={"outlined"} sx={{p: 3}}>
      <Elements options={options} stripe={stripePromise}>
        <CheckoutForm onSubmit={props.onSubmit} />
      </Elements>
    </Paper>
  );
}