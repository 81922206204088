import _ from 'lodash';
import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import {Title, GreySection, HeroImage} from './Components';

const SellerExperience = ({setExperience}) => (
  <Grid container spacing={{
    xs: 6,
    sm: 12
  }}>
    <Grid item xs={12} sm={4}>
      <Typography variant="h2">
        Message AI
      </Typography>

      <Typography variant="body2">
        List your gift card for sale, set your terms,
        and receive real money.
      </Typography>

      <Box mt={3}>
        <Button
          fullWidth variant="outlined"
          size="large"
          sx={{
            border: "1px solid #fff",
            color: "#fff",
            p: 2,
            borderRadius: 20
          }}
          onClick={() => setExperience("buyer")}>
          View Buyer Experience
        </Button>
      </Box>
    </Grid>
    <Grid item xs={12} sm={6}>
      <Box mb={4}>
        <Stack direction="row" spacing={1}>
          <Typography  variant="h4" fontWeight={600}>
            #1
          </Typography>
          <Box>
            <Typography  variant="h4" fontWeight={600}>
              List your card
            </Typography>
            <Typography  variant="body2">
              Upload your gift card code and set your price.
              We will perform a balance check with the merchant to confirm
              your available balance. We will notify you when buyers bid on your gift card.
            </Typography>
          </Box>
        </Stack>
      </Box>


      <Box mt={4} mb={4}>
        <Stack direction="row" spacing={1}>
          <Typography  variant="h4" fontWeight={600}>
            #2
          </Typography>
          <Box>
            <Typography  variant="h4" fontWeight={600}>
              Accept a sale
            </Typography>
            <Typography  variant="body2">
              When you accept a sale, funds from the buyer will be stored in
              a smart contract pending the final step described below.
              During this stage, you can earn interest on the escrowed funds (if you choose to hold the funds as USDC).
              Alternatively, if you hold the funds as BTC or ETH,
              those values will vary according to the market.
            </Typography>
          </Box>
        </Stack>
      </Box>


      <Box mt={4} mb={6}>
        <Stack direction="row" spacing={1}>
          <Typography  variant="h4" fontWeight={600}>
            #3
          </Typography>
          <Box>
            <Typography  variant="h4" fontWeight={600}>
              Receive your funds
            </Typography>
            <Typography  variant="body2">
              The buyer will have 45 days to view the gift card code. Once they do so (or 45 days have passed),
              the funds will be irreversibly transferred from the smart contract to your Loyaltyworks wallet or your own third party wallet.
            </Typography>
            <Typography mt={2}  variant="body2">
              Prior to presenting the gift card code to the buyer, we will again validate
              that the available balance has not changed. If it has, and the buyer has not yet viewed the code, the buyer will be refunded.
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Grid>
  </Grid>
);

const BuyerExperience = ({setExperience}) => (
  <Grid container spacing={{
    xs: 6,
    sm: 12
  }}>
    <Grid item xs={12} sm={4}>
      <Typography variant="h2" >
        Buyer Experience
      </Typography>

      <Typography  variant="body2">
        Purchase a deeply discounted gift card with an available
        balance confirmed by Loyaltyworks. You have 45 days to view your code.
      </Typography>

      <Box mt={3}>
        <Button
          fullWidth variant="outlined"
          size="large"
          sx={{
            border: "1px solid #fff",
            color: "#fff",
            p: 2,
            borderRadius: 20
          }}
          onClick={() => setExperience("seller")}>
          View Seller Experience
        </Button>
      </Box>
    </Grid>
    <Grid item xs={12} sm={6}>
      <Box mb={4}>
        <Stack direction="row" spacing={1}>
          <Typography  variant="h4" fontWeight={600}>
            #1
          </Typography>
          <Box>
            <Typography  variant="h4" fontWeight={600}>
              Bid on a gift card
            </Typography>
            <Typography  variant="body2">
              Buy from a wholeseller like Loyaltyworks or from an individual
              seller with the deepest possible discounts. Enable Loyaltyworks to transfer funds
              from your wallet when your bid is accepted.
            </Typography>
          </Box>
        </Stack>
      </Box>


      <Box mt={4} mb={4}>
        <Stack direction="row" spacing={1}>
          <Typography  variant="h4" fontWeight={600}>
            #2
          </Typography>
          <Box>
            <Typography  variant="h4" fontWeight={600}>
              Transfer your funds and receive ownership
            </Typography>

            <Typography  variant="body2">
              When your bid is accepted, funds from your wallet will be transferred to
              a smart contract that escrows the funds for the seller. You will receive an NFT which
              you can use at any time to reveal the encrypted gift card code.
            </Typography>
          </Box>
        </Stack>
      </Box>


      <Box mt={4} mb={6}>
        <Stack direction="row" spacing={1}>
          <Typography variant="h4" fontWeight={600}>
            #3
          </Typography>
          <Box>
            <Typography variant="h4" fontWeight={600}>
              View your gift card code
            </Typography>
            <Typography  variant="body2">
              You can view the gift card code at any time for the next 45 days.
              When revealed, the funds held in the contract will be transferred to the seller.
            </Typography>

            <Typography mt={2}  variant="body2">
              Before your code is revealed, a balance check will be performed by Loyaltyworks.
              If the balance is discrepant with the listed value at sale, you will be fully refunded.
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Grid>
  </Grid>
);

export default function MarketingHowContainer(props) {
  const [experience, setExperience] = React.useState("seller");

  return (
    <Box sx={{
      borderBottom: "1px solid #fff"
    }}>
      <Container maxWidth="lg">
        {
          experience == "seller" ?
            <SellerExperience setExperience={setExperience} /> :
            <BuyerExperience setExperience={setExperience} />
        }
      </Container>
    </Box>
  )
}
