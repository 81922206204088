import _ from "lodash";

import actionTypes from "../constants/index";

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.sports.INDEX:
      return payload.sports;
    default:
      return $$state;
  }
};
