import moment from "moment";

export const getTimeTill = (dateTime) => {
  if (dateTime == null) return;

  const now = moment(); // Current time
  const targetDateTime = moment.utc(dateTime, "YYYY-MM-DD HH:mm:ss A").tz('UTC'); // Target date/time

  const diff = moment.duration(targetDateTime.diff(now)); // Calculate difference

  // Check the difference in various units
  if (diff.asSeconds() < 0) {
    return "Expired"
  } else if (diff.asSeconds() < 60) {
    return `${Math.round(diff.asSeconds())} seconds left`;
  } else if (diff.asMinutes() < 60) {
    return `${Math.round(diff.asMinutes())} minutes left`;
  } else if (diff.asHours() < 24) {
    return `${Math.round(diff.asHours())} hours left`;
  } else {
    return `${Math.round(diff.asDays())} days left`;
  }
}