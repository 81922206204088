import _ from "lodash";
import * as React from "react";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Link from '../../../components/Link';

export default function Refundwhen(props) {
  return (
    <Paper variant="outlined" sx={{p: 3}}>
      <Typography variant="body3" color="text.secondary">
        The exact timing of the refund will depend on the sports event (is it this week, next month, etc.)
        as well as the speed of the brand in confirming your order.
      </Typography>
      <Typography variant="body3" color="text.secondary">
        Refunds should never take more than 6 weeks to finalize.
      </Typography>

    </Paper>
  );
}
