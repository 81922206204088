import _ from "lodash";
import * as React from "react";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckIcon from '@mui/icons-material/Check';

import Loader from '../../components/Loader';
import request from '../../utils/request';
import currencyFormatter from '../../utils/currencyFormatter';

export default function ProfilesDetails(props) {
  const { username, offers } = props

  const [tab, setTab] = React.useState(0);

  const tabs = ["activity"];

  const wins = _.filter(offers, o => o.status == "won")
  const savings = _.chain(wins)
    .filter(o => !!o.prize.amount_cents)
    .map(o => o.prize.amount_cents)
    .sum()
    .value() / 100.0

  return (
    <Box>
      <Grid container spacing={{xs: 1, sm: 2}}>
        <Grid item xs={6} md={6}>
          <Paper sx={{px: 2, py: 6}} variant="outlined">
             <Stack spacing={1}  alignItems="center">
              <Typography variant="body4" fontWeight={600} textTransform="uppercase">
                Savings
              </Typography>

              <Typography variant="h3" fontWeight={600}>
                {currencyFormatter(savings, 2)}
              </Typography>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={6} md={6}>
          <Paper sx={{px: 2, py: 6}} variant="outlined">
             <Stack spacing={1}  alignItems="center">
              <Typography variant="body4" fontWeight={600} textTransform="uppercase">
                Wins
              </Typography>

              <Typography variant="h3" fontWeight={600}>
                {wins.length}
              </Typography>
            </Stack>
          </Paper>
        </Grid>
      </Grid>

      <Box mt={2}>
        <Box mb={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>

          <Tabs value={tab} onChange={(e, tab) => {
            setTab(tab)
          }}>
            {
              _.map(tabs, t => {
                return (
                  <Tab key={t} sx={{
                    textTransform: "inherit",
                    fontWeight: 600,
                    fontSize: 14
                  }} label={
                    _.capitalize(t)
                  }  />
                )
              })
            }
          </Tabs>
        </Box>

        <Stack spacing={1}>
          {
            _.map(offers, o => {
              return (
                <Paper key={o.id} variant="outlined" sx={{p: 2}}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar src={o.feed_item.image_url} sx={{
                      width: 50,
                      height: 50
                    }} />

                    <Box>
                      <Typography variant="body3" fontWeight={600}>
                        {o.feed_item.title}
                      </Typography>
                      <Typography variant="body3">
                        Save {o.prize.description} if {o.proposition.winDescription}
                      </Typography>
                    </Box>
                  </Stack>

                  <Stack direction="row" justifyContent="end" sx={{mt: 0}}>
                    <Chip color={
                      o.status == "won" ? "primary" :
                        o.status == "lost" ? "error" :
                        "default"
                    } size="small" label={
                      <Typography variant="body4" fontWeight={600} textTransform="uppercase">
                        {o.status}
                      </Typography>
                    } />
                  </Stack>
                </Paper>
              )
            })
          }
        </Stack>
      </Box>
    </Box>
  );
}
