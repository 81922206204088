export default (props) => {
  return (
    <img src="https://upload.wikimedia.org/wikipedia/en/1/1a/Ro_Company_Logo.png"
      style={{
        height: 40,
        borderRadius: 5,
        ...props
      }} />
  )
}
