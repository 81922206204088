import _ from "lodash";
import * as React from "react";
import {useParams} from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Loader from '../../components/Loader';

import request from '../../utils/request';

import Details from './Details';

export default function LeaderBoardMain(props) {
  const { username } = useParams()

  const [loading, setLoading] = React.useState(true);
  const [offers, setOffers] = React.useState([]);

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  const fetchData = async () => {
    const { data } = await request.get(`users/activity`, {
      params: {
        username
      }
    })

    if (data.errors) {

    } else {
      setOffers(data)
    }
  }

  return (
    <Box pt={2} pb={12}>
      <Container maxWidth="sm">
        <Box mb={3}>
          <Typography variant="h3" fontWeight={600} textAlign="center">
            {username}
          </Typography>
        </Box>

        {
          loading ?
            <Loader /> :
            <Details
              offers={offers}
              username={username} />
        }
      </Container>
    </Box>
  );
}
