import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';

import * as userActions from '../../actions/user';
import withRouter from '../../utils/withRouter';

import Alerts from '../Alerts';

function AccountMain({user, transactions, fetchTransactions}) {
  const location = useLocation();

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  const fetchData = async () => {
  }

  return (
    <Box pt={2} pb={10}>
      <Box minHeight={"100vh"}>
        <Outlet />
      </Box>
    </Box>
  );
}


const select = $$state => _.pick($$state, "user");
export default connect(select, {
  ...userActions,
})(withRouter(AccountMain));
