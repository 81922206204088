import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";
import generateUUID from '../utils/uuid';

export const createAlert = alert => {
  return {
    type: actionTypes.alerts.CREATE,
    payload: {
      alert: {
        ...alert,
        id: generateUUID()
      }
    },
  };
};

export const deleteAlert = alert => {
  return {
    type: actionTypes.alerts.DELETE,
    payload: {
      alert
    },
  };
};

