import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import * as userActions from '../../actions/user';
import withRouter from '../../utils/withRouter';
import Popover from '../../components/Popover';

function AccountPopover(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const { user, history, logout } = props;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box>
        <Popover action={
          <IconButton>
            <Avatar sx={{
              height: 30,
              width: 30
            }} src={user.avatar_url} />
          </IconButton>
        } content={popupState => {
          return (
            <List disablePadding component="nav" sx={{minWidth: 250}}>
              {
                user.role == "admin" &&
                  <ListItemButton
                    onClick={() => {
                      history.push(`/admin`)
                      popupState.close();
                    }}>
                    <ListItemText primary={
                      <Typography variant="body3" fontWeight={500}>Admin</Typography>
                    } />
                  </ListItemButton>
              }

              <ListItemButton
                onClick={() => {
                  logout();
                  popupState.close();
                }}>
                <ListItemText primary={
                  <Typography variant="body3" fontWeight={500}>Log out</Typography>
                } />
              </ListItemButton>
            </List>
          )
        }} />
    </Box>
  );
}


const select = $$state => _.pick($$state, "user");
export default connect(select, userActions)(withRouter(AccountPopover));

