import _ from 'lodash';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as RouterLink } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import withRouter from '../../utils/withRouter';
import Link from '../../components/Link';

export default function MarketingAbout(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box mb={{
      xs: 4,
      sm: 4
    }} mt={{
      xs: 1,
      sm: 2
    }}>
      <Container maxWidth="sm">
        <Box sx={{
          background: "primary.main",
          borderRadius: "16px",
          px: {
            xs: 5,
            sm: "80px"
          },
          py: {
            md: 6,
            xs: 6
          }
        }}>

          <Typography textAlign="center" fontWeight={600} mb={1} style={{
            fontSize: mobile ? "1.3em" : "1.5em",  
            lineHeight: 1,
            letterSpacing: "-0.03em"
          }}>
            Not our first rodeo
          </Typography>

          <Typography variant="body1" textAlign="center" sx={{
            lineHeight: 1.4
          }}>
            We have built financial technology at some of the world’s
            largest startups, including Plaid.
          </Typography>
        </Box>

        <Box mb={4}>
          <Paper sx={{p: 1, background: "#f8f8f8"}} variant="outlined">
            <Stack direction="row" alignItems="center" spacing={0}>
              <img src="https://www.logo.wine/a/logo/Plaid_(company)/Plaid_(company)-Vertical-Logo.wine.svg"
                style={{
                  height: 50
                }} />
              <Typography variant="body3" fontWeight={600}>
                We're funded by great investors including Zach Perret, the Plaid founder and CEO.
              </Typography>
            </Stack>
          </Paper>
        </Box>

        <Box>
          <Box>
              <Grid container spacing={{xs: 2, sm: 4}}>
                <Grid item sm={12} md={12}>
                  <Paper sx={{p: 3, background: "#f8f8f8"}} variant="outlined">
                    <Stack spacing={2} alignItems="start">
                      <img style={{
                        height: 150,
                        borderRadius: 5
                      }}
                        src={"https://res.cloudinary.com/dirtrv8w6/image/upload/v1684777763/user_uploads/attractive_t82opo.jpg"} />

                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="body3" fontWeight={600}>
                          Ben Plesser
                        </Typography>

                        <Divider orientation="vertical" flexItem />

                        <Typography variant="body3" color="text.secondary" fontWeight={600}>
                          Founder and CEO
                        </Typography>
                      </Stack>

                      <Typography variant="body2" color="text.secondary" sx={{mb: 2}}>
                        Ben was one of the first 10 employees at both <Link color="primary" target="_blank" href="https://plaid.com">Plaid</Link> and <Link color="primary" target="_blank" href="https://yipitdata.com">another unicorn startup</Link>.
                        Afterwards, he founded and grew his own payments startup, <Link color="primary" target="_blank" href="https://www.tremendous.com">Tremendous.com</Link>,
                        to $30M+ in annual revenue.
                      </Typography>
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item sm={12} md={12}>
                  <Paper sx={{p: 3, background: "#f8f8f8"}} variant="outlined">
                    <Stack spacing={2} alignItems="start">
                      <img style={{
                        height: 150,
                        borderRadius: 5
                      }}
                        src={"https://media.licdn.com/dms/image/C5603AQFE0zN2WZE1Dw/profile-displayphoto-shrink_400_400/0/1525902487802?e=1724889600&v=beta&t=o5UFxxxVBujyMV25fsJo4YGEDi31DNcO5i3UApxn50k"} />

                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="body3" fontWeight={600}>
                          Ben Perez
                        </Typography>

                        <Divider orientation="vertical" flexItem />

                        <Typography variant="body3" color="text.secondary" fontWeight={600}>
                          Founder and CTO
                        </Typography>
                      </Stack>

                      <Typography variant="body2" color="text.secondary" sx={{mb: 2}}>
                        Ben was engineer #3 at <Link color="primary" target="_blank" href="https://plaid.com">Plaid</Link> and then worked early
                        stage <Link color="primary" target="_blank" href="https://www.linkedin.com/company/zenefits">Zenefits</Link>.
                        Afterwards, he founded own AI infrasture startup for autonomous vehicles which was eventually acquired by <Link color="primary" target="_blank" href="https://www.linkedin.com/company/ripcord">Ripcord</Link>,

                      </Typography>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>

              <Box mt={{xs: 3, sm: 5}}>
                  <Grid container spacing={{
                    xs: 2,
                    md: 4
                  }} alignItems="center">
                    <Grid item xs={12} sm={12}>
                      <Box sx={{
                        background: "rgba(85, 208, 253, 0.1)",
                        border: "1px solid rgb(85 208 153)",
                        borderRadius: "30px",
                        p: 5,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        minHeight: {
                          xs: 200,
                          sm: 300
                        }
                      }}>
                        <Typography color="secondary" variant="h1" fontWeight={600}>
                          2
                        </Typography>

                        <Typography variant="body2" fontWeight={500} mt={1} lineHeight={1.5}>
                          Unicorn where we were first 10 employees.
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box sx={{
                        background: "rgba(85, 208, 253, 0.1)",
                        border: "1px solid rgb(85 208 153)",
                        borderRadius: "30px",
                        p: 5,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        minHeight: {
                          xs: 200,
                          sm: 300
                        }
                      }}>
                        <Typography color="secondary" variant="h1" fontWeight={600}>
                          3
                        </Typography>

                        <Typography variant="body2" fontWeight={500} mt={1} lineHeight={1.5}>
                          VC backed startups founded in our careers.
                        </Typography>
                      </Box>
                    </Grid>


                    <Grid item xs={12} sm={12}>
                      <Box sx={{
                        background: "rgba(85, 208, 253, 0.1)",
                        border: "1px solid rgb(85 208 153)",
                        borderRadius: "30px",
                        p: 5,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        minHeight: {
                          xs: 200,
                          sm: 300
                        }
                      }}>
                        <Typography color="secondary" variant="h1" fontWeight={600}>
                          $1B+
                        </Typography>

                        <Typography variant="body2" fontWeight={500} mt={1} lineHeight={1.5}>
                          Payment dollars that our startups have processed
                        </Typography>
                      </Box>
                    </Grid>

                  </Grid>
              </Box>


          </Box>
        </Box>
      </Container>
    </Box>
  )
}
