import _ from "lodash";
import moment from 'moment';
import * as React from "react";
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { usePlaidLink } from 'react-plaid-link';
import { Outlet, useOutletContext, useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import Loader from '../../components/Loader';
import Link from '../../components/Link';
import Alert from '../../components/Alert';
import FullScreenDialog from '../../components/FullScreenDialog';
import PlaidLinkButton from '../../components/PlaidLinkButton';
import ClickablePaper from '../../components/ClickablePaper';

import withRouter from '../../utils/withRouter';
import request from '../../utils/request';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';

import * as offersActions from '../../actions/offers';
import * as userActions from '../../actions/user';
import * as cardActions from '../../actions/cards';

import Faqs from './Faqs';
import Card from '../cards/Card';

function OfferInsstructions(props) {
  const {
    offer,
    user,
    savePlaidUser,
    open,
    onClose,
  } = props;

  const {
    proposition,
    merchant,
    prize
  } = offer;

  const [saving, setSaving] = React.useState(false);

  const [receiptOpen, setReceiptOpen] = React.useState(false);
  const toggleReceiptOpen = () => setReceiptOpen(!receiptOpen);

  const [plaidOpen, setPlaidOpen] = React.useState(false);
  const togglePlaidOpen = () => setPlaidOpen(!plaidOpen);

  const timeLeft = time.getTimeTill(proposition.eligibilityEndsAt);

  return (
    <FullScreenDialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box>
          <Typography variant="h4" fontWeight={600}>
            Instructions to receive refund.
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{pb: 4}}>
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" fontWeight={600} sx={{width: 10}}>
              1.
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              Shop at {offer.requirement_title} before the offer ends at {dates.tzAwareDateTime(proposition.eligibilityEndsAt, "dddd hh:mm A")}.
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" fontWeight={600}  sx={{width: 10}}>
              2.
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              Pay with your Gravy card <Link href="/app/account/cards" target="_blank">(view your card)</Link>.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        {
          _.includes(["pending", "activated", "verified"], offer.status) &&
            <Box mt={4}>
              <Faqs offer={offer} prize={prize} merchant={merchant} />
            </Box>
        }
      </DialogActions>
    </FullScreenDialog>

  )
}

const select = $$state => _.pick($$state, ["user", "cards", "offers"]);
export default connect(select, {
  ...offersActions,
  ...userActions,
  ...cardActions
})(withRouter(OfferInsstructions));
