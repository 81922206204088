import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LinearProgress from '@mui/material/LinearProgress';

function Progress({value}) {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress color="primary" variant="determinate" value={value} />
    </Box>
  )
}

export default function OnboardingFooter(props) {
  const { button, skipButton, title, goBack, disabled, loading } = props;
  return (
    <AppBar position="fixed" sx={{
      top: 'auto',
      bottom: 0,
      borderTop: 1,
      borderColor: 'divider',
      bgcolor: "rgba(255, 255, 255, 0.7)",
      boxShadow: "none",
      color: "inherit",
    }}>
      <Progress value={props.progress || 0} />
      <Toolbar sx={{      py: 2,}}>
        <Box sx={{ flexGrow: 1 }}>
          {
            goBack != null &&
              <IconButton onClick={props.goBack}>
                <ArrowBackIcon />
              </IconButton>
          }
        </Box>

        <Stack direction="row" alignItems="center" spacing={2}>
          {
            button ||
              <LoadingButton
                color="primary"
                size="large"
                variant="contained"
                type="submit"
                disabled={disabled || loading}
                loading={loading}>

                {title || "Next"}
              </LoadingButton>
          }

          {
            skipButton
          }

        </Stack>
      </Toolbar>
    </AppBar>
  );
}