import _ from 'lodash';
import { connect } from 'react-redux';
import * as React from 'react';
import { Link as RouterLink, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

import withRouter from '../../utils/withRouter';
import * as transactionActions from '../../actions/transactions';
import currencyFormatter from '../../utils/currencyFormatter';
import { safeMoment } from '../../utils/dates';
import Loader from '../../components/Loader';
import FullScreenDialog from '../../components/FullScreenDialog';

function TransactionsList(props) {
  const { transactions, fetchTransactions, history } = props;

  const onClose = () => history.push("/app", {replace: true});

  const [tab, setTab] = React.useState(0);
  const tabs = ["wins", "withdrawals"];

  const filteredTransactions = _.filter(transactions, t => {
    return tab == 0 ?
      t.transactable_type == "OfferPrize" :
      t.transactable_type == "Payout"
  })

  return (
    <FullScreenDialog
      open={true}
      fullWidth
      maxWidth="xs"
      onClose={onClose}>
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box>
          <Typography variant="h2" fontWeight={600}>
            Gravy Statement
          </Typography>

          <Typography variant="body1" fontWeight={500} color="text.secondary">
            Your past wins and payouts
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Tabs value={tab} onChange={(e, tab) => {
            setTab(tab)
          }}>
            {
              _.map(tabs, t => {
                return (
                  <Tab key={t} sx={{
                    textTransform: "inherit",
                    fontWeight: 600,
                    fontSize: 14
                  }} label={
                    _.capitalize(t)
                  }  />
                )
              })
            }
          </Tabs>

          <Stack spacing={1}>
            {
              _.map(filteredTransactions, (transaction, i) => (
                <Paper
                  key={i}
                  variant="outlined"
                  sx={{p: 2}}>
                  <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Stack direction="row" spacing={2}>

                      <Box>
                        <Typography variant="body3" fontWeight={600}>
                        </Typography>
                        <Typography variant="body3" color="text.secondary">
                        </Typography>

                        <Typography variant="body3" color="text.secondary">
                          {
                            safeMoment(transaction.created_at).format("MM/DD/YY")
                          }
                        </Typography>
                      </Box>
                    </Stack>

                    <Chip color={
                      transaction.direction == "credit" ? "error" : "success"
                    } size="small" label={
                      <Typography variant="body4" fontWeight={600}>
                        { transaction.direction == "credit" ? "-" : "+"}{
                          currencyFormatter(transaction.amount, 2)
                        }
                      </Typography>

                    } />
                  </Stack>
                </Paper>
              )
            )}
          </Stack>
        </Box>
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <Button size="large" color="primary">
          Close
        </Button>
      </DialogActions>
    </FullScreenDialog>

  )
}

const select = $$state => _.pick($$state, "transactions");
export default connect(select, {
  ...transactionActions
})(withRouter(TransactionsList));
