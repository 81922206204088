import _ from "lodash";
import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function ConditionalRebateDescription(props) {
  return (
    <Paper variant="outlined" sx={{p: 3}}>
      <Typography variant="body2" color="text.secondary">
        A <Link
          href="https://en.wikipedia.org/wiki/Conditional_rebate#:~:text=With%20this%20specific%20type%20of,circumstance%20(i.e.%20condition)%20arises."
          target="_blank">conditional rebate</Link> is a sales-based offer typically used by retailers to increase their sales, generate traffic and create publicity.
          With this specific type of rebate, a company typically offers a certain amount of their product, or some sort of sales incentive, if a particular circumstance (i.e. condition) arises.
          In this case, it's sports based!
      </Typography>
    </Paper>
  );
}
