import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Slide, useScrollTrigger } from '@mui/material';
import { Outlet, useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CardMedia from '@mui/material/CardMedia';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import IconButton from '@mui/material/IconButton';

import { deepPurple } from '@mui/material/colors';

import PlaidLogo from '../../icons/PlaidLogo';
import Link from '../../components/Link';
import withRouter from '../../utils/withRouter';
import * as offerActions from '../../actions/offers';

import AuthButton from '../user/AuthButton';
import SampleOffers from './SampleOffers';
import GravyVideo from '../../components/GravyVideo';
import tabletRewardsAppImage from '../../assets/images/tablet_rewards_app.svg';

function About() {
  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showNavBar, setShowNavBar] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = _.throttle(() => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;

      // Check if the user has scrolled to the bottom
      if (scrollTop > 200) {
        setShowNavBar(true);
      } else {
        setShowNavBar(false);
      }
    }, 200); // Adjust the throttle time (in milliseconds) as needed

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Slide direction="up" timeout={500} in={showNavBar} mountOnEnter unmountOnExit>
      <AppBar position="fixed" sx={{
        bottom: 0,
        top: "auto",
        background: "#fff",
        boxShadow: "none",
        borderTop: "1px solid #cccccc",
      }}>
        <Container maxWidth="xl">
          <Stack direction={{
            xs: "column",
            md: "row"
           }}
            alignItems={{
              md: "center",
              xs: "initial"
            }}
            justifyContent={{
              md: "center",
              xs: "initial"
            }} spacing={{
              xs: 0,
              md: 8
          }}>

            <Stack direction="row" alignItems="center" spacing={0}>
              <Box sx={{
                position: "relative",
                top: 5
              }}>
                <PlaidLogo height={
                  50
                } />
              </Box>

              <Box>
                <Typography variant="body4" fontWeight={600} color="text.primary">
                  Built by the team that built Plaid
                </Typography>
                <Link variant="body4" fontWeight={700} component={RouterLink} to="/about" color="primary" underline="always">
                  Read about us
                </Link>
              </Box>

            </Stack>
          </Stack>
        </Container>
      </AppBar>
    </Slide>
  )
}

function MarketingHome(props) {
  const { user, offers } = props;

  const navigate = useNavigate();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [playHow, setPlayHow] = React.useState(false);

  React.useEffect(() => {
    if (!!user.id && user.status !== "unregistered") navigate("/app")
  }, []);

  const howItWorksRef = React.useRef(null);

  return (
    <Box>
      <Box sx={{
        background: "black",
      }} pt={6} pb={12}>
        <Container maxWidth="sm">
          <Stack direction="column" alignItems="center" spacing={4}>
            <Box>
              <Typography mb={1} textAlign="center" textTransform="uppercase" fontWeight={600} style={{
                color: "#fff",
                fontSize: "0.85em",  
                letterSpacing: "-0.03em"
              }}>
                Pour Gravy on it
              </Typography>

              <Typography color="secondary" textAlign="center" fontWeight={600} style={{
                fontSize: mobile ? "2.7em" : "3em",  
                lineHeight: 1,
                letterSpacing: "-0.02em"
              }}>
                Save real money
              </Typography>

              <Typography mt={1} variant="h2" textAlign="center" fontWeight={500} style={{
                color: "rgb(215 215 215)",  
                lineHeight: 1.3,
              }}>
                When cool things happens in sports, news, and culture
              </Typography>

            </Box>

            <Stack direction={{
              xs: "column",
              md: "row"
            }} spacing={1}>
              <Button
                component={RouterLink}
                to="/onboarding"
                size="large"
                variant="contained"
                color="secondary"
                sx={{
                  color: "#fff",
                  padding: 2,
                  width: 300
                }}>
                Start Saving
              </Button>

              <Button
                onClick={() => {
                  howItWorksRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  setPlayHow(true);
                }}
                size="large"
                variant="outlined"
                color="secondary"
                sx={{
                  border: "1px solid white",
                  color: "#fff",
                  padding: 2,
                  width: 300,
                }}>
                How Gravy works
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Box>

      <Box py={{
        xs: 8,
        md: 6
      }} sx={{
        background: "rgb(245, 245, 245)",
      }}>
        <Box mb={{xs: 6, md: 16}}>
          <Container maxWidth={mobile ? "sm" : "xs"}>
            <Paper sx={{p: 2}} variant="outlined">
              <SampleOffers
                justifyContent="center"
                subtitle="Big brands, big offers" />
            </Paper>
          </Container>
        </Box>

        <Box mb={8}>
          <Container maxWidth="xl">
            <Grid container spacing={{
              md: 6,
              xs: 2
            }} alignItems="center">
              <Grid item xs={12} md={5} sx={{
                px: 6, py: {
                  xs: 2,
                  sm: 6
                }
              }}>
                <Stack spacing={3}>
                  <Box>
                    <Typography color="secondary" fontWeight={600} style={{
                      fontSize: mobile ? "1.2em" : "3em", 
                      lineHeight: "1.05",
                      letterSpacing: "-0.03rem",
                    }}>
                      More Fun. More Cash. Zero Fees.
                    </Typography>

                    <Typography mt={{xs: 1, md: 2}} variant={mobile ? "body1" : "h5"} fontWeight={500}>
                      Gravy works with major brands to transform their boring discounts
                      into gamified cashback offers.
                    </Typography>
                  </Box>

                  <Button
                    component={RouterLink}
                    to="/onboarding"
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    sx={{
                      color: "#fff",
                      padding: 2,
                      width: 300
                    }}>
                    Start Saving
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} md={7} ref={howItWorksRef}>
                <GravyVideo play={playHow} />
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Divider />

        <Box py={{
          xs: 6,
          md: 10
        }}>
          <Container maxWidth="sm">
            <Box mb={2}>
              <Stack>
                <Typography color="secondary" fontWeight={600} gutterBottom style={{
                  fontSize: mobile ? "1.2em" : "1.1em", 
                  lineHeight: "0.94",
                  letterSpacing: "-0.03rem",
                }}>
                  What do Gravy deals look like?
                </Typography>
              </Stack>
            </Box>

            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                  <Paper variant="outlined" sx={{
                    p: 3,
                  }}>
                    <Stack direction={{
                      xs: "column",
                      md: "row"
                    }} spacing={3} alignItems={{
                      md: "center",
                      xs: "start"
                    }}>
                      <img
                        style={{
                          height: 100,
                          borderRadius: "50%",
                          objectFit: 'cover'
                        }}
                        src="https://p34.f1.n0.cdn.zight.com/items/E0ue4YxQ/bbc8078a-dcdb-44b3-b2c0-2c422da16464.png?source=viewer&v=edd5fc077139201c2f696c68f8486479" />


                      <Box mt={2}>
                        <Typography variant="body2" fontWeight={600}>
                          Brands that you love
                        </Typography>
                       <Typography color="text.secondary" variant="body2">
                          Gravy works with brands to offer shoppers an alternative to boring discounts.
                        </Typography>
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Paper variant="outlined" sx={{
                    p: 3,
                  }}>
                    <Stack direction={{
                      xs: "column",
                      md: "row"
                    }} spacing={{
                      xs: 2,
                      md: 3
                    }} alignItems={{
                      md: "center",
                      xs: "start"
                    }}>

                      <img
                        src="https://p34.f1.n0.cdn.zight.com/items/6quox8dB/8c22f26b-c264-4fc0-ba7c-5b6f0c133997.png?source=viewer&v=f4b4a229795aa356b1d42246416f484d"
                        style={{
                          height: 100,
                          borderRadius: "50%",
                          objectFit: 'cover'
                        }} />

                      <Box mt={2}>
                        <Typography variant="body2" fontWeight={600}>
                          Your team wins big, you win big
                        </Typography>
                       <Typography color="text.secondary" variant="body2">
                          Why settle for 3% off when you could get a full refund if your QB throws
                          5 TDs?
                        </Typography>
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>


                <Grid item xs={12} lg={12}>
                  <Paper variant="outlined" sx={{
                    p: 3,
                  }}>
                    <Stack direction={{
                      xs: "column",
                      md: "row"
                    }} spacing={{
                      xs: 2,
                      md: 3
                    }} alignItems={{
                      md: "center",
                      xs: "start"
                    }}>

                      <img
                        src="https://p34.f1.n0.cdn.zight.com/items/GGuB2QYA/a55e113e-6581-4e89-9161-b9cc71c856c2.png?v=333c0619947552487e26be13665e0f96"
                        style={{
                          height: 100,
                          borderRadius: "50%",
                          objectFit: 'cover'
                        }} />

                      <Box mt={2}>
                        <Typography variant="body2" fontWeight={600}>
                          Zero fees for shoppers
                        </Typography>
                       <Typography color="text.secondary" variant="body2">
                          <em>We only ever charge merchants</em>, never shoppers. <Link fontWeight={600} color="primary" href="/faq/fees/fees-brands" target="_blank">Read how it works</Link>.
                        </Typography>
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
            </Grid>

            <Box mt={3}>
              <Button
                component={RouterLink}
                to="/onboarding"
                fullWidth
                size="large"
                variant="contained"
                color="primary">
                Let Gravy Cook
              </Button>
            </Box>
          </Container>
        </Box>
      </Box>

      <Box sx={{
        background: "#141c3f",
        py: {
          xs: 8,
          md: 20
        },
      }}>
        <Container maxWidth="md">
          <Box mb={6}>
            <Typography variant="h2" color="#fff">
              You're in good company
            </Typography>
            <Typography variant="body1" color="#fff">
              Thousands of real shoppers were asked
              if they'd prefer a standard discount on their next
              clothing purchase or a FULL refund if their local NBA player scored 50+ in the next game.
            </Typography>
          </Box>

          <Grid container alignItems="center">
            <Grid item xs={5} md={5}>
              <Box>
                <Typography variant="h3" color="#fff" style={{
                  fontSize: "2em",
                  lineHeight: "1",
                  fontWeight: "500",
                  letterSpacing: "-0.03em"
                }}>
                  3%
                </Typography>
                <Typography variant="body1" color="lightBlue" fontWeight={500}>
                  Fixed Discount
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3} md={3}>
              <Box>
                <Typography variant="h3" color="#fff" style={{
                  fontSize: "2em",
                  lineHeight: "1",
                  fontWeight: "500",
                  letterSpacing: "-0.03em"
                }}>
                  vs.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4} md={4}>
              <Box>
                <Typography variant="h3" color="#fff" style={{
                  fontSize: "2em",
                  lineHeight: "1",
                  fontWeight: "500",
                  letterSpacing: "-0.03em"
                }}>
                  100%
                </Typography>
                <Typography variant="body1" color="lightBlue" fontWeight={500}>
                  Gravy Refund
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box mt={6}>
            <Typography variant="h3" color="#fff" style={{
              fontSize: "2em",
              lineHeight: "1",
              fontWeight: "500",
              letterSpacing: "-0.03em"
            }}>
              66%
            </Typography>
            <Typography variant="body1" color="lightBlue" fontWeight={500}>
              Of shoppers chose Gravy
            </Typography>
          </Box>
        </Container>
      </Box>


      <Box sx={{
        pt: 14,
        pb: 14,
        height: mobile ? "initial" : "80vh",
        width: "100%",
        backgroundSize: "cover",
        backgroundImage: "url('https://assets.website-files.com/613d5c4dc4abb8c7f1d44b8f/615ba839331cb89fb056c6e3_sharon-mccutcheon--8a5eJ1-mmQ-unsplash%20(1).jpg')"
      }}>
        <Container maxWidth="md">
          <Typography mb={3} variant="h3" color="#fff" style={{
            fontSize: mobile ? "1.3em" : "2em",
            lineHeight: "1.25",
            fontWeight: "500",
            letterSpacing: "-0.03em"
          }}>
            Championship level offers or bust.
          </Typography>

          <Button component={RouterLink} to="/onboarding" variant="contained" size="large" color="primary">
            Start Saving
          </Button>
        </Container>
      </Box>

      <About />
    </Box>
  );

}

const select = $$state => _.pick($$state, ["user", "offers"]);
export default connect(select, {
  ...offerActions
})(withRouter(MarketingHome));
