export default (props) => {
  return (
    <img src="https://yt3.googleusercontent.com/kX32AV9ChK-NyKDRd8f5Xzz6WkPejWVoOvndMyZywo_stWTjKfV6ye03VF487MLtFWPJKan62GU=s900-c-k-c0x00ffffff-no-rj"
      style={{
        height: 40,
        borderRadius: 5,
        ...props
      }} />
  )
}
