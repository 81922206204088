import _ from "lodash";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import HelpIcon from '@mui/icons-material/Help';

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';
import currencyFormatter from '../../utils/currencyFormatter';
import FullScreenDialog from '../../components/FullScreenDialog';

import {usePostHog} from "posthog-js/react";
import request from '../../utils/request';

export default function MerchantsIssues(props) {
  const [refreshing, setRefreshing] = React.useState(false);
  const [refreshed, setRefreshed] = React.useState(false);

  const navigate = useNavigate();

  const onClose = () => navigate("../", {replace: true})

  const toggleRefreshing = () => setRefreshing(!refreshing);

  const refresh = async () => {
    try {
      setRefreshing(true)
      const { data } = await request.post(`plaid/transactions_refresh`)
      if (data.errors) {
        alert(data.errors)
        setRefreshing(false)
      } else {
        setTimeout(() => {
          setRefreshing(false)
          setRefreshed(true)
        }, 1000 * 20)
      }
    } catch(err) {
      alert("Something went wrong, please try again later")
      setRefreshing(false)
    }
  }

  return (
    <FullScreenDialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box mt={1}>
          <Typography variant="h4" fontWeight={600}>
            Missing a Pick?
           </Typography>
          <Typography variant="body1" lineHeight={1.3}>
            Plaid can take a while to recognize new transactions.
            If you've shopped recently at a Gravy featured merchant, verify below.
           </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {
          refreshed &&
            <Alert color="primary" sx={{mt: 1}}>
              <Typography variant="body2" fontWeight={600}>
                You should
                receive a confirmation text within the next
                couple of minutes. If not, email help@itsgravy.co.
              </Typography>
            </Alert>
        }

        {
          refreshing &&
            <Alert color="primary" sx={{mt: 1}}>
              <Typography variant="body2" fontWeight={600}>
                This could take up to 20 seconds.
              </Typography>
            </Alert>
        }
      </DialogContent>
      <DialogActions sx={{p: 3, pb: 6}}>
        <LoadingButton disabled={refreshed} loading={refreshing} size="large" variant="contained" onClick={refresh}>
          Verify my purchase
        </LoadingButton>
      </DialogActions>
    </FullScreenDialog>
  )
}