export default (value, maximumFractionDigits=0, currency="USD") => {
  return new Intl.NumberFormat(
    'en-US',
    {
      style: 'currency',
      currency: currency,
      maximumFractionDigits: maximumFractionDigits
    }
  ).format(value)
}
