import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import request from '../../utils/request';
import withRouter from '../../utils/withRouter';
import * as userActions from '../../actions/user';
import TextField from '../../components/TextField';
import DialogCloseButton from '../../components/DialogCloseButton';
import DialogResponsive from '../../components/DialogResponsive';

const validationSchema = yup.object({
  login: yup
    .string()
    .required(),
});

function UserContextify({user, setUser, history}) {
  const onClose = () => {
    history.replace("/app")
  }

  const formik = useFormik({
    initialValues: {
      login: '',
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const { data } = await request.post(`users/contextify`, values)
        if (data.errors) {
          alert(data.errors.message)
        } else {
          setUser({
            id: data.id,
            jwt: data.jwt,
            ...data.attributes
          })

          history.push("/app")
        }
      } finally {

      }
    }
  });

  return (
    <DialogResponsive
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs"
      sx={{
        zIndex: 100000000,
      }}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />
          <DialogCloseButton onClose={onClose} />
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Container maxWidth="xs">
          <Typography variant="title" fontWeight={600}>
            Activate your account
          </Typography>

          <Box mt={2} mb={4}>
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={2}>
                <TextField
                  name="login"
                  label="Login"
                  value={formik.values.login}
                  onChange={formik.handleChange}
                  error={formik.touched.login && Boolean(formik.errors.login)}
                  helperText={formik.touched.login && formik.errors.login}/>

                <LoadingButton
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}>
                  Activate
                </LoadingButton>
              </Stack>
            </form>
          </Box>
        </Container>
      </DialogContent>
    </DialogResponsive>
  );
}

const select = $$state => _.pick($$state, "user");
export default connect(select, userActions)(withRouter(UserContextify));
