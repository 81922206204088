import _ from "lodash";
import * as React from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Faq from './Faq';
import currencyFormatter from '../../utils/currencyFormatter';

export default function OffersFaqs({offer, merchant}) {
  const defaults = [{
    question: "How do I qualify for this offer?",
    answer: `First activate below. Then order at a qualifying merchant between now and the time listed above with a connected credit or debit card.`
  }, {
    question: "What if the event is canceled?",
    answer: `Refunds for canceled games are automatically voided..`
  }, {
    question: "Which credit cards does Gravy support?",
    answer: `Before receiving your refund, you must connect the credit card that you used to verify your order. Gravy supports AMEX, Bank of America, Chase, Discover, Citi, and others. To see the entire list of supported cards, go to your Gravy account page and press Connect Credit Card.`
  }, {
    question: "If I win, how will I receive my refund?",
    answer: "Refunds can be paid out to your bank account, PayPal, or a gift card."
  }, {
    question: "When will I receive my refund?",
    answer: `The timing refunds depend on the merchant. You will be paid as soon as the window for refunds / returns ends with that merchant.`
  },
  {
    question: `What if I order less than the prize amount?`,
    answer: `You will receive a refund of the lesser of your payment and the quoted refund. This means that if your bill is less than the quoted refund amount, your refund will be equal to your payment.`
  },
]

  return (
    <Box>
      <Accordion variant="outlined">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="body3" fontWeight={500}>
            More FAQs
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={1} sx={{mt: 1}}>
            {
              _.map([...defaults], (faq, i) => {
                return <Faq
                  key={i}
                  {...faq} />
              })
            }
          </Stack>
        </AccordionDetails>
      </Accordion>

    </Box>
  )
}
