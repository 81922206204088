import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export default styled(TextField)(({ theme }) => ({
  '& label': {
    fontSize: 15,
    top: 2
  },
  '& .MuiInputBase-input': {
    fontSize: 15,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    "::placeholder": {
      fontSize: 15
     }
   }
}))
