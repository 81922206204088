import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import Loader from '../../components/Loader';
import ClickablePaper from '../../components/ClickablePaper';

import withRouter from '../../utils/withRouter';

import FeedItemCard from '../feedItems/Card';

export default function InstructionsShop(props) {
  const {
    user,
    feedItems,
    cards,
    picks,
    params,
    teams
  } = props;

  return (
    <Box>
      <Box>
        <Typography variant="h4" fontWeight={700} lineHeight={1.3}>
          Save BIG when you shop
        </Typography>
        <Typography variant="body1">
          When you use your Gravy card,
          you get either...
        </Typography>
      </Box>

      <Box mt={2}>
        <Stack spacing={1}>
          <Paper variant="outlined" sx={{p: 3}}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="h1">
                ✅
              </Typography>
              <Box>
                <Typography variant="body1" fontWeight={600}>
                  2% Cash Back
                </Typography>
                <Typography variant="body1">
                  Guaranteed
                </Typography>
              </Box>
            </Stack>
          </Paper>

          <Divider>
            <Typography variant="body3" fontWeight={600}>
              OR
            </Typography>
          </Divider>

          <Paper variant="outlined" sx={{p: 3}}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="h1">
                🎁
              </Typography>
              <Box>
                <Typography variant="body1" fontWeight={600}>
                  Up to 100% Cash Back
                </Typography>
                <Typography variant="body1">
                  If something cool
                  happens in sports, news, or culture.
                </Typography>
              </Box>
            </Stack>

          </Paper>
        </Stack>
      </Box>

    </Box>
  );
}
