export default (props) => {
  return (
    <img src="https://upload.wikimedia.org/wikipedia/en/thumb/d/d3/Starbucks_Corporation_Logo_2011.svg/640px-Starbucks_Corporation_Logo_2011.svg.png"
      style={{
        height: 40,
        borderRadius: 5,
        ...props
      }} />
  )
}
