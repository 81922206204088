import _ from "lodash";
import * as React from "react";
import { Outlet, Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import categories from './categories'

export default function HelpCategory(props) {
  const params = useParams();
  const category = _.find(categories, c => c.path == params.category)

  const breadcrumbs = [
    <Link
      color="primary"
      underline="hover"
      component={RouterLink}
      to="/faq">
      <Typography variant="body2" key="1" fontWeight={600}>
        Help Articles
      </Typography>
    </Link>,
    <Typography variant="body2" key="2" color="text.secondary" fontWeight={600}>
      {category.title}
    </Typography>,
  ];

  return (
    <Box>
      <Box mb={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
      </Box>

      <Paper variant="outlined" sx={{p: 3}}>
        <Typography variant="body2" color="text.secondary" fontWeight={600}>
          {category.subtitle}
        </Typography>

        <Box mt={3}>
          {
            _.map(category.pages, p => (
              <Box key={p.title} mb={{
                xs: 2,
                md: 1
              }}>
                <Link color="primary" underline="hover" component={RouterLink} to={p.path} fontSize={15} fontWeight={600}>
                  {p.title}
                </Link>
              </Box>
            ))
          }
        </Box>

      </Paper>
    </Box>
  );
}
