import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import * as referralActions from '../../actions/referrals';
import withRouter from '../../utils/withRouter';

import Link from '../../components/Link';
import Loader from '../../components/Loader';

function ReferralsList({user, referrals, fetchReferrals}) {
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => setOpen(!open);

  React.useEffect(() => {
    fetchReferrals().finally(() => setLoading(false))
  }, []);

  return (
    <Box>
      <Box>
        <Button variant="contained" fullWidth size="large" component={RouterLink} to="./new">
          Refer new friend
        </Button>
      </Box>

      <Box mt={3}>
        <Stack spacing={1}>
          {
            loading ?
              <Loader /> :
              _.map(referrals, ref => {
                return (
                  <Paper key={ref.id} sx={{p: 2}} variant="outlined">
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Box>
                        <Typography variant="body3" color="text.secondary" fontWeight={600}>
                          {ref.user.name}
                        </Typography>
                        <Typography variant="body3" color="text.secondary" fontWeight={600}>
                          {ref.user.email}
                        </Typography>
                      </Box>
                      <Chip size="small" label={
                        <Typography variant="body4" fontWeight={600} textTransform="uppercase">
                          {ref.status}
                        </Typography>
                      } />
                    </Stack>
                  </Paper>
                )
              })
          }
        </Stack>
      </Box>

      <Outlet />
    </Box>
  );
}


const select = $$state => _.pick($$state, "user", "referrals");
export default connect(select, {
  ...referralActions
})(withRouter(ReferralsList));
