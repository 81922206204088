import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import Iconify from '../../components/iconify';
import request from '../../utils/request';
import withRouter from '../../utils/withRouter';
import * as alertActions from '../../actions/alerts';

import Instructions from './instructions/Main';


function PourList(props) {
  const {
    params,
    history
  } = props;

  const [loading, setLoading] = React.useState(true)
  const [activeMerchant, setActiveMerchant] = React.useState({})
  const [merchants, setMerchants] = React.useState([])

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onClose = () => history.replace("../");

  React.useEffect(() => {
    fetchMerchants().finally(() => setLoading(false))
  }, [])

  const fetchMerchants = async () => {
    const { data } = await request.get(`merchants`);

    setMerchants(
      _.map(data, d => {
        return {
          id: d.id,
          ...d.attributes
        }
      })
    )
  }

  const whiteListed = [
    "Uber",
    "DoorDash",
    "Amazon",
    "Target",
    "Starbucks",
    "Chipotle",
    "Dunkin",
    "Walmart"
  ];

  return (
    <Box>
      {
        false &&
          <Box mb={2}>
            <Alert color="secondary" sx={{
              border: "1px solid #c9b1f0"
            }} onClick={() => {
              history.push("/app?ins=2")
            }}>
              <Typography color="secondary" variant="body2" fontWeight={600}>
                How do Gravy Pours work exactly?
              </Typography>
            </Alert>
          </Box>
      }

      <Stack spacing={1}>

        {
          loading ?
            <Loader /> :
            _.chain(merchants)
              .filter(m => _.includes(whiteListed, m.name))
              .sortBy(m => _.indexOf(whiteListed, m.name))
              .map((m, i) => {
                return (
                  <ClickablePaper key={i} variant="outlined" onClick={() => {
                    setActiveMerchant(m)
                  }} sx={{p: 2}}>
                    <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
                      <Stack direction="row" spacing={3} alignItems="center">
                        <img src={m.logo_url} style={{
                          width: 45,
                          borderRadius: 5
                        }} />

                        <Box>
                          <Typography variant={mobile ? "h4" : "h5"} fontWeight={600} lineHeight={1.3}>
                            Add Funds
                          </Typography>
                          <Typography variant="body2">
                            Win up to 100% back
                          </Typography>
                        </Box>
                      </Stack>

                      <ChevronRightIcon />
                    </Stack>
                  </ClickablePaper>
                )
              })
              .value()
        }
      </Stack>

      <Instructions
        {...props}
        merchant={activeMerchant}
        open={!_.isEmpty(activeMerchant)}
        onClose={() => setActiveMerchant({})} />

      <Outlet  />
    </Box>
  )
}

const select = $$state => _.pick($$state, ["user"]);
export default connect(select, {
  ...alertActions
})(withRouter(PourList));
