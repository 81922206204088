import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const ClickablePaper = styled((props) => {
  const { selected, ...other } = props;
  return <Paper variant="outlined" {...other} sx={{
    p: 2,
  }} />;
})(({ theme, selected, disabled }) => ({
  cursor: "pointer",
  color: selected ? theme.palette.primary.main : theme.palette.text.primary,
  borderColor: selected ? theme.palette.primary.main : theme.border.borderColor,
  "&:hover": {
    cursor: disabled ? "not-allowed" : "pointer",
    borderColor: disabled ? theme.border.borderColor : theme.palette.primary.main,
    // color: theme.palette.primary.main
  }
}));


export default ClickablePaper;
