import _ from "lodash";
import qs from 'qs';
import Confetti from 'react-confetti';
import { connect } from 'react-redux';
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import CheckIcon from '@mui/icons-material/Check';

import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import FullScreenDialog from '../../components/FullScreenDialog';
import * as cardActions from '../../actions/cards';
import * as alertActions from '../../actions/alerts';
import withRouter from '../../utils/withRouter';
import request from '../../utils/request';

function PicksMain(props) {
  const { user, picks, cards, fetchCards, createAlert, location, history } = props;

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );

  const [loading, setLoading] = React.useState(true);
  const [isExploding, setIsExploding] = React.useState(search.p == 1);

  const onClose = () => history.push(`/app`, {replace: true})

  React.useEffect(() => {
    if (cards.length == 0) {
      fetchCards().finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [])

  const gravyCard = _.find(cards, c => c.source == "gravy");
  const plaidCard = _.find(cards, c => c.source == "plaid");

  const allowExternalConnect = plaidCard == null;

  const earnPicks = async operation => {
   return request.post(`picks`, { operation })
  }

  return (
    <FullScreenDialog open={true} fullWidth maxWidth="xs" onClose={onClose}>
      {
        isExploding &&
          <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
        />
      }

      <DialogTitle>
        <Box mt={search.o == 1 ? 3 : 4}>
          {
            search.p == 1 ?
              <Box>
                <Typography variant="h1" fontWeight={600} lineHeight={1.1}>
                  Great Pick!
                </Typography>
                <Typography variant="h4" fontWeight={400}>
                  Want another one?
                </Typography>

              </Box> :
              <Box>
                <Typography variant="h1" fontWeight={600} lineHeight={1}>
                  Earn more Picks
                </Typography>
                <Typography variant="h4">
                  It's a breeze...
                </Typography>

              </Box>
          }
        </Box>
      </DialogTitle>
      <DialogContent>
        {
          search.o == 1 &&
            <Alert color="error" variant="filled">
              <Typography variant="body2" fontWeight={600} lineHeight={1.1}>
                Pick required. You have zero right now.
              </Typography>
            </Alert>
        }
        <Stack spacing={3} sx={{pt: 2}}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="body1" fontWeight={600} lineHeight={1.2}>
                Shop with your card
              </Typography>
              <Typography variant="body2">
                Pick per purchase
              </Typography>
            </Box>

            <Button sx={{width: 120}} variant="contained" component={RouterLink} to={
              (gravyCard == null && plaidCard == null) ?
                `/app/cards/new` :
                `/app/cards`
              }>
              Shop
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="body1" fontWeight={600} lineHeight={1.2}>
                Purchase Picks
              </Typography>
              <Typography variant="body2">
                5 Picks for $10
              </Typography>
            </Box>

            <Button sx={{width: 120}} variant="contained" onClick={() => {
              earnPicks("purchase").finally(() => {
                alert("Sorry, purchasing picks is not currently available.")
              })
            }}>
              Buy
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="body1" fontWeight={600} lineHeight={1.2}>
                Refer friends
              </Typography>
              <Typography variant="body2">
                Pick per new user who wins.
              </Typography>
            </Box>

            <CopyToClipboard
              text={
                `https://${window.location.host}?ref=${user.referral_code}`
              }
              onCopy={() => {
                createAlert({
                  title: "Copied referral link"
                })

                earnPicks("referral")
              }}>
                <Button sx={{width: 120}} variant="contained">
                  Copy Link
                </Button>
            </CopyToClipboard>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="body1" fontWeight={600} lineHeight={1.2}>
                Take short survey(s)
              </Typography>
              <Typography variant="body2">
                Pick per survey completed
              </Typography>
            </Box>

            <Button sx={{width: 120}} variant="contained" component={RouterLink} to={
              "/app/account/surveys"
            }>
              Start
            </Button>
          </Stack>
          {
            plaidCard == null &&
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                <Box>
                  <Typography variant="body1" fontWeight={600} lineHeight={1.2}>
                    Add your credit card
                  </Typography>
                  <Typography variant="body2">
                    Pick for connecting with Plaid
                  </Typography>
                </Box>

                <Button sx={{width: 120}} variant="contained" component={RouterLink} to={
                  "/app/cards/new"
                  } sx={{width: 120}}>
                  Connect
                </Button>
              </Stack>
          }

        </Stack>
      </DialogContent>
      <DialogActions sx={{p: 2}}>
        <Button onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </FullScreenDialog>

  )
}

const select = $$state => _.pick($$state, "user", "cards", "picks");
export default connect(select, {
  ...cardActions,
  ...alertActions
})(withRouter(PicksMain));