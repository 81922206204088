import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Navigate } from "react-router-dom";
import Box from '@mui/material/Box';

import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import withRouter from '../../utils/withRouter';
import request from '../../utils/request';

import * as offerActions from '../../actions/offers';

function OffersLanding(props) {
  const {
    history,
    params
  } = props;

  const [loading, setLoading] = React.useState(true);
  const [offer, setOffer] = React.useState(null);

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, [])

  const fetchData = async () => {
    const { data } = await request.get(`offers/token?token=${params.offerId}`)

  }

  if (loading) return <Loader />
  if (!!offer) return <Navigate to={`/p/${offer.id}`} />

  return <Box />
}

const select = $$state => _.pick($$state, ["user", "offers"]);
export default connect(select, {
  ...offerActions,
})(withRouter(OffersLanding));
