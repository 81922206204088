import _ from "lodash";
import qs from 'qs';
import { connect } from 'react-redux';
import * as React from "react";
import { useLocation } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import FullScreenDialog from '../../components/FullScreenDialog';
import PlaidLinkButton from '../../components/PlaidLinkButton';
import * as cardActions from '../../actions/cards';
import * as userActions from '../../actions/user';
import * as alertActions from '../../actions/alerts';
import withRouter from '../../utils/withRouter';

import Card from '../cards/Card';
import InstructionsCard from './InstructionsCard';
import InstructionsShop from './InstructionsShop';
import InstructionsPick from './InstructionsPick';

function Progress({progress}) {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress color="secondary" variant="determinate" value={progress} />
    </Box>
  )
}

function DashboardInstructions(props) {
  const { user, savePlaidUser, cards, fetchCards, location } = props;

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );

  const [open, setOpen] = React.useState(false)
  const [step, setStep] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [walletOpen, setWalletOpen] = React.useState(false);

  const toggleOpen = () => setOpen(!open)

  const onboarded = window.localStorage.getItem("onboarded");

  React.useState(() => {
    if (!!onboarded) {
      window.localStorage.removeItem("onboarded");
      setTimeout(toggleOpen, 2000)
    } else if (search.ins == 1) {
      setTimeout(toggleOpen, 2000)
    }
  }, [])

  React.useEffect(() => {
    if (!!onboarded || search.ins == 1) {
      Promise.all([
        fetchCards(),
      ]).finally(() => setLoading(false))
    }
  }, [])

  const card = _.find(cards, c => c.source == "gravy");

  if (loading == null) return <Box />
  if (card == null) return <Box />

  return (
    <FullScreenDialog open={open} fullWidth maxWidth="xs">
      <Progress progress={(step + 1) / 4 * 100} />

      <DialogTitle>
      </DialogTitle>
      <DialogContent>
        <Box>
          {
            step == 0 &&
              <InstructionsCard
                {...props}
                card={card}
                next={() => setStep(step + 1)} />
          }


          {
            step == 1 &&
              <InstructionsPick  {...props} {...card} />
          }

          {
            step == 2 &&
              <InstructionsShop
                {...props}
                card={card}
                next={() => setStep(step + 1)} />
          }

        </Box>
      </DialogContent>
      <DialogActions sx={{p: 3}}>
        {
          step == 0 &&
            <Box pt={4}>
              <Stack direction="row" justifyContent="end" spacing={1}>
                <Button size="large" variant="contained" onClick={() => {
                  setStep(step + 1)
                }}>
                  Next
                </Button>
              </Stack>
            </Box>

        }

        {
          step == 1 &&
            <Stack direction="row">
              <Button onClick={() => {
                setStep(step - 1)
              }}>
                Back
              </Button>
              <Button size="large" variant="contained" onClick={() => {
                setStep(step + 1)
              }}>
                Next
              </Button>
            </Stack>
        }

        {
          step == 2 &&
            <Stack direction="row">
              <Button onClick={() => {
                setStep(step - 1)
              }}>
                Back
              </Button>
              <Button size="large" variant="contained" onClick={toggleOpen}>
                Done
              </Button>
            </Stack>
        }

      </DialogActions>
    </FullScreenDialog>

  )
}

const select = $$state => _.pick($$state, "user", "cards");
export default connect(select, {
  ...userActions,
  ...cardActions,
  ...alertActions
})(withRouter(DashboardInstructions));
