import _ from "lodash";
import moment from 'moment';
import * as React from "react";
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { ChartColumnIncreasing } from 'lucide-react'

import Link from '../../components/Link';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import FullScreenDialog from '../../components/FullScreenDialog';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';
import request from '../../utils/request';
import currencyFormatter from '../../utils/currencyFormatter';

import Favorites from '../favorites/Teams';
import AuthButton from '../user/AuthButton';

import Demo from './Demo';

export default function RewardsFeed(props) {
  const {
    reward,
    user,
    feedItems,
    history,
  } = useOutletContext();

  const onClose = () => history.push(`/rewards/${reward.token}`, {replace: true});

  const { merchant, amount_cents, cost_cents} = reward;

  const [saving, setSaving] = React.useState(null);
  const [tab, setTab] = React.useState(0);
  const [demoOpen, setDemoOpen] = React.useState(false);

  const toggleDemoOpen = () => setDemoOpen(!demoOpen);

  const categories = ["all", "games", "players"];

  const rewardProbability = cost_cents / amount_cents;

  const getProbability = p => _.get(
    p,
    ["propositionProbabilities", 0, "probability"], 1000000
  );

  const relevantFeedItems = _.chain(feedItems)
    .filter(feedItem => {
      return _.includes([
        "Player", "SportsEvent"
      ], feedItem.feedableType);
    })
    .filter(feedItem => _.filter(
        feedItem.propositions,
        p => getProbability(p) <= rewardProbability
      ).length > 0
    )
    .value()

  const save = async proposition => {
    try {
      setSaving(proposition.id);

      const { data } = await request.put(`rewards/${reward.token}`, {
        proposition_id: proposition.id
      })

      if (!data.errors) {
        window.localStorage.setItem("reward", JSON.stringify({
          ...reward,
          proposition
        }));

        history.push(`/app/activities`)
      } else if (data.errors.message == 'DEMO') {
        toggleDemoOpen()
      } else {
        alert(data.errors.message)
      }
    } catch(err) {
      alert("Something went wrong, please try again and contact help@itsgravy.co if it continues.")
    } finally {
      setSaving(null)
    }
  }

  return (
    <FullScreenDialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box mt={2}>
          <Typography variant="h2" fontWeight={600}>
            Pick one below
          </Typography>
          <Typography variant="h4" fontWeight={400} lineHeight={1.3}>
            {currencyFormatter(amount_cents / 100)} { merchant?.name} Gift Card
            if it happens!
          </Typography>
        </Box>

        <Box mt={3}>
          <Alert color="secondary" action={
            <Favorites title={"Customize"} />
          }>
            <Typography color="secondary" variant="body2" fontWeight={600}>
              Not loving these options?
            </Typography>
          </Alert>
        </Box>

      </DialogTitle>
      <DialogContent>
          <Stack spacing={2}>
            {

              _.chain(relevantFeedItems)
              .map((fi, i) => {
                const proposition = _.chain(fi.propositions)
                  .filter(p => getProbability(p) <= rewardProbability)
                  .sortBy(p => getProbability(p))
                  .reverse()
                  .first()
                  .value()

                return [proposition, fi]
              })
              .reject(pair => pair[0] == null)
              .sortBy(pair => getProbability(pair[0]))
              .map((pair, i) => {
                const proposition = pair[0];
                const fi = pair[1];

                const { story, title, startAt, imageUrl, feedableType } = fi;

                const probability = getProbability(proposition);

                return (
                  <Paper variant="outlined" key={i} sx={{p: 2}}>
                      <Box>
                        <Stack spacing={2} sx={{mb: 2}}>
                          <Box>
                            <Typography variant="h5" fontWeight={600}>
                              {
                                title
                              }
                            </Typography>

                            <Typography variant="h2" fontWeight={600}>
                              {proposition.winDescription}
                            </Typography>
                          </Box>
                        </Stack>

                        <AuthButton onAuthentication={() => save(proposition)}>
                          <LoadingButton
                            size="large"
                            fullWidth
                            disabled={!!saving}
                            loading={saving == proposition.id} variant="contained">
                            Select
                          </LoadingButton>
                        </AuthButton>
                      </Box>
                  </Paper>
                )
              }).value()
            }
          </Stack>

      </DialogContent>
      <DialogActions sx={{
        p: 3,
      }}>
      </DialogActions>

      <Demo
        open={demoOpen}
        onClose={toggleDemoOpen} />
    </FullScreenDialog>

  )
}
