import _ from 'lodash';
import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default function MarketingPrivacy(props) {
  return (
    <Container maxWidth="md" sx={{mt: 3, mb: 4}}>
      <Box>
        <Typography variant="body1" fontWeight={600}>
          Privacy Policy
        </Typography>

        <Typography variant="body2" fontWeight={600}>
          Last updated: April 11, 2024
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2">
          Welcome to our website. Your privacy is important to us and we value the trust you place in us.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2">
          This Privacy Statement describes the types of information that TailRisk, Inc. (TailRisk or “we”) collects, uses and shares when you interact with our website, purchase products through our platform, or receive a communication from us, or other products that you received from one of our Service Providers.
          This Privacy Statement sets forth our policy with respect to the collection, use and disclosure of information that is collected on our Site. By using the Site, you agree to the data collection, use, disclosure and storage practices described in this Privacy Statement.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2">
          By accessing or using our Site, you acknowledge that you have read, understood, and agree to be bound to the terms of this Privacy Statement and our terms of use.

          TailRisk is a platform for online daters. We may be considered a “service provider” under California law. If you are located in the European Economic Area or the United Kingdom, we are a data controller of such data under the EU General Data Protection Regulation and the UK General Protection Regulation.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2">
          The information we collect may include your name, address, email address, telephone number, mobile telephone number or geolocation information or any other information you provide while you use our Services such as login or password.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2">
          We may use the information we collect from and about you to:
        </Typography>
        <ul>
          {
            [
              "Send you requested product or service information;",
              "Respond to your questions and concerns;",
              "Improve the content and functionality of our Web site and marketing efforts;",
              "Conduct research and analysis;",
              "Display content based upon your interests;",
              "Comply with applicable laws, including to respond to requests from public and government authorities;",
              "Enforce this Privacy Statement;",
              "Protect our rights, privacy, safety or property, and/or that of you or others.",
              "Develop new programs, products or services",
              "Protect the security or integrity of the Services and our business;",
            ].map(point => (
              <li key={point}>
                <Typography variant="body2">
                  {point}
                </Typography>
              </li>
            ))
          }
        </ul>
      </Box>
      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          Information Sharing
        </Typography>

        <Typography variant="body2" fontStyle="italic">
          We may share the information we collect from and about you:

        </Typography>
      </Box>

      <Box mt={1}>
        <Typography variant="body2">
          With to our Service Providers, if the disclosure will enable them to perform a business, professional or technical support function for us
          as necessary if we believe that there has been a violation of this Privacy Statement, our Terms of Use, of our rights or the rights of any third party;
          when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;
          to respond to judicial process or provide information to law enforcement or regulatory agencies or in connection with an investigation on matters related to public safety, national security or law enforcement, as permitted by law, or otherwise as required by law; and
          as described to you at the point of collection.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2">

          If we are involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.

          We do not sell your personal information to third parties.
          We may sell or purchase assets during the normal course of our business. If another entity acquires us or any of our assets, information we have collected about you may be transferred to such entity. In addition, if any bankruptcy or reorganization proceeding is brought by or against us, such information may be considered an asset of ours and may be sold or transferred to third parties. Should such a sale or transfer occur, we will use reasonable efforts to try to require that the transferee use personal information provided through the Services in a manner that is consistent with this Privacy Statement.

          If you do not want us to share your personal information as set forth in this Privacy Statement, contact us at help@TailRisk.ai.
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          Information We Collect Automatically and Cookie Policy:
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2">
          As is true of most Web sites, we automatically gather information about your computer such as your IP address, browser type, referring/exit pages, and operating system.
          ‍
          When anyone interacts with our website, certain information about your use of our Services is automatically collected. This information includes computer and connection information, such as statistics on your page views, traffic to and from our Services, referral URL, ad data, your IP address, and device identifiers. This information also may include how you search for our Services, the websites you click on from our Services or emails, whether and when you open our emails, and your browsing activities across other websites.

          We may use cookies, web beacons, pixel tags, log files or other technologies to automatically collect certain information when you use our Services or interact with our emails. For example, we may automatically collect certain non-personal information from you such as your mobile device identifier or MAC address, browser type, operating system, device model software version, Internet Protocol (“IP”) address, mobile or ISP carrier information, and the domain name from which you accessed the Services. We also may collect information about your use of the Services including the date and time you access the Services, the areas or pages of the Services that you visit, the amount of time you spend using the Services, the number of times you return, whether you open forward or click-through emails and ads, and other usage data.

          Tracking Options and California Do Not Track Disclosures. You may adjust your browser or operating system settings to limit this tracking or to decline cookies, but by doing so, you may not be able to use certain features on the Services or take full advantage of all of our offerings. Check the “Help” menu of your browser or operating system to learn how to adjust your tracking settings or cookie preferences. To learn more about the use of cookies or other technologies to deliver more relevant advertising and your choices about not having this information used by certain Service Providers (defined below), please click here. On your mobile device, you can adjust your privacy and advertising settings to limit your tracking for advertising or control whether you receive more relevant advertising. Note that our systems may not recognize Do Not Track headers or requests from some or all browsers. For more information on our use of cookies, click here.

          We may also allow third parties such as Google, Facebook and Segment to use tracking technologies to collect or receive information from the TailRisk website and elsewhere on the internet and use that information to provide measurement services and targeted ads.
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          Combination of Information
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2">
          We may combine the information we receive from and about you, including information you provide to us and information we automatically collect through our Site, as well as information collected offline or from third party sources, including Services Providers, to help us tailor our communications to you and to improve our Services.

          We may sell or purchase assets during the normal course of our business. If another entity acquires us or any of our assets, information we have collected about you may be transferred to such entity. In addition, if any bankruptcy or reorganization proceeding is brought by or against us, such information may be considered an asset of ours and may be sold or transferred to third parties. Should such a sale or transfer occur, we will use reasonable efforts to try to require that the transferee use personal information provided through the Services in a manner that is consistent with this Privacy Statement.

          If you do not want us to share your personal information as set forth in this Privacy Statement, contact us at help@TailRisk.ai.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          Third Parties
        </Typography>
      </Box>

      <Box mt={1}>
        <Typography variant="body2">
          We may contract with third parties to perform functions related to the Site and to help us with the services we provide. These third parties will have access to your personal information as needed to perform their business functions, but may not use or share that personal information for other purposes. These companies are authorized to use your personal information only as necessary to provide these services to us and/or to support the services we provide to our users.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          Your California Privacy Rights
        </Typography>

        <Typography variant="body2">
          California Civil Code Section §1798.83 permits users of our site that are California residents to request certain information regarding our disclosure of Personal Information to third parties for their direct marketing purposes. To make such a request, please send an email to help@TailRisk.ai.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          Visitors from the EEA or the UK
        </Typography>

        <Typography variant="body2">
          If you are a resident of the EEA or the UK, you must consent to the collection and use of your data within in our legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms. In some cases, we may also have a legal obligation to collect the personal information in question. You may contact us to withdraw your consent to our collection and use of your personal data and to exercise the following rights to:
        </Typography>

        <ul>
          {
            [
              "Access your personal data or information;",
              "Delete, or request deletion of, your personal data or information;",
              "Object to or restrict processing of your personal information;",
              "Request portability of your personal information;",
              "Complain to your local data protection authority at any time;",
              "Object to automated decision making; and",
              "Update your personal data or information.",
              "Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent.",
            ].map(point => (
              <li key={point}>
                <Typography variant="body2">
                  {point}
                </Typography>
              </li>
            ))
          }
        </ul>
      </Box>
      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          Legal Requirements
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body2">
          We may disclose your Personal Data if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of Company, (iii) act in urgent circumstances to protect the personal safety of users of the Services or the public, or (iv) protect against legal liability.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          Children
        </Typography>
      </Box>

      <Box mt={1}>
        <Typography variant="body2">
          Protecting children’s privacy is important to us. We do not direct the Services to, nor do we knowingly collect any personal information from, children under the age of thirteen.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          Links to Other Web Sites
        </Typography>
      </Box>

      <Box mt={1}>
        <Typography variant="body2">
          This Privacy Statement applies only to the Services. It does not apply to non-TailRisk websites and mobile applications that may link to the Services or be linked to or from the Services; please review the privacy policies on those websites and applications directly to understand their privacy practices. The Services may contain links to third-party sites or applications, which may have privacy policies that differ from our own. We are not responsible for the practices of such sites or applications. The links to third-party sites from the Services do not imply that we endorse or have reviewed the third-party sites.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          Data Security
        </Typography>
      </Box>

      <Box mt={1}>
        <Typography variant="body2">
          The security of your personal information is important to us. We have taken certain physical, administrative, and technical steps to safeguard the information we collect from and about You. While we make every effort to help ensure the integrity and security of our network and systems, the transmission of information via the internet is not completely secure. E-mail sent to or from the Services may not be secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Site. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Site.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          Data Storage and Retention
        </Typography>
      </Box>

      <Box mt={1}>
        <Typography variant="body2">
          Your personal information is stored on our Service Providers’ servers in the United States. You understand and agree that we may collect, use, disclose, and otherwise process the information you provide as described in this Privacy Statement even if you are from an area outside the United States. Your personal information may be disclosed in response to inquiries or requests from government authorities or to respond to judicial process in the United States. We will retain your personal information for as long as it is needed to provide you with the Services, or to fulfill any legal or contractual obligations we may have.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          Access to Your Personal Information
        </Typography>
      </Box>

      <Box mt={1}>
        <Typography variant="body2">
          You may also send us an email at help@TailRisk.ai to request access to, correct or delete any personal information that you have provided to us. We may not accommodate a request to change or delete information if we believe doing so would violate any law or legal or audit requirement or cause the information to be incorrect.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          Authorized User Notice
        </Typography>
      </Box>

      <Box mt={1}>
        <Typography variant="body2">
          If you access the Site on behalf of one of our Users, you are considered an Authorized User under the User's contract with us.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" fontWeight={600}>
          Changes to Our Privacy Statement
        </Typography>
      </Box>

      <Box mt={1}>
        <Typography variant="body2">
          We reserve the right to revise this Privacy Statement at any time. When we do, we will post the changes on the Site. If we change the Privacy Statement in a material way, we will provide appropriate notice to you. Your continued use of the Services after any changes or revisions to this Privacy Statement shall indicate your agreement with the terms of such revised Privacy Statement.
          Please also feel free to contact us at help@TailRisk.ai if you have any questions about our Privacy Statement or the information practices of the Services.
        </Typography>
      </Box>
    </Container>
  )
}
