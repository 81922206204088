import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

export const fetchTransactions = query => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`transactions`, {
      params: {
        limit: 20,
        offset: 0,
        ...query,
      }
    });

    if (!data.errors) {
      dispatch({
        type: actionTypes.transactions.INDEX,
        payload: {
          transactions: _.map(data, d => {
            return {
              id: d.id,
              ...d.attributes
            }
          })
        }
      });
    }

    return data;
  }
};

export const payout = () => {
  return async (dispatch, getState) => {
    const { data } = await request.post(`payouts`)

    if (!data.errors) {
      dispatch({
        type: actionTypes.transactions.CREATE,
        payload: {
          transaction: {
            id: data.id,
            ...data.attributes
          }
        }
      });
    }

    return data;
  }
};