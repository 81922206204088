import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import {  Link as RouterLink, Outlet} from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import * as surveyActions from '../../actions/surveys';
import withRouter from '../../utils/withRouter';
import Link from '../../components/Link';
import Loader from '../../components/Loader';

function SurveysMain(props) {
  const {surveys, fetchSurveys} = props;

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (surveys.length == 0) {
      fetchSurveys().finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, []);

  const breadcrumbs = [
    <Link
      color="primary"
      underline="hover"
      component={RouterLink}
      to="../">
      <Typography variant="body3" fontWeight={600}>
        Account
      </Typography>
    </Link>,
    <Typography variant="body3" color="text.secondary" fontWeight={600}>
      Surveys
    </Typography>,
  ];

  return (
    <Box pt={2} pb={12}>
      <Container maxWidth="sm">
        <Box mb={4}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
        </Box>


        {
          loading ?
            <Loader /> :
            <Outlet />
        }
      </Container>
    </Box>
  );
}

const select = $$state => _.pick($$state, ["surveys"]);
export default connect(select, {
  ...surveyActions
})(withRouter(SurveysMain));
