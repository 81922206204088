import _ from "lodash";
import * as React from "react";
import {connect} from 'react-redux';
import {Outlet} from "react-router-dom";
import {usePostHog} from 'posthog-js/react'


import withRouter from '../../utils/withRouter';
import * as analytics from '../../utils/analytics';
import {useEffect} from "react";

function AnalyticsMain(props) {
    const {
        user,
        location
    } = props;

    const posthog = usePostHog();
    useEffect(() => {
        if (user != null) {
            posthog?.identify(user.id, {
                email: user.email,
                name: user.name,
                phone: user.phone
            });
        }
    }, [posthog, user])

    React.useEffect(() => {
        analytics.initialize();

        if (user && user.id) {
            analytics.identify(user.id);
            analytics.setUserProperties(user);
        }
    }, [props.user.id])

    React.useEffect(() => {
        analytics.initialize();

        analytics.logEvent("Page View", {
            pathname: location.pathname
        });
    }, [location.pathname])

    return (
        <React.Fragment>
            <Outlet/>
        </React.Fragment>
    );
}


const select = $$state => _.pick($$state, "user");
export default connect(select, {})(withRouter(AnalyticsMain));
