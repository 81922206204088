import _ from "lodash";
import * as React from "react";
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';

import request from '../../utils/request';
import AuthButton from '../user/AuthButton';
import PlaidBuilt from '../../components/PlaidBuilt';

import Demo from './Demo';

export default function RewardsExplanation(props) {
  const {
    user,
    reward,
    proposition,
    open,
    onClose,
    history
  } = props;

  const [saving, setSaving] = React.useState(null);
  const [demoOpen, setDemoOpen] = React.useState(false);

  const toggleDemoOpen = () => setDemoOpen(!demoOpen);

  const save = async () => {
    try {
      setSaving(proposition.id);

      const { data } = await request.put(`rewards/${reward.token}`, {
        proposition_id: proposition.id
      })

      if (!data.errors) {
        window.localStorage.setItem("reward", JSON.stringify({
          ...reward,
          proposition
        }));

        history.push(`/app/activities`)
      } else if (data.errors.message == "DEMO") {
        toggleDemoOpen()
      } else {
        alert(data.errors.message)
      }
    } catch(err) {
      alert("Something went wrong, please try again and contact help@itsgravy.co if it continues.")
    } finally {
      setSaving(null)
    }
  }

  function convertQuestionToStatement(sentence) {
    if (!sentence) return;

    // Check if the sentence starts with "Will" and ends with a question mark
    if (sentence.startsWith("Will") && sentence.endsWith("?")) {
      // Remove "Will" from the start and the question mark from the end
      let statement = sentence.slice(4, -1).trim();

      // Split the statement into words to detect the verb
      let words = statement.split(' ');

      // Find the first verb and convert it to the present tense
      const verbMapping = {
        'win': 'wins',
        'be': 'is',
        'have': 'has',
        'do': 'does',
        'go': 'goes',
        'make': 'makes',
        'take': 'takes',
        'give': 'gives',
        'get': 'gets',
        'say': 'says'
        // Add more verb mappings as needed
      };

      // Loop through the words to find the verb
      for (let i = 0; i < words.length; i++) {
        let word = words[i].toLowerCase();
        if (verbMapping[word]) {
          // Replace the verb with its present tense form
          words[i] = verbMapping[word];
          break;
        }
      }

      // Reassemble the sentence and append a period
      return words.join(' ') + '.';
    }

    // If the sentence doesn't start with "Will", replace the question mark with a period if it exists
    return sentence.endsWith('?') ? sentence.slice(0, -1) + '.' : sentence;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box mt={2}>
          <Typography variant="h2" fontWeight={600}>
            ${reward.amount_cents / 100} gift card at { reward.merchant?.name }
          </Typography>
          <Typography variant="h3" fontWeight={400}>
            If { convertQuestionToStatement(proposition?.win_description)}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <PlaidBuilt  />
        </Box>
      </DialogContent>
      <DialogActions sx={{
        p: 3,
      }}>
        <AuthButton onAuthentication={save}>
          <LoadingButton
            loading={saving}
            variant="contained"
            size="large">
            Confirm
          </LoadingButton>
        </AuthButton>
        <AuthButton onAuthentication={() => {
          history.push(`/rewards/${reward.token}/feed`)
        }}>
          <LoadingButton
            color="secondary"
            loading={saving}
            variant="contained"
            size="large">
            More Choices
          </LoadingButton>
        </AuthButton>
      </DialogActions>

      <Demo
        open={demoOpen}
        onClose={toggleDemoOpen} />

    </Dialog>

  )
}
