import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

export const fetchPaymentMethods = () => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`payment_methods`)

    if (!data.errors) {
      dispatch({
        type: actionTypes.paymentMethods.INDEX,
        payload: {
          paymentMethods: data
        }
      });
    }

    return data;
  }
};