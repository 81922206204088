import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

export const fetchLeaderboards = (period) => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`leaderboards`, {
      params: {
        period
      }
    })

    if (!data.errors) {
      dispatch({
        type: actionTypes.leaderboards.INDEX,
        payload: {
          leaderboards: data
        }
      });
    }

    return data;
  }
};