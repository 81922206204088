import _ from "lodash";
import moment from 'moment';
import { useSwipeable } from 'react-swipeable';
import * as React from "react";
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { ChevronRight, Zap } from 'lucide-react'

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import FullScreenDialog from '../../components/FullScreenDialog';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';
import request from '../../utils/request';
import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import * as merchantOffersActions from '../../actions/merchantOffers';
import * as payoutActions from '../../actions/payouts';
import * as alertActions from '../../actions/alerts';
import { fShortenNumber } from '../../utils/format-number';

function MerchantsPoints(props) {
  const {
    user,
    points,
    merchantOffers,
    createAlert,
    createPayout,
    params,
    history
  } = props;

  const onClose = () => history.replace("../");

  const merchantOffer = _.find(merchantOffers, mo => mo.id == params.merchantOfferId);
  const merchant = merchantOffer?.merchant;

  const [saving, setSaving] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [amountCents, setAmountCents] = React.useState(null)

  const save = async amount_cents => {
    if (amount_cents * 10 > points) return setError(true)

    try {
      setSaving(true)

      const results = await createPayout({
        amount_cents,
      })

      if (results.errors) {
        alert(results.errors.message)
      } else {
        createAlert({
          title: "Payout initiated, you'll receive a text with your gift card"
        })

        history.push(`/app/account/savings`)
      }
    } catch(err) {
      alert("There was an error")
    } finally {
      setSaving(false)
    }
  }

  return (
    <FullScreenDialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box>
          <Typography variant="h2" fontWeight={600}>
            Visa Prepaid Card
          </Typography>
          <Typography variant="h3" fontWeight={400}>
            Turn your points into cash
          </Typography>
        </Box>

        <Box mt={2}>
          <Button fullWidth variant="outlined" component={RouterLink} to="/app/feed">
            Win more points
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          {
            !!error &&
              <Box mb={2}>
                <Alert variant="filled" color="error">
                  <Typography variant="body2" fontWeight={600}>
                    Not enough points for this card.
                  </Typography>
                </Alert>
              </Box>
          }

          <Stack spacing={2}>
            {
              _.map([1000, 2000, 5000, 10000], amountCent => {
                return (
                  <ClickablePaper variant="outlined" key={amountCent} onClick={() => {
                    setAmountCents(amountCent)
                  }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                      <Box>
                        <Typography mb={1} variant="body1" fontWeight={600}>
                          {
                            currencyFormatter(amountCent / 100, 0)
                          } Visa Card
                        </Typography>

                        <Chip
                          icon={<Zap fontSize={16} />}
                          label={
                            <Typography variant="body3" fontWeight={600}>
                              {
                                fShortenNumber(amountCent * 10)
                              } Points
                            </Typography>
                          } />
                      </Box>

                      <ChevronRight />
                    </Stack>
                  </ClickablePaper>
                )
              })
            }
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions sx={{
        p: 3,
      }}>
        <LoadingButton variant="contained" disabled={!amountCents} loading={saving} onClick={save}>
          Complete
        </LoadingButton>
      </DialogActions>

    </FullScreenDialog>

  )
}


const select = $$state => _.pick($$state, ["user", "merchantOffers", "points"]);
export default connect(select, {
  ...alertActions,
  ...merchantOffersActions,
  ...payoutActions,
})(withRouter(MerchantsPoints));
