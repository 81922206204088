import _ from "lodash";
import moment from "moment";
import * as React from "react";
import {connect} from 'react-redux';
import {Link as RouterLink, Outlet, Navigate, useLocation, useParams} from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Soup } from 'lucide-react';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import Loader from '../../components/Loader';
import ClickablePaper from '../../components/ClickablePaper';

import withRouter from '../../utils/withRouter';

import * as merchantOffersActions from '../../actions/merchantOffers';
import * as teamActions from '../../actions/teams';

import FeedItemCard from './Card';
import TeamFavorites from '../favorites/Teams';
import {usePostHog} from "posthog-js/react";

const FeedItemsList = props => {
  const {
    user,
    feedItems,
    teams,
    merchantOffers,
    fetchMerchantOffers,
    location,
    history
  } = props;

  const [activeFeedItem, setActiveFeedItem] = React.useState({});
  const [tab, setTab] = React.useState(0);
  const [subCategory, setSubCategory] = React.useState(0);

  React.useEffect(() => {
    // Scroll to the top of the window on first render
    window.scrollTo(0, 0);

    if (merchantOffers.length == 0) fetchMerchantOffers()
  }, []);

  const categories = ["all", "sports", "players", "news", "culture", "crypto"];
  const subcategories = {
    sports: [
      "all",
      "nfl",
      "mlb",
      "nba"
    ],
    players: [
      "all",
      "nfl",
      "mlb",
      "nba"
    ]
  }

  const relevantFeedItems = _.filter(feedItems, feedItem => {
    const now = moment()
    const targetDateTime = moment.utc(feedItem.startAt, "YYYY-MM-DD HH:mm:ss A").tz('UTC');
    const diff = moment.duration(targetDateTime.diff(now)).asDays();

    if (
      feedItem.feedableType == "SportsEvent" &&
      diff > 8
    ) return false

  const desiredCategory = categories[tab];
    if (desiredCategory === "all") {
      return feedItem.feedableType !== "Player";
    } else if (desiredCategory === "sports") {
      if (feedItem.feedableType !== "SportsEvent") return false

      const cat = subcategories.sports[subCategory]
      return cat == "all" ||
        cat == feedItem.feedable.league.abbrv;
    } else if (desiredCategory === "players") {
      if (feedItem.feedableType !== "Player") return false

      const team = _.find(
        teams,
        t => t.id == feedItem.feedable.playerTeams[0]?.teamId,
      )

      const cat = subcategories.players[subCategory]

      return cat == "all" || cat == team?.league.abbrv;
    } else {
      return feedItem.feedable.category === desiredCategory;
    }
  });

  return (
    <Box>
      <Box my={2}>
        <Button size="large" fullWidth color="secondary" variant="contained" component={RouterLink} to="/app/earn">
          Earn More Picks
        </Button>
      </Box>

      <Box mt={2} mb={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant="scrollable" value={tab} onChange={(e, tab) => {
          setTab(tab)
        }}>
          {
            _.map(categories, t => {
              return (
                <Tab key={t} sx={{
                  textTransform: "inherit",
                  fontWeight: 600,
                  fontSize: 14
                }} label={
                  <Typography variant="body3" fontWeight={600} textTransform="uppercase">
                    {
                      t
                    }
                  </Typography>
                  }  />
              )
            })
          }
        </Tabs>
      </Box>

      {
        _.includes(["sports", "players"], categories[tab]) &&
          <Box mt={3} mb={3}>
            <Stack direction="row" spacing={4} justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1}>

                {
                  _.map(subcategories[categories[tab]], (t, i) => {
                    console.log(subcategories[subCategory])
                    return (
                      <Chip onClick={() => setSubCategory(i)} color={subCategory == i ? "primary" : "default"} label={
                        <Typography variant="body3" fontWeight={600} textTransform="uppercase">
                          {
                            t
                          }
                        </Typography>
                      } />
                    )
                  })
                }
              </Stack>
              <TeamFavorites />
            </Stack>
          </Box>
      }

      <Stack spacing={2}>
        {
          _.map(relevantFeedItems, fi => {
            return (
              <FeedItemCard
                key={fi.feedItem.id}
                feedItem={fi}
                {...props} />
            )
          })
        }
      </Stack>

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user", "merchantOffers", "feedItems", "teams"]);
export default connect(select, {
    ...merchantOffersActions,
    ...teamActions
})(withRouter(FeedItemsList));
