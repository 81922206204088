import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import Loader from '../../components/Loader';
import ClickablePaper from '../../components/ClickablePaper';

import withRouter from '../../utils/withRouter';
import * as time from '../../utils/time';
import currencyFormatter from '../../utils/currencyFormatter';

import * as userActions from '../../actions/user';

const UserGroupsList = props => {
  const {
    user,
    userGroups,
    history
  } = props;

  const [offerId, setOfferId] = React.useState(null);

  const [loading, setLoading] = React.useState(false);
  const [tab, setTab] = React.useState(0);

  return (
    <Box pb={10}>
      <Box mt={2} mb={2}>
        <Button variant="contained" component={RouterLink} to="./new" startIcon={
          <AddIcon />
        }>
          Create Group
        </Button>
      </Box>

      <Stack spacing={1}>

        {
          _.map(userGroups, group => {
            return (
              <ClickablePaper key={group.id} onClick={() => history.push(`./${group.id}`)}>
                <Stack spacing={1} direction="row" alignItems="center">
                  {
                    !!group.emoji &&
                      <Typography variant="h3" fontWeight={600}>
                        {group.emoji}
                      </Typography>

                  }

                  <Typography variant="body2" fontWeight={600}>
                    {group.name}
                  </Typography>
                </Stack>
              </ClickablePaper>
            )
          })
        }
      </Stack>

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user", "feedItems", "userGroups"]);
export default connect(select, {
  ...userActions,
})(withRouter(UserGroupsList));
