import _ from "lodash";
import * as React from 'react';
import { connect } from 'react-redux';
import { Outlet, Navigate } from "react-router-dom";
import { useFormik } from 'formik';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

import withRouter from '../../utils/withRouter';
import * as analytics from '../../utils/analytics';
import * as userActions from '../../actions/user';
import DialogCloseButton from '../../components/DialogCloseButton';

import AutocompleteTeams from '../../components/AutocompleteTeams';

function OnboardingFootball(props) {
  const {
    saveFavorites,
    leagues,
    teams,
    history,
    integrations,
    createIntegration,
    updateIntegration,
    user
  } = props;

  const onClose = () => history.replace("../");

  const [sleeperOpen, setSleeperOpen] = React.useState(false);
  const toggleSleeperOpen = () => setSleeperOpen(!sleeperOpen);

  const city =_.get(user, ["city"])
  const nfl = _.find(leagues, l => _.lowerCase(l.abbrv) == "nfl");
  const nflTeams = _.chain(teams)
    .filter(t => t.league_id == nfl.id)
    .sortBy("name")
    .value()

  const formik = useFormik({
    initialValues: {
      team_id: "",
    },
    onSubmit: async ({team_id}) => {
      try {
        await saveSports(team_id)

        window.localStorage.setItem("reward", "true");
        history.push("/app")
      } catch(err) {
        window.localStorage.setItem("reward", "true");
        history.push("/app")
      }
    },
  });

  const saveSports = async team_id => {
    try {
      const team = _.find(teams, t => t.id == team_id);
      if (team == null) return;

      const otherCityTeams = _.chain(teams)
        .reject(t => t.id == team_id)
        .filter(t => t.region_name == team.region_name)
        .map(t => {
          return {
            favoritable_id: t.id,
            favoritable_type: "Team"
          }
        })
        .value()

      await saveFavorites(
        user,
        [
          ...otherCityTeams,
          {
            favoritable_id: team_id,
            favoritable_type: "Team"
          }
        ]
      );
    } catch(err) {

    }
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs"
      sx={{zIndex: 10000000}}>
      <DialogTitle sx={{p: 2}}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box />

          <DialogCloseButton onClose={onClose} />
        </Stack>

        <Box mt={3}>
          <Typography variant="body1" fontWeight={600} lineHeight={1.3}>
            Last step, what's your NFL team?
          </Typography>

          <Typography variant="body2" color="text.secondary" fontWeight={500}>
            You'll get cash back on shopping with your Gravy card if cool things
            happen in sports, culture, and news.
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent sx={{p: 2}}>
        <Box mt={1}>
          <form onSubmit={formik.handleSubmit} id="football-form">
            <AutocompleteTeams
              fullWidth
              label={"Pick an NFL team"}
              teams={nflTeams}
              value={formik.values.team_id}
              onChange={(e, value) => {
                formik.setFieldValue("team_id", value.id)
              }} />

          </form>
        </Box>
      </DialogContent>
      <DialogActions sx={{p: 3}}>
        <LoadingButton
          loading={formik.isSubmitting}
          variant="contained"
          type="submit"
          form="football-form">
          Continue
        </LoadingButton>
      </DialogActions>

    </Dialog>
  );
}

const select = $$state => _.pick($$state, "user", "leagues", "teams", "integrations");
export default connect(select, {
  ...userActions,
})(withRouter(OnboardingFootball));
