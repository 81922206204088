import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import * as payoutActions from '../../actions/payouts';
import * as userActions from '../../actions/user';

import PlaidLinkButton from '../../components/PlaidLinkButton';
import Link from '../../components/Link';

function PayoutsDetails(props) {
  const { payouts, user, savePlaidUser, history, params } = props;

  const payout = _.find(payouts, p => p.id == params.payoutId)

  const [saving, setSaving] = React.useState(false);

  const onClose = () => history.replace("../");

  const onSuccess = async token => {
    try {
      setSaving(true);

      const results = await savePlaidUser(user, token);
      if (results.errors) {
        alert(results.errors.message)
      } else {
        window.location.reload()
      }
    } catch(err) {
      alert("Unknown error, please try again")
    } finally {
      setSaving(false);
    }
  }

  if (payout == null) return <Box />;

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>
        <Box mt={2}>
          <Typography variant="h2" fontWeight={600} lineHeight={1.3}>
            {currencyFormatter(payout.amount)} Gift Card
          </Typography>
          {
            payout.status == "pending" ?
              <Typography variant="h4">
                Why is this payout pending?
              </Typography> :
              <Typography variant="body1">
                Your card is viewable <Link color="secondary" component={RouterLink} to="/app/cards" fontWeight={600}>here</Link>.
              </Typography>
          }
        </Box>
      </DialogTitle>
      <DialogContent>
        {
          payout.status == "pending" &&
            <Box>
              <Typography variant="body2" color="text.secondary" fontWeight={500}>
                To prevent fraud, please connect your credit card.
                When you do, your payouts will be completed.
              </Typography>
            </Box>
        }

      </DialogContent>

      <DialogActions sx={{p: 3}}>
        {
          payout.status == "pending" &&
            <PlaidLinkButton onSuccess={onSuccess} />
        }
      </DialogActions>
    </Dialog>

  );
}

const select = $$state => _.pick($$state, "user", "payouts");
export default connect(select, {
  ...payoutActions,
  ...userActions
})(withRouter(PayoutsDetails));
