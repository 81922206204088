import _ from "lodash";
import { createBrowserHistory } from "history";
import { compose, createStore, applyMiddleware, combineReducers } from "redux";

// See
// https://github.com/gaearon/redux-thunk and http://redux.js.org/docs/advanced/AsyncActions.html
// This is not actually used for this simple example, but you'd probably want to use this
// once your app has asynchronous actions.
import thunkMiddleware from "redux-thunk";

// This provides an example of logging redux actions to the console.
// You'd want to disable this for production.
import loggerMiddleware from "./middleware/logger";

import rootReducer from "./reducers";
import initialState from "./config/initialState";
import { debugModeEnabled } from "./environment";

export const history = createBrowserHistory();

export default (props) => {
  // This is how we get initial props Rails into redux.
  const { name } = props;
  const middlewares = [thunkMiddleware];

  if (debugModeEnabled()) {
    middlewares.push(loggerMiddleware);
  }

  const composeEnhancers =
    (debugModeEnabled() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  // const reducer = combineReducers({ reducers });
  const composedStore = composeEnhancers(
    // applyMiddleware(thunkMiddleware, loggerMiddleware)
    applyMiddleware(...middlewares)
  );
  const storeCreator = composedStore(createStore);
  return storeCreator(rootReducer, {
    ...initialState,
    ...props
  });
};
