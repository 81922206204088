import _ from "lodash";

// This logger should be configured not to run in a production environment.
// See https://github.com/petehunt/webpack-howto#6-feature-flags for how you might turn this
// off for production.
export default function logger({ getState }) {
  return (next) => (action) => {
    // Call the next dispatch method in the middleware chain.
    console.log("will dispatch", action);
    const result = next(action);

    const $$state = _.omit(_.merge({}, getState(), { order: { options: null } }), [
      "styles",
      "uploader",
    ]);

    // console.log('state after dispatch', JSON.stringify($$state));

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return result;
  };
}
