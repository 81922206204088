import _ from "lodash";
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import Link from '../../components/Link';
import Alert from '../../components/Alert';

import UberEatsLogo from '../../icons/UberEatsLogo';
import StarbucksLogo from '../../icons/StarbucksLogo';
import SeatgeekLogo from '../../icons/SeatgeekLogo';
import ChipotleLogo from '../../icons/ChipotleLogo';

export default function OnboardingPreview(props) {
  const { open, onClose } = props;

  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const offers = [{
    icon: <UberEatsLogo />,
    title: "100% refund on your next order if",
    proposition: "Your favorite point guard scores 50+"
  }, {
    icon: <StarbucksLogo />,
    title: "$5 refund on your next order if",
    proposition: "Your football team wins this weekend"
  }, {
    icon: <SeatgeekLogo height={27} />,
    title: "100% refund on your next order if",
    proposition: "Your favorite pitcher throws a shutout"
  },{
    icon: <ChipotleLogo height={27} />,
    title: "$10 refund on your next order if",
    proposition: "Your favorite UFC fighter records a knockout"
  }]

  return (
    <Box mt={4}>
      <Typography mb={2} variant="body3" fontWeight={600} color="text.secondary">
        Your Gravy offers are cooking...
      </Typography>

      <Stack spacing={1}>
        {
          _.map(offers, offer => {
            return (
              <Paper key={offer.title} variant="outlined" sx={{p: 2}}>
                <Stack direction="row" spacing={2} alignItems="center">
                  {
                    offer.icon
                  }

                  <Box>
                    <Typography variant="body3" fontWeight={600}>
                      {offer.title}
                    </Typography>
                    <Typography variant="body3" color="text.secondary" fontWeight={500}>
                      {offer.proposition}
                    </Typography>
                  </Box>
                </Stack>
              </Paper>
            )
          })
        }
      </Stack>
    </Box>
  );
}
