import _ from "lodash";
import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import IconButton from '@mui/material/IconButton';

import * as analytics from '../utils/analytics';

export default function GravyVideo({play}) {
  const videoRef = React.useRef(null);
  const [playing, setPlaying] = React.useState(false);

  const handleClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        analytics.logEvent("GravyVideo play");

        videoRef.current.play();
        setPlaying(true);
      } else {
        videoRef.current.pause();
        setPlaying(false);
      }
    }
  };

  React.useEffect(() => {
    if (play && videoRef.current) handleClick()
  }, [play]);

  return (
    <Paper variant="outlined" sx={{
      position: "relative",
      display: "inline-block",
      width: "100%"
    }}>
      <video
        ref={videoRef}
        onClick={handleClick}
        style={{ width: '100%', borderRadius: 10 }}
        poster="https://p49.tr4.n0.cdn.zight.com/items/xQuooJZE/352951e8-bed5-4ada-8377-d1210feeaf47.png?source=viewer&v=fbfd010fb236db28df4113730a7e145c"
        controls={false}>
        <source
          src="https://res.cloudinary.com/dxxog3y9j/video/upload/v1720115781/Gravy_v5_xyw6xv.mp4"
          type="video/mp4"
        />
      </video>
      {!playing && (
        <IconButton onClick={handleClick} sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "#fff",
          background: "rgba(0, 0, 0, 0.5)",
        }}>
          <PlayCircleIcon sx={{fontSize: 30}} />
        </IconButton>
      )}
    </Paper>
  );
};