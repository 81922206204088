import _ from "lodash";
import moment from 'moment';
import * as React from "react";
import { connect } from 'react-redux';
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import Iconify from '../../components/iconify';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';
import categoryBackgrounds from '../../utils/categoryBackgrounds';
import * as merchantOffersActions from '../../actions/merchantOffers';
import * as cardActions from '../../actions/cards';
import withRouter from '../../utils/withRouter';

import CardsList from '../cards/List';

function ShopList(props) {
  const {
    merchantOffers,
    fetchMerchantOffers,
    cards,
    fetchCards,
    params,
    history
  } = props;

  const [loading, setLoading] = React.useState(true)


  const onClose = () => history.replace("../");

  React.useEffect(() => {
    Promise.all([
      merchantOffers.length == 0 ? fetchMerchantOffers() : Promise.resolve(),
      cards.length == 0 ? fetchCards() : Promise.resolve(),
    ]).finally(() => setLoading(false))
  }, [])


  return (
    <Box>
      <Box>

        <Paper variant="outlined" sx={{
          p: {
            xs: 3,
            sm: 4
          }
        }}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Stack direction={{
              xs: "column",
              sm: "column"
            }} spacing={2}>
              <Box sx={{
                height: {
                  xs: 80,
                  sm: 80
                }
              }}>
                <img src={"https://p70.p4.n0.cdn.zight.com/items/z8uRWXYe/28a41117-d7a4-444e-ad09-8eb6373a068f.png?v=50d4dfa6dd9a4815ff8d483432b8c6d9"} style={{
                  height: "100%",
                  borderRadius: 5
                }} />
              </Box>

              <Box>
                <Box mb={{
                  xs: 1,
                  sm: 1
                }}>
                  <Typography variant={"h2"} fontWeight={600} lineHeight={1}>
                    Gravy Visa Card
                  </Typography>
                  <Typography variant="h4" fontWeight={400}>
                    Earn Pours on every swipe
                  </Typography>
                </Box>

                <Button fullWidth component={RouterLink} to="./gravy" color="primary" variant="contained" size="large">
                  Buy Card
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Paper>
      </Box>

      <CardsList />

      <Outlet  />
    </Box>
  )
}

const select = $$state => _.pick($$state, ["user", "merchantOffers", "cards"]);
export default connect(select, {
  ...merchantOffersActions,
  ...cardActions,
})(withRouter(ShopList));
