import _ from "lodash";
import mixpanel from "mixpanel-browser";

let initialized = false;

export const initialize = () => {
  if (initialized) return;

  initialized = true;

  mixpanel.init("72e85ac80c69a38bd19a4fe86072a024", {
    track_pageview: true,
    persistence: "localStorage",
  });
};

export const identify = (userId) => {
  mixpanel.identify(userId);
};

export const logEvent = (event, data = {}) => {
  mixpanel.track(event, data);
};

export const setUserProperties = (properties) => {
  mixpanel.people.set({
    ...properties,
    $email: properties.email,
    $phone: properties.phone,
  });
};
