import _ from 'lodash';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';


export default function MarketingPricing(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box mt={6} mb={6}>
      <Box>
        <Container maxWidth="sm">
          <Stack alignItems="center">
            <Typography textAlign="center" style={{
              color: theme.palette.primary.main,
              fontSize: mobile ? "1em" : "1.8em",  
              fontWeight: 600,
              lineHeight: "1.2em",
              letterSpacing: "-0.03rem"
            }}>
              Our product is free for shoppers
            </Typography>

            <Box mt={1}>
              <Typography variant={mobile ? "body3" : "body2"} textAlign="center">
                Brands ultimately pay us to help run these offers which cost them roughly
                the same amount as their fixed discounts. They pay insurance premiums.
              </Typography>
            </Box>
          </Stack>
        </Container>
      </Box>

      <Box mt={3}>
        <Stack alignItems="center">
          <ArrowDownwardIcon />
        </Stack>
      </Box>

      <Box mt={4}>
        <Container maxWidth="md">
          <Grid spacing={2} container>
            <Grid item xs={12} md={6}>
              <Paper sx={{p: 3, minHeight: 250}} variant="outlined">
                <Typography variant="body2" fontWeight={600} gutterBottom>
                  Shopper Pricing
                </Typography>

               <Typography variant="body2">
                  When you receive an offer from a retailer through Gravy, you will never have
                  to pay Gravy...ever.
                </Typography>

                <Box mt={4} mb={3}>
                  <Stack spacing={4} direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="body3" fontWeight={600}>
                      Service fees
                    </Typography>

                    <Chip
                      label={
                        <Typography variant="body4" fontWeight={600}>
                          0%
                        </Typography>
                      }  />
                  </Stack>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{p: 3, minHeight: 250}} variant="outlined">
                <Typography variant="body2" fontWeight={600} gutterBottom>
                  Brand Pricing
                </Typography>

               <Typography variant="body3">
                  Our pricing model is similar to insurance. You request a certain amount of coverage
                  to refund your shoppers if a sports event occurs and we'll charge a premium based on underwriting
                  the probability of the event.
                </Typography>

                <Box mt={3}>
                  <Button color="secondary" variant="contained" fullWidth href="https://tailrisk.ai" target="_blank" sx={{
                    color: "#fff"
                  }}>
                    Visit our website for brands
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}
