import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';

import Loader from '../../components/Loader';
import Link from '../../components/Link';
import Iconify from '../../components/iconify';
import Alert from '../../components/Alert';

import withRouter from '../../utils/withRouter';
import * as cardActions from '../../actions/cards';
import * as userActions from '../../actions/user';

import ClickablePaper from '../../components/ClickablePaper';
import PlaidLinkButton from '../../components/PlaidLinkButton';
import Card from "./Card";

function CardsList(props) {
  const { cards, activeCard, setActiveCard, history } = props;

  return (
    <Stack spacing={2}>
      {cards.map((card) => (
        <Box          key={card.id}
        >
          <ClickablePaper
            onClick={() => {
              history.push(`./${card.id}`)
            }}
            variant="outlined"
            selected={card.id == activeCard.id}
            sx={{
              p: 2,
            }}>
            <Box height={60}>
              {
                card.source == "plaid" ?
                  <Box sx={{position: "relative", top: 10}}>
                    <Typography variant="body2" fontWeight={600}>{card.name}</Typography>
                  </Box> :
                  <Iconify width={40} icon="logos:visa" />

              }
            </Box>

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">{
                `******${card.last_4}`
              }</Typography>

              <Button variant="outlined" size="small">
                Details
              </Button>
            </Stack>

          </ClickablePaper>
        </Box>
      ))}
    </Stack>
  )
}

const CardsMain = props => {
  const {
    user,
    cards,
    fetchCards,
    savePlaidUser,
    merchantOffers
  } = props;

  const [loading, setLoading] = React.useState(true);
  const [activeCard, setActiveCard] = React.useState({});
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    if (cards.length == 0) {
      fetchCards().finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [])

  const sortedCards = _.sortBy(cards, [
    (card) => card.id !== activeCard.id,
    (card) => card.source !== "plaid",
    (card) => new Date(card.created_at)
  ])

  React.useEffect(() => {
    if (cards.length > 0) setActiveCard(
      _.get(sortedCards, "0", {})
    )
  }, [cards.length])

  const connectToPlaid = async token => {
    try {
      setSaving(true)
      const results = await savePlaidUser(user, token);
      if (results.errors) {
        alert(results.errors.message)
      } else {
        window.location.href = "/app/account/cards/"
      }
    } catch(err) {
      console.log(err)
      alert("Unknown error please try again")
    } finally {
      setSaving(false)
    }
  }

  return (
    <Box pb={10}>
      <Container maxWidth="sm">
        <Box>
          <Paper variant="outlined" sx={{
            p: {
              xs: 3,
              sm: 4
            }
          }}>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Stack direction={{
                xs: "column",
                sm: "column"
              }} spacing={2}>
                <Box sx={{
                  height: {
                    xs: 80,
                    sm: 80
                  }
                }}>
                  <img src={"https://p70.p4.n0.cdn.zight.com/items/z8uRWXYe/28a41117-d7a4-444e-ad09-8eb6373a068f.png?v=50d4dfa6dd9a4815ff8d483432b8c6d9"} style={{
                    height: "100%",
                    borderRadius: 5
                  }} />
                </Box>

                <Box>
                  <Box mb={{
                    xs: 1,
                    sm: 1
                  }}>
                    <Typography variant={"h2"} fontWeight={600} lineHeight={1}>
                      Gravy Visa Card
                    </Typography>
                    <Typography variant="h4" fontWeight={400}>
                      Earn Pours on every swipe
                    </Typography>
                  </Box>

                  <Button fullWidth component={RouterLink} to="./gravy" color="primary" variant="contained" size="large">
                    Buy Card
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </Paper>
        </Box>
        <Box mt={2}>
          <CardsList
            {...props}
            cards={sortedCards}
            activeCard={activeCard}
            setActiveCard={setActiveCard} />
        </Box>
      </Container>

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user", "cards", "merchantOffers"]);
export default connect(select, {
  ...cardActions,
  ...userActions
})(withRouter(CardsMain));
