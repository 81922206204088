import _ from "lodash";

import actionTypes from "../constants/index";

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.offers.CREATE:
      return _.uniqBy([
        payload.offer,
        ...$$state
      ], "id");
    case actionTypes.offers.INDEX:
      const offersById = _.keyBy($$state, 'id'); // Convert current state to an object keyed by 'id'

      // Merge and update the existing offers with the new ones
      payload.offers.forEach(offer => {
        offersById[offer.id] = { ...offersById[offer.id], ...offer };
      });

      // Convert the object back to an array
      return Object.values(offersById);
    case actionTypes.offers.UPDATE:
      return _.map($$state, d => d.id == payload.offer.id ?
          {
            ...d,
            ...payload.offer
          } : d
      )
    case actionTypes.offers.DELETE_ALL:
      return [];
    case actionTypes.offers.DELETE:
      return _.reject($$state, d => d.id == payload.offer.id)
    default:
      return $$state;
  }
};
