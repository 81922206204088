import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

export const fetchPicks = () => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`picks`)

    if (!data.errors) {
      dispatch({
        type: actionTypes.picks.INDEX,
        payload: {
          picks: _.map(data, d => {
            return {
              id: d.id,
              ...d.attributes
            }
          })
        }
      });
    }

    return data;
  }
};


export const deletePick = id => {
  return {
    type: actionTypes.picks.DELETE,
    payload: {
      id
    },
  };
};