const categories = [{
  title: "Did you invent these cashback offers?",
  subtitle: "History of conditional rebates",
  path: "history",
  pages: [{
    title: "What are conditional rebates?",
    path: "conditional-what",
    component: "ConditionalRebateDescription"
  }, {
    title: "Why would brands offers these sports deals?",
    path: "conditional-why",
    component: "ConditionalRebateWhy"
  }, {
    title: "What are some real world examples of these offers?",
    path: "conditional-history",
    component: "ConditionalRebateHistory"
  }]
}, {
  title: "Fees",
  subtitle: "Will Gravy charge me any fees?",
  path: "fees",
  pages: [{
    title: "Are there any fees?",
    path: "fees-consumers",
    component: "ConsumerFees"
  }, {
    title: "If you don't charge shoppers fees, how do you make money as a business?",
    path: "fees-brands",
    component: "BrandFees"
  }]
}, {
  title: "Reimbursements",
  subtitle: "Receiving your refunds",
  path: "refunds",
  pages: [{
    title: "How will I receive my refunds?",
    path: "methods",
    component: "RefundHow"
  },
  {
    title: "When will I receive my refund?",
    path: "schedule",
    component: "RefundWhen"
  }]
}]

export default categories;