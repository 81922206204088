import _ from "lodash";
import * as React from "react";
import {Link as RouterLink, Outlet, Navigate, useLocation, useParams} from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckIcon from '@mui/icons-material/Check';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import Loader from '../../components/Loader';
import ClickablePaper from '../../components/ClickablePaper';

import currencyFormatter from '../../utils/currencyFormatter';

export default function LeaderboardList({leaders}) {
  return (
    <Box>
      <Grid container spacing={{xs: 1, sm: 2}}>
        <Grid item xs={12} md={6}>
          <Paper sx={{
            p: {
              xs: 1,
              md: 3
            }
          }} variant="outlined">
            <Stack direction="row" spacing={{
              xs: 0,
              md: 1
            }} alignItems="center">
              <AttachMoneyIcon sx={{
                display: {
                  xs: "none",
                  sm: "block"
                }
              }} />
              <Typography variant={"body2"} fontWeight={600}>
                Total Savings
              </Typography>
            </Stack>

            <Box mt={{xs: 2, md: 4}}>
              <Stack spacing={{xs: 2, md: 3}}>
                {
                  _.map(leaders.savings, (leader, i) => (
                    <Box key={i}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{flexGrow: 1}}>

                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="body4" fontWeight={600} sx={{
                            width: 25,
                            textAlign: "center",
                            background: "black",
                            p: 0.5,
                            borderRadius: 2,
                            color: "#fff"
                          }}>
                            {i + 1}
                          </Typography>
                          <Typography variant="body3" fontWeight={600}>
                            {leader.username}
                          </Typography>

                        </Stack>

                        <Typography variant="body3" fontWeight={600}>
                          {currencyFormatter(leader.savings, 2)}
                        </Typography>
                      </Stack>
                    </Box>
                  ))
                }
              </Stack>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6} md={6} sx={{
          display: {
            xs: "none",
            md: "initial"
          }
        }}>
          <Paper
            sx={{
              p: {
                xs: 1,
                md: 3
              }
            }}
            variant="outlined">
             <Stack direction="row" spacing={{
              xs: 1,
              md: 1
            }} alignItems="center">
              <CheckIcon sx={{
                display: {
                  xs: "none",
                  sm: "block"
                }
              }} />
              <Typography variant="body2" fontWeight={600}>
                Wins
              </Typography>
            </Stack>


            <Box mt={{xs: 2, md: 4}}>
              <Stack spacing={{xs: 2, md: 3}}>
                {
                  _.map(leaders.wins, (leader, i) => (
                    <Box key={i}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{flexGrow: 1}}>

                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="body4" fontWeight={600} sx={{
                            width: 25,
                            textAlign: "center",
                            background: "black",
                            p: 0.5,
                            borderRadius: 2,
                            color: "#fff"
                          }}>
                            {i + 1}
                          </Typography>
                          <Typography variant="body3" fontWeight={600}>
                            {leader.username}
                          </Typography>

                        </Stack>

                        <Typography variant="body3" fontWeight={600}>
                          {leader.wins}
                        </Typography>
                      </Stack>
                    </Box>
                  ))
                }
              </Stack>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
