import _ from "lodash";

import actionTypes from "../constants/index";

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.userGroups.CREATE:
      return _.uniqBy([
        payload.userGroup,
        ...$$state
      ], "id");
    case actionTypes.userGroups.INDEX:
      return payload.userGroups;
    case actionTypes.offers.UPDATE:
      return _.map($$state, d => d.id == payload.userGroup.id ?
          {
            ...d,
            ...payload.userGroup
          } : d
      )
    case actionTypes.userGroups.DELETE_ALL:
      return [];
    case actionTypes.userGroups.DELETE:
      return _.reject($$state, d => d.id == payload.userGroup.id)
    default:
      return $$state;
  }
};
