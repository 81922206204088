import _ from "lodash";

import actionTypes from "../constants/index";

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.integrations.CREATE:
      return [
        payload.integration,
        ...$$state
      ];
    case actionTypes.integrations.INDEX:
      return payload.integrations;
    case actionTypes.integrations.UPDATE:
      return _.map($$state, i => {
        return i.id == payload.integration.id ?
          {
            ...i,
            ...payload.integration
          } : i
      })
    default:
      return $$state;
  }
};
