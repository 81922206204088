import _ from "lodash";
import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import Link from "../../components/Link";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";

export default function OfferLost(props) {
  const {
    offers,
    user,
    deleteOffer,
    params,
    history,
    location,
  } = props;

  return (
    <Box>
      <Alert color="error" variant="filled">
        <Typography variant="body2" fontWeight={500}>
          You didn't win this time but there's always next time with Gravy!
        </Typography>
      </Alert>
    </Box>
  );
}
