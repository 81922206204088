import _ from "lodash";

import actionTypes from "../constants/index";

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.propositions.CREATE:
      return [
        payload.proposition,
        ...$$state
      ];
    case actionTypes.propositions.INDEX:
      return payload.propositions;
    case actionTypes.propositions.UPDATE:
      return _.map($$state, i => {
        return i.id == payload.proposition.id ?
          {
            ...i,
            ...payload.proposition
          } : i
      })
    default:
      return $$state;
  }
};
