import _ from "lodash";
import * as React from "react";
import Promise from 'bluebird';
import { Outlet, Navigate } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import withRouter from '../../utils/withRouter';
import * as merchantOffersActions from '../../actions/merchantOffers';
import * as integrationActions from '../../actions/integrations';
import request from '../../utils/request';

import Loader from '../../components/Loader';

function ShopMain(props) {
  const {
    user,
    merchantOffers,
    fetchMerchantOffers,
    integrations,
    fetchIntegrations
  } = props;

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (integrations.length == 0) fetchIntegrations()

  }, [])

  return (
    <Box pb={12}>
      <Container maxWidth="sm">

        {
          loading ?
            <Loader /> :
            <Outlet context={props} />
        }
      </Container>
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user", "merchantOffers", "integrations"]);
export default connect(select, {
  ...merchantOffersActions,
  ...integrationActions
})(withRouter(ShopMain));
