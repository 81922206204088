import _ from "lodash";
import * as React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import withRouter from '../../utils/withRouter';
import * as merchantOffersActions from '../../actions/merchantOffers';
import request from '../../utils/request';

import Loader from '../../components/Loader';

function EarnMain(props) {
  const {
    user,
    merchantOffers,
    fetchMerchantOffers,
  } = props;

  return (
    <Box pb={12}>
      <Container maxWidth="sm">
        <Outlet context={props} />
      </Container>
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user", "merchantOffers"]);
export default connect(select, {
  ...merchantOffersActions,
})(withRouter(EarnMain));
