import _ from "lodash";
import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  Paper,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChevronRight } from 'lucide-react'

import request from '../../utils/request';
import ClickablePaper from '../../components/ClickablePaper';

function Timer() {
  const [timeRemaining, setTimeRemaining] = React.useState(60 * 60); // 60 minutes in seconds

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining(timeRemaining - 1);
      } else {
        clearInterval(intervalId);
        // Optionally, do something when the timer reaches 0
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [timeRemaining]);

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;

  return (
    <Typography variant="h1" textAlign="center">
      {minutes}:{seconds.toString().padStart(2, '0')}
    </Typography>
  );
}

function Checkout(props) {
  const { open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{p: 3}}>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box mt={2}>
          <Typography variant={"h3"} fontWeight={600} lineHeight={1.3}>
            4256A
          </Typography>
          <Typography variant={"h4"} fontWeight={400} lineHeight={1.3}>
            Apply this code at checkout to
            get 100% off your purchase if the Cowboys cook this weekend.
          </Typography>
        </Box>

      </DialogTitle>
      <DialogContent sx={{p: 3}}>
        <Typography textAlign="center" variant="body4" fontWeight={600} textTransform="uppercase">
          Offer Expires In
        </Typography>
        <Timer />
      </DialogContent>
      <DialogActions sx={{p: 3}}>
        <Button target="_blank" href="https://littlecaesars.com/en-us/order/pickup/" variant="contained" sx={{
          background: "#ff6000 !important"
        }}>
          Pick Up
        </Button>
        <Button target="_blank" href="https://littlecaesars.com/en-us/order/delivery/" variant="contained" sx={{
          background: "#ff6000 !important"
        }}>
          Delivery
        </Button>
      </DialogActions>
    </Dialog>
  )
}


function Teams(props) {
  const { open, onClose } = props;

  const teams = [
    "Bears",
    "Cowboys",
    "Eagles",
    "Giants",
    "Niners",
    "Other"
  ]

  return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={{p: 3}}>
          <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box />

              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>

            </Stack>
          </Box>

          <Box mt={2}>
            <Typography variant={"h3"} fontWeight={600} lineHeight={1.3}>
              Who's your favorite NFL team?
            </Typography>
            <Typography variant={"h4"} fontWeight={400} lineHeight={1.3}>
              You'll get free food if they cook this weekend.
            </Typography>
          </Box>

        </DialogTitle>
        <DialogContent sx={{p: 3}}>
          <Stack spacing={1}>
            {
              _.map(teams, team => {
                return (
                  <ClickablePaper key={team} onClick={onClose}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography variant={"body2"} fontWeight={600} lineHeight={1.3}>
                        {team}
                      </Typography>

                      <ChevronRight />
                    </Stack>
                  </ClickablePaper>
                )
              })
            }
          </Stack>
        </DialogContent>
        <DialogActions>

        </DialogActions>
      </Dialog>
  )
}

function Offer(props) {
  const { open, onClose } = props;

  const [reward, setReward] = useState({});
  const [checkout, setCheckout] = useState(false);
  const [teamsOpen, setTeamsOpen] = useState(true);

  const toggleCheckout = () => setCheckout(!checkout);

  useEffect(() => {
    fetchReward()
  }, [])


  const fetchReward = async () => {
    setReward({
      id: "123",
      title: "Cowboys vs. Giants",
      propositions: [{
        win_description: "Dak Prescott throws 295+ yards"
      }, {
        win_description: "Rico Dowdle runs for 65+ yards"
      }]
    });
  }

  const { propositions, title } = reward;

  return (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={{p: 3}}>
          <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box />

              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>

            </Stack>
          </Box>

          <Box mt={2}>
            <Typography variant={"h3"} fontWeight={600} lineHeight={1.3}>
              Up to 100% off your next order
            </Typography>
            <Typography variant={"h4"} fontWeight={400} lineHeight={1.3}>
              If the Cowboys cook, you don't have to.
            </Typography>
          </Box>

        </DialogTitle>
        <DialogContent sx={{p: 3}}>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              borderRadius: 2,
            }}>
              <Box>
                <Box mb={3}>
                  <Box mt={1}>
                    <Typography mb={2} variant="body3" textTransform="uppercase" fontWeight={600}>
                      You win if
                    </Typography>

                    <Stack spacing={2}>
                      {
                        _.map(propositions, (prop, i) => {
                          const { win_description } = prop;

                          return (
                            <Stack direction="row" spacing={2} alignItems="center">
                              <Chip sx={{width: 60}} size="small" label={
                                <Typography variant={"body4"} fontWeight={600}>
                                  {
                                    i == 0 ? "IF" : "AND"
                                  }
                                </Typography>
                              } />

                              <Box key={prop.id}>
                                <Typography variant={"body1"} fontWeight={500} lineHeight={1.3}>
                                  {
                                    win_description
                                  }
                                </Typography>

                              </Box>
                            </Stack>
                          )
                        })

                      }
                    </Stack>
                  </Box>
                </Box>
                <Stack spacing={1}>
                  <Button fullWidth variant="contained" size="large" color="secondary" onClick={toggleCheckout} sx={{
                    background: "#ff6000 !important"
                  }}>
                    I want this
                  </Button>
                  <Button fullWidth variant="contained" size="large" color="primary">
                    More choices
                  </Button>
                </Stack>
              </Box>
          </Paper>
        </DialogContent>
        <DialogActions>

        </DialogActions>

      <Checkout
        open={checkout}
        onClose={toggleCheckout} />

      <Teams open={teamsOpen} onClose={() => {
        setTeamsOpen(false)
      }} />
    </Dialog>
  )
}

const FullScreenBackgroundWithDialog = () => {
  const [open, setOpen] = useState(false);
  const [reward, setReward] = useState(null);

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchReward()
  }, [])


  const fetchReward = async () => {
    setReward({
      parlay: {
        id: "123",
        title: "Cowboys vs. Giants",
        propositions: [{
          win_description: "Dak Prescott throws 295+ yards"
        }, {
          win_description: "Rico Dowdle runs for 65+ yards"
        },]
      }
    });
  }

  const { title, propositions } = _.get(reward, ["parlay"], {});

  return (
    <Box sx={{
      background: "#fff"
    }}>
      <Container maxWidth="sm">
        <Box pt={2}>
          <img
            style={{
              height: 80
            }}
            src="https://1000logos.net/wp-content/uploads/2023/04/Little-Caesars-Logo.png" />
        </Box>

        <Box mt={4}>
          <Typography variant="h2" fontWeight={600} lineHeight={1.3}>
            NFL Week 8 Special Offer
          </Typography>
          <Typography variant="h3" lineHeight={1.3} fontWeight={500}>
            Up to 100% off your order today ($15 max) if
            your favorite NFL team or players win big.
          </Typography>
        </Box>

        <Box mt={4}>
          <Button variant="contained" sx={{
            background: "#ff6000 !important"
          }} onClick={() => setOpen(true)}>
            Get Started
          </Button>
        </Box>

        <Offer
          open={open}
          onClose={() => setOpen(false)} />
      </Container>

      <Box
        onClick={() => setOpen(true)}
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          height: 70,
          width: '100vw',
          backgroundImage: `url('https://banners.littlecaesars.com/us/banner-assets/banner-NFL%20sponsorship%20banner-desktop-en-us.png')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 0, // Ensure the background is behind the dialog
        }}
      />
    </Box>
  );
};

export default FullScreenBackgroundWithDialog;
