import _ from 'lodash';
import Promise from 'bluebird';
import { connect } from 'react-redux';
import * as React from 'react';
import { Link as RouterLink, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Loader from '../../components/Loader';
import Link from '../../components/Link';

import withRouter from '../../utils/withRouter';
import * as teamsActions from '../../actions/teams';
import * as leaguesActions from '../../actions/leagues';
import * as playersActions from '../../actions/players';

import Leagues from './Leagues';
import Teams from './Teams';
import Players from './Players';

function AccountFavorites(props) {
  const {
    teams,
    fetchTeams,
    leagues,
    fetchLeagues,
  } = props;

  const [loading, setLoading] = React.useState(true);
  const [tab, setTab] = React.useState(0);

  const tabs = _.reject([
    "teams",
  ], _.isNil);

  const breadcrumbs = [
    <Link
      color="primary"
      underline="hover"
      component={RouterLink}
      to="../">
      <Typography variant="body3" fontWeight={600}>
        Account
      </Typography>
    </Link>,
    <Typography variant="body3" color="text.secondary" fontWeight={600}>
      Favorites
    </Typography>,
  ];

  React.useEffect(() => {
    Promise.all([
      teams.length == 0 ? fetchTeams() : Promise.resolve(),
      leagues.length == 0 ? fetchLeagues() : Promise.resolve(),
    ]).finally(() => setLoading(false))
  }, []);

  return (
   <Container maxWidth="sm">
      <Box mb={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
      </Box>

      <Tabs value={tab} onChange={(e, tab) => {
        setTab(tab)
      }}>
        {
          _.map(tabs, t => {
            return (
              <Tab key={t} sx={{
                textTransform: "inherit",
                fontWeight: 600,
                fontSize: 14
              }} label={
                _.capitalize(t)
              }  />
            )
          })
        }
      </Tabs>

      {
        loading ?
          <Loader /> :
          <Paper sx={{p: 2}} variant="outlined">
            {
              tab == 0 &&
                <Teams />
            }

            {
              tab == 1 &&
                <Leagues />
            }

          </Paper>
      }
    </Container>
  )
}

const select = $$state => _.pick($$state, "user", "teams", "leagues");
export default connect(select, {
  ...teamsActions,
  ...leaguesActions,
  ...playersActions
})(withRouter(AccountFavorites));
