import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink, Navigate } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LiquorIcon from '@mui/icons-material/Liquor';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import AirlinesIcon from '@mui/icons-material/Airlines';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import withRouter from '../../utils/withRouter';
import * as analytics from '../../utils/analytics';
import * as userActions from '../../actions/user';

import Footer from './Footer';

function OnboardingOffers(props) {
  const {
    user,
    savePreferences,
    history
  } = props;

  const offers = [{
    description: "Food delivery",
    type: "food_delivery",
    icon: <LocalShippingIcon sx={{fontSize: 50}} />
  },{
    description: "Bars and restaurants",
    type: "bar",
    icon: <LiquorIcon sx={{fontSize: 30}} />
  },  {
    description: "Clothing and Apparel",
    type: "clothing"
  }, {
    description: "Sports tickets",
    type: "restaurant",
    icon: <LocalShippingIcon sx={{fontSize: 30}} />
  }, {
    description: "Travel",
    type: "travel",
    icon: <AirlinesIcon sx={{fontSize: 30}} />
  }, {
    description: "Car ",
    type: "car",
    icon: <DirectionsCarIcon sx={{fontSize: 30}} />
  },]

  const formik = useFormik({
    initialValues: {
      preferences: user.preferences == null ? {
        food_delivery: 0,
        cothing: 0,
        coffee: 0,
        restaurant: 0,
        bar: 0
      } : user.preferences
    },
    onSubmit: async ({preferences}) => {
      try {
        const results = await savePreferences(user, preferences);
        if (results.errors) {
          alert(results.errors.message)
        } else {
          history.push(`../identity`)
        }
      } catch(err) {
        alert("There was an error")
      } finally {
      }
    },
  });

  return (
    <Box pb={14}>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={4}>
          <Typography variant="body1" fontWeight={600}>
            What offers matter to you?
          </Typography>

          <Typography variant="body2" color="text.secondary" fontWeight={500}>
            You'll receive big refunds when your teams or players win big.
          </Typography>
        </Box>

        <Grid spacing={3} container>
            {
              _.chain(offers)
                .map((offer, i) => {
                  return (
                    <Grid item key={i} xs={6}>
                      <ClickablePaper variant="outlined" sx={{px: 3, height: 150}}>
                        <Stack spacing={2} alignItems="center" justifyContent="space-around">
                          <Typography variant="body3" fontWeight={700} mb={1}>
                            {
                              offer.description
                            }
                          </Typography>

                          {offer.icon}
                        </Stack>
                      </ClickablePaper>
                    </Grid>
                  )
                })
                .value()
            }
        </Grid>

        <Footer
          loading={formik.isSubmitting}
          progress={90} />
      </form>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user");
export default connect(select, {
  ...userActions,
})(withRouter(OnboardingOffers));
