import _ from "lodash";
import moment from 'moment';
import { useSwipeable } from 'react-swipeable';
import * as React from "react";
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';
import categoryBackgrounds from '../../utils/categoryBackgrounds';
import FullScreenDialog from '../../components/FullScreenDialog';
import withRouter from '../../utils/withRouter';

import {useEffect} from "react";
import {usePostHog} from "posthog-js/react";

function FeedItemDetails(props) {
  const {
    feedItems,
    offers,
    params,
    history
  } = props;

  const posthog = usePostHog();

  const [storyOpen, setStoryOpen] = React.useState(false);

  const toggleStory = () => setStoryOpen(!storyOpen);

  const feedItem = _.find(feedItems, fi => fi.id == params.feedItemId);

  const relevantOffers = _.filter(offers, o => o.feed_item_id == params.feedItemId);

  const proposition = _.chain(relevantOffers)
    .map(o => o.proposition)
    .find(p => p.id == params.propositionId)
    .value();

  const order = [
    "coffee",
    "restaurant",
    "food_delivery",
    "bar",
    "groceries",
    "dessert",
    "clothing",
    "ticketing"
  ];

  const categories = _.chain(relevantOffers)
    .filter(o => o.proposition.id == proposition.id)
    .groupBy("category")
    .keys()
    .sortBy(category => {
      const index = _.indexOf(order, category);
      return index === -1 ? Number.MAX_VALUE : index;
    })
    .value();

  if (_.isEmpty(feedItem) || _.isEmpty(proposition)) return <Box />;

  const { story, title, description, start_at, image_url } = feedItem;

  const started = time.getTimeTill(start_at) == "Expired";


  const onClose = () => history.replace("../");

  const prettyCategoryName = category => {
    const lastLetter = category[category.length - 1];

    switch(lastLetter) {
      case "s":
      case "a":
      case "e":
      case "i":
      case "o":
      case "u":
      case "g":
        return category;
      case "y":
        return `${_.trimEnd(category, lastLetter)}ies`
      default:
        return `${category}s`
    }
  }

  return (
    <FullScreenDialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle sx={{
        p: 0,
        height: 205,
        position: "relative",
        backgroundSize: "cover",
        backgroundImage: `url(${image_url})`
      }}>

          <Box p={2} sx={{
            position: "absolute",
            bottom: -25,
            left: 0,
            right: 45,
            background: "rgba(29, 48, 121, 0.8)",
            borderRadius: 2,
            zIndex: 10000000
          }}>
            <Typography variant="body1" fontWeight={700} mb={2} style={{
              lineHeight: 1.3,
              fontSize: "15px",
              color: "rgba(255, 255, 255, 1)",
              textTransform: "uppercase"
            }}>
              {
                title
              }
            </Typography>

            <Typography variant="h6" fontWeight={500} sx={{
              lineHeight: 1.3,
              fontSize: "22px",
              color: "rgba(255, 255, 255, 1)",
            }}>
              If {
                proposition.winDescription
              }
            </Typography>
        </Box>

      </DialogTitle>
      <DialogContent sx={{
        position: "relative",
        background: "#141c3f",
        p: 2,
      }}>

        <Box mt={6}>
          <Grid container spacing={1}>
            {
              _.map(categories, (category, i) => {
                const background = categoryBackgrounds[category]

                return (
                  <Grid item xs={6} key={i}>
                    <Paper p={2} sx={{
                      height: 130,
                      display: "flex",
                      backgroundSize: "cover",
                      backgroundImage: `url(${background})`,
                      position: "relative",
                      cursor: "pointer"
                    }} onClick={() => history.push(`./${category}`)}>
                          <Box sx={{
                            background: "rgba(0,0,0,0.65)",
                            p: 1,
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                          }}>
                            <Typography variant="body4" fontWeight={700} lineHeight={1.2} color="#fff" textTransform="uppercase">
                              Save on {
                                _.lowerCase(prettyCategoryName(category))
                              }
                            </Typography>
                          </Box>
                    </Paper>
                  </Grid>
                )
              })
            }

          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{
        p: 2,
        backgroundColor: "#141c3f",
        justifyContent: "start"
      }}>
        <Button onClick={onClose} startIcon={<ArrowBackIosNewIcon />} sx={{color: "#fff", fontWeight: 600}}>
          Back
        </Button>
      </DialogActions>
      <Outlet />
    </FullScreenDialog>

  )
}


const select = $$state => _.pick($$state, ["user", "offers", "feedItems"]);
export default connect(select, {
})(withRouter(FeedItemDetails));
