import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

export default function Logo(props) {
  return (
    <IconButton sx={{position: "relative", left: -10}} component={RouterLink} to="/">
      <img
        src="https://res.cloudinary.com/dxxog3y9j/image/upload/v1726686197/gravy_1_we9oi7.png"
        style={{
          height: 40
        }}/>

    </IconButton>
  );
}