import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import { Link as RouterLink } from "react-router-dom";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { GreySection, Title, HeroImage } from '../marketing/Components';
import categories from './categories'

export default function HelpHome(props) {
  return (
    <Box mt={{
      xs: 0,
      md: 6
    }}>
      <Grid container spacing={{
        xs: 3,
        md: 3
      }}>
        {
          _.map(categories, section => (
            <Grid key={section.title} item xs={12} md={4}>
              <Paper sx={{
                p: 3,
                display: "flex",
                height: {
                  xs: "initial",
                  sm: 200
                },
              }} variant="string">
                <Stack sx={{height: "100%"}}>
                  <Box flexGrow={1}>
                    <Typography variant="body2" fontWeight={600}>
                      {section.title}
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                      {section.subtitle}
                    </Typography>
                  </Box>

                  <Box mt={1}>
                    <Typography variant="body3" fontWeight={600}>
                      <Link color="primary" underline="hover" component={RouterLink} to={section.path}>
                        View all articles
                      </Link>
                    </Typography>
                  </Box>
                </Stack>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
    </Box>
  );
}
