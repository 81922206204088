import moment from 'moment-timezone';

export const safeMoment = date => {
  // Makes moment work for Safari which expects a format
  // other than what Rails app is serving
  return moment(
    date,
    "YYYY-MM-DD HH:mm:ss A"
  )
}

export const tzAwareDateTime = (utcDateTime, format='MM/DD/YY hh:mm A') => {
  // Parse the UTC datetime string
  const dateTime = moment.utc(utcDateTime, "YYYY-MM-DD HH:mm:ss A").tz('UTC');

  // Convert to local timezone or a specific timezone
  // E.g., 'America/New_York' or use moment.tz.guess() for the user's timezone
  const userTimeZone = moment.tz.guess();
  const localDateTime = dateTime.tz(userTimeZone);

  // Format the datetime in the desired format
  return localDateTime.format(format);
};