import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddIcon from '@mui/icons-material/Add';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import Loader from '../../components/Loader';
import ClickablePaper from '../../components/ClickablePaper';

import withRouter from '../../utils/withRouter';
import * as time from '../../utils/time';
import request from '../../utils/request';
import currencyFormatter from '../../utils/currencyFormatter';
import * as userGroupActions from '../../actions/userGroups';
import * as offersActions from '../../actions/offers';
import * as alertActions from '../../actions/alerts';

import Card from './Card';
import Details from './Details';

const OffersListContainer = props => {
  const {
    user,
    offers,
    fetchOffers,
    userGroups,
    fetchUserGroups,
    history
  } = props;

  const [offerId, setOfferId] = React.useState(null);

  const [loading, setLoading] = React.useState(false);
  const [showGroupTabs, setShowGroupTabs] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [feedItems, setFeedItems] = React.useState({});
  const [friendOffers, setFriendOffers] = React.useState([]);

  React.useEffect(() => {
    if (userGroups.length == 0) fetchUserGroups()

    if (offers.length == 0) {
      fetchOffers().finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, []);

  React.useEffect(() => {
    if (offers.length > 0) fetchFeedItems(
      _.map(offers, o => o.feed_item_id)
    )
  }, [offers.length]);

  React.useEffect(() => {
    if (userGroups.length > 0) {
      fetchGroupOffers(userGroups).finally(() => setShowGroupTabs(true))
    } else {
      setShowGroupTabs(true)
    }
  }, [userGroups.length]);

  const fetchFeedItems = async id => {
    const { data } = await request.get("feed_items", {
      params: {
        id,
        limit: 50,
        offset: 0,
      }
    })

    if (data.length > 0) {
      setFeedItems({
        ..._.reduce(data, (acc, fi) => {
          acc[fi.id] = fi;
          return acc
        }, {}),
        ...feedItems,
      })
    }
  }

  const fetchGroupOffers = async (userGroups) => {
    const user_id = _.chain(userGroups)
      .map(g => _.map(g.users, u => u.id))
      .flatten()
      .reject(id => id == user.id)
      .uniq()
      .value()

    const { data } = await request.get("offers", {
      params: {
        user_id,
        limit: 50,
        offset: 0,
      }
    })

    if (data.length > 0) {
      const offers = _.map(data, d => {
        return {
          ...d,
          ...d.attributes
        }
      })

      setFriendOffers(offers)

      // fetchFeedItems(_.map(offers, o => o.feed_item_id))
    }
  }

  const tabs = showGroupTabs ? [
    "yours",
    "all groups",
    ..._.map(userGroups, g => `${g.emoji} ${g.name}`)
  ] : ["yours"]

  let relevantOffers;

  switch(tab) {
    case 0:
      relevantOffers = _.filter(offers, o => o.user_id == user.id)
      break;
    case 1:
      relevantOffers = friendOffers;
      break;
    default:
      const group = userGroups[tab];
      const user_id = _.chain(userGroups[tab - 2])
        .get(["users"], [])
        .map(u => u.id)
        .reject(u => u == user.id)
        .value()

      relevantOffers = _.filter(
        friendOffers,
        o => _.includes(user_id, o.user_id)
      )
  }

  return (
    <Box pb={10} pt={2}>

      <Box>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" component={RouterLink} to="/app/account/groups/new">
            Add group
          </Button>
        </Stack>
      </Box>

      <Box mt={3} mb={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant="scrollable" value={tab} onChange={(e, tab) => {
          setTab(tab)
        }}>
          {
            _.map(tabs, t => {
              return (
                <Tab key={t} sx={{
                  textTransform: "inherit",
                  fontWeight: 600,
                  fontSize: 14
                }} label={
                  <Typography variant="body3" fontWeight={600} textTransform="uppercase">
                    {
                      t
                    }
                  </Typography>
                  }  />
              )
            })
          }
        </Tabs>
      </Box>

      {
        loading ?
          <Loader /> :
          <Stack spacing={1.5}>
            {
                _.map(relevantOffers, (offer, i) => (

                <Box key={i} onClick={() => {
                  if (tab == 0) history.push(`./${offer.id}`)
                }}>
                  <Card
                    {...props}
                    offer={offer}
                    {...offer}
                    {...feedItems[offer.feed_item_id]}
                    user={user} />
                </Box>
                ))
            }
          </Stack>
      }

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user", "offers", "userGroups"]);
export default connect(select, {
  ...offersActions,
  ...userGroupActions,
  ...alertActions
})(withRouter(OffersListContainer));
