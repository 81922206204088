import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

import logo from '../assets/images/plaid.svg';

export default function Logo({path, height}) {
  return (
    <Link component={RouterLink} to={path || "/"} underline="none">
      <img
        style={{height: height || 80}}
        src={logo} />
    </Link>
  );
}