import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function CarsdWallet(props) {
  const navigate = useNavigate();
  const onClose = () => navigate("../", {replace: true})

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body1" fontWeight={600}>
            Why is my personal card restricted?
          </Typography>

          <Typography variant="body1" color="text.secondary">
            When you shop with your personal credit card,
            you only earn Picks at Gravy featured merchants.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{p: 3}}>
        <Button onClick={onClose}>
          Close
        </Button>
      </DialogActions>

    </Dialog>
  );
}
