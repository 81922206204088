import _ from "lodash";

import actionTypes from "../constants/index";

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.surveys.INDEX:
      return payload.surveys
    case actionTypes.surveys.UPDATE:
      return _.map(
        $$state,
        s => s.id == payload.id ?
          {
            ...s,
            ...payload
          } :
          s
      )
    case actionTypes.surveys.DELETE:
      return _.reject($$state, s => s.id == payload.id)
    default:
      return $$state;
  }
};
