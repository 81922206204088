import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import FullScreenDialog from '../../components/FullScreenDialog';

import UberEatsLogo from '../../icons/UberEatsLogo';
import RoLogo from '../../icons/RoLogo';
import SeatgeekLogo from '../../icons/SeatgeekLogo';
import DoorDashLogo from '../../icons/DoorDashLogo';

import Preview from '../offers/Preview';

export default function MarketingSampleOffers({title, subtitle="Big savings when your teams win big", justifyContent}) {
  const [sampleOffersModalOpen, setSampleOfferModalOpen] = React.useState(false);

  const toggleSampleOfferModalOpen = () => setSampleOfferModalOpen(!sampleOffersModalOpen);

  const onClose = toggleSampleOfferModalOpen;

  const offers = [{
    icon: <UberEatsLogo />,
    title: "100% refund on your next delivery order",
    proposition: "If your NFL team scores 42+ during week 1"
  }, {
    icon: <SeatgeekLogo />,
    title: "100% refund on tickets",
    proposition: "If your MLB wins hits a grandslam"
  }, {
    icon: <RoLogo />,
    title: "100% refund on your next order",
    proposition: "If a NBA player scores 50+ for your team"
  }]

  return (
    <Box>
      {
        !!title ?
          <Link color="primary" fontWeight={600} variant="body2" href="#"
            onClick={e => {
              e.preventDefault();
              toggleSampleOfferModalOpen();
          }}>
            {title}
          </Link> :
          <Stack direction="row" alignItems="center" justifyContent={{
            xs: justifyContent || "initial",
            md: justifyContent || "center"
          }} spacing={2}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <UberEatsLogo height={30} />
              <SeatgeekLogo height={30} />
              <RoLogo height={30} />
            </Stack>

            <Box>
              {
                !!subtitle &&
                <Typography color="text.secondary" variant="body4" fontWeight={600}>
                  {
                    subtitle
                  }
                </Typography>
              }

              <Link sx={{mt: -0.5}} color="primary" variant="body4" fontWeight={700} href="#" onClick={e => {
                e.preventDefault();
                toggleSampleOfferModalOpen();
              }}>
                Preview offers on Gravy
              </Link>
            </Box>
          </Stack>
      }

      <Preview
        open={sampleOffersModalOpen}
        onClose={toggleSampleOfferModalOpen} />
    </Box>
  );
}
