import _ from "lodash";
import * as React from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import request from '../utils/request';
import TextField from './TextField';

const validationSchema = yup.object({
  type: yup
    .string()
    .required(),
  message: yup
    .string()
});

export default function PlaidErrorReporting(props) {
  const [open, setOpen] = React.useState(false);
  const toggleOpen = () => setOpen(!open);

  const errors = [{
    label: "My card is not supported by Plaid",
    value: "unsupported",
  }, {
    label: "Plaid is erroring",
    value: "link",
  }, {
    label: "Other",
    value: "other"
  }]

  const formik = useFormik({
    initialValues: {
      type: "",
      message: ""
    },
    onSubmit: async (values) => {
      try {
        const { data } = await request.post("plaid/access_token_errors", values)
        if (data.errors) {
          alert(data.errors.message)
        } else {
          alert("We've been notified!")
          toggleOpen()
        }
      } catch(err) {
        alert(err.message)
      }
    },
  });

  return (
    <Box>
      <Button fullWidth variant="outlined" color="error" onClick={toggleOpen}>
        Report Issue
      </Button>

      <Dialog
        open={open}
        onClose={toggleOpen}
        fullWidth
        maxWidth="xs">
        <DialogTitle>
          <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box />

              <IconButton onClick={toggleOpen}>
                <CloseIcon />
              </IconButton>

            </Stack>
          </Box>

          <Box>
            <Typography variant="body1"  fontWeight={600}>
              What is the issue with connecting your account?
             </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit} id="plaid-errors-form">
            <Stack spacing={2} sx={{mt: 1}}>
              <TextField
                select
                label="Pick one"
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                error={formik.touched.type && Boolean(formik.errors.type)}>
                {
                  errors.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                }
              </TextField>

              {
                formik.values.type == "other" &&
                  <TextField
                    fullWidth
                    name="message"
                    multiline
                    rows={3}
                    label="Describe the issue in your own words"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={formik.touched.message && Boolean(formik.errors.message)}
                  />
              }

              {
                formik.values.type == "unsupported" &&
                  <TextField
                    fullWidth
                    name="message"
                    label="Who is the card issuer?"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={formik.touched.message && Boolean(formik.errors.message)}
                  />
              }
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{p: 3}}>
          <Button variant="contained" type="submit" form="plaid-errors-form">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
