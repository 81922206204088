import _ from "lodash";
import * as React from "react";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Link from '../../../components/Link';

export default function RefundHow(props) {
  return (
    <Paper variant="outlined" sx={{p: 3}}>
      <Typography variant="body3" color="text.secondary">
        You will receive a link to claim your funds either directly
        to you bank account, to a Visa card, or as a gift card to a major brand (Amazon, Uber, etc.)
      </Typography>
    </Paper>
  );
}
