const card = {
  _arrayBufferToBase64: function(e) {
    let t = "", r = new Uint8Array(e), n = r.byteLength;
    for (let e = 0; e < n; e++) t += String.fromCharCode(r[e]);
    return window.btoa(t);
  },
  _hexToBase64: function(e) {
    let t = [];
    for (let r = 0; r < e.length; r += 2) t.push(parseInt(e.substr(r, 2), 16));
    let r = new Uint8Array(t), n = "";
    return r.forEach(e => n += String.fromCharCode(e)), window.btoa(n);
  },
  _stringToArrayBuffer: function(e) {
    let t = new ArrayBuffer(e.length), r = new Uint8Array(t);
    for (let t = 0, n = e.length; t < n; t++) r[t] = e.charCodeAt(t);
    return t;
  },
  _generateSecret: function() {
    return window.crypto.randomUUID().replace(/-/g, "");
  },
  _importRsaKey: async function(e) {
    let t = e.substring(26, e.length - 24 - 1), r = window.atob(t),
        n = this._stringToArrayBuffer(r);
    return window.crypto.subtle.importKey("spki", n, { name: "RSA-OAEP", hash: "SHA-1" }, !0, ["encrypt"]);
  },
  generateSessionId: async function(e, t) {
    if (!e) throw Error("pem is required");
    if (t && !/^[0-9A-Fa-f]+$/.test(t)) throw Error("secret must be a hex string");
    let r = t ?? this._generateSecret(), n = this._hexToBase64(r), o = await this._importRsaKey(e),
        i = await window.crypto.subtle.encrypt({ name: "RSA-OAEP" }, o, new TextEncoder().encode(n));
    return { secretKey: r, sessionId: this._arrayBufferToBase64(i) };
  },
  decryptSecret: async function(e, t, r) {
    if (!e) throw Error("base64Secret is required");
    if (!t) throw Error("base64Iv is required");
    if (!r || !/^[0-9A-Fa-f]+$/.test(r)) throw Error("secretKey must be a hex string");
    let n = Uint8Array.from(window.atob(e), e => e.charCodeAt(0)),
        o = Uint8Array.from(window.atob(t), e => e.charCodeAt(0)),
        i = Uint8Array.from(r.match(/.{1,2}/g).map(e => parseInt(e, 16))),
        a = await window.crypto.subtle.importKey("raw", i, { name: "AES-GCM" }, !1, ["decrypt"]),
        l = await window.crypto.subtle.decrypt({ name: "AES-GCM", iv: o }, a, n);
    return new TextDecoder().decode(l);
  },
  // verify: async function(t) {
  //   if (!t) throw Error("args is required");
  //   let { userId: r, production: n = !1, host: o } = t;
  //   if (!r) throw Error("userId is required");
  //   let i = o ?? `https://api${n ? "" : "-dev"}.raincards.xyz`,
  //       a = await fetch(`${i}/applications/alloy-sdk-config?userId=${r}`, { method: "GET", headers: { "Content-Type": "application/json" } }),
  //       l = await a.json();
  //   await e.init({ ...l, production: n, isNext: !0, showHeader: !1 });
  //   e.open(e => e);
  // }
};

export default card;
