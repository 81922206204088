import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useOutletContext, useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { connect } from 'react-redux';
import { Navigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Loader from '../../components/Loader';
import Link from '../../components/Link';
import Alert from '../../components/Alert';
import GravyVideo from '../../components/GravyVideo';
import AuthButton from '../user/AuthButton';
import withRouter from '../../utils/withRouter';
import * as environment from '../../environment';
import * as time from '../../utils/time';
import request from '../../utils/request';
import currencyFormatter from '../../utils/currencyFormatter';

import SampleOffers from '../marketing/SampleOffers';

export default function PlayersDetails(props) {
  const {
    user,
    player,
    offers,
    saveFavorites
  } = useOutletContext();

  const navigate = useNavigate();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [playHow, setPlayHow] = React.useState(false);

  const onboard = async () => {
    try {
      await saveFavorite()
    } catch(err) {
    }

    navigate("/onboarding")
  }

  const saveFavorite = async () => {
    const results = await saveFavorites(
      user,
      [{
        favoritable_id: player.id,
        favoritable_type: "Player"
      }]
    )

    if (_.get(user, ["favorites", "length"], 0) > 1) {
      navigate(`/app`)
    } else {
      navigate(`/app/onboarding`)
    }
  }

  return (
    <Box minHeight={"100vh"} pb={12} sx={{background: "#f8f7fa"}}>
      <Box pt={{
        xs: 3,
        md: 4
      }} pb={{
        xs: 3,
        md: 3
      }} sx={{background: "#fff"}}>
        <Container maxWidth="sm">
          <Box>
            <img style={{
              height: mobile ? 100 : 120,
              borderRadius: 20
            }} src={player.avatar.url} />
          </Box>

          <Box mt={1}>
            <Typography variant="body2" fontWeight={600}>
              If {player.first_name} cooks, you won't have to...
            </Typography>
            <Typography variant={mobile ? "body3" : "body2"} color="text.secondary" fontWeight={500}>
              Save big on everyday bills when your favorite players (like {player.name}) and teams win big.
            </Typography>
          </Box>

          <Box mt={2}>
            <Stack spacing={3}>
              <Button variant="contained" fullWidth size="large" onClick={onboard}>
                Start Saving
              </Button>
              <SampleOffers justifyContent="center" subtitle="Major brands, major savings" />
            </Stack>
          </Box>

        </Container>
      </Box>

      <Box mt={{
        xs: 2,
        md: 4
      }}>
        <Container maxWidth="sm">
          <Grid container spacing={{xs: 2, sm: 2}}>
            <Grid item xs={12} md={12}>
              <Stack spacing={2}>

                <GravyVideo play={playHow} />
              </Stack>
            </Grid>

          </Grid>
        </Container>
      </Box>

      <AppBar position="fixed" sx={{
        top: 'auto',
        bottom: 0,
        borderTop: 1,
        borderColor: 'divider',
        bgcolor: "#fff",
        boxShadow: "none",
        color: "inherit",
      }}>
        <Box p={1}>
          <Stack direction="row" alignItems="center" justifyContent={
            mobile ? "start" : "center"
          }>
            <img src="https://www.logo.wine/a/logo/Plaid_(company)/Plaid_(company)-Vertical-Logo.wine.svg"
              style={{
                height: 40,
                position: "relative",
                top: 3
              }} />
            <Link variant="body3" fontWeight={600} color="primary" component={RouterLink} to="/about" target="_blank">
              Built by the team behind Plaid ($13.5B)
            </Link>
          </Stack>
        </Box>
      </AppBar>

    </Box>
  )
}
