import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export default function DialogBackButton({onClick}) {
  return (
    <IconButton onClick={onClick} sx={{p: 0}}>
      <KeyboardBackspaceIcon />
    </IconButton>
  );
}