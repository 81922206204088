import * as React from "react";
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';

const StyledLink = styled((props) => {
  return <Link underline={props.underline || "hover"} fontWeight={500} {...props} />
})(({ theme, selected, color }) => ({
  color: color ?
    color :
    selected ? theme.palette.secondary.main : theme.palette.primary.main,
  "&:hover": {
    color: color || theme.palette.secondary.main,
  }
}));

export default StyledLink;