import _ from "lodash";
import * as React from "react";
import { Outlet, Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import categories from './categories'
import * as pages from './pages/index';

export default function HelpDetails(props) {
  const params = useParams();
  const category = _.find(categories, c => c.path == params.category)
  const page = _.find(category.pages, p => p.path == params.page)
  const Component = pages[page.component];

  const breadcrumbs = [
    <Link
      underline="hover"
      component={RouterLink}
      color="primary"
      to="/faq">
      <Typography variant="body2" key="3" fontWeight={600}>
        Help Articles
      </Typography>
    </Link>,
    <Link
      underline="hover"
      component={RouterLink}
      color="primary"
      to="../">
      <Typography variant="body2" key="3" fontWeight={600}>
        {category.title}
      </Typography>
    </Link>
  ];

  return (
    <Box>
      <Container maxWidth="md" sx={{m: 0}}>
        <Box  mb={3}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
        </Box>


        <Typography variant="body1" fontWeight={600} gutterBottom>
          {page.title}
        </Typography>

        <Box mt={2}>
          {
            Component != null && <Component />
          }
        </Box>
      </Container>
    </Box>
  );
}
