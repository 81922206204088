import _ from 'lodash';
import Promise from 'bluebird';
import { connect } from 'react-redux';
import * as React from 'react';
import { Link as RouterLink, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Loader from '../../components/Loader';
import Link from '../../components/Link';

import withRouter from '../../utils/withRouter';
import * as integrationActions from '../../actions/integrations';

import Leagues from './Leagues';
import Teams from './Teams';

import Sleeper from '../integrations/Sleeper';

function AccountFantasy(props) {
  const {
    integrations,
    fetchIntegrations,
    createIntegration,
    updateIntegration
  } = props;

  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => setOpen(!open);

  const breadcrumbs = [
    <Link
      color="primary"
      underline="hover"
      component={RouterLink}
      to="../">
      <Typography variant="body3" fontWeight={600}>
        Account
      </Typography>
    </Link>,
    <Typography variant="body3" color="text.secondary" fontWeight={600}>
      Fantasy
    </Typography>,
  ];

  React.useEffect(() => {
    Promise.all([
      integrations.length == 0 ? fetchIntegrations() : Promise.resolve()
    ]).finally(() => setLoading(false))
  }, []);

  const integration = _.find(
    integrations,
    i => i.type == "Integration::Sleeper"
  ) || {}

  return (
   <Container maxWidth="sm">
      <Box mb={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
      </Box>

      <Box>
        {
          loading ?
            <Loader /> :
            <Box variant="outlined">
              <Typography variant="body2" fontWeight={600}>
                Do you play fantasy football?
              </Typography>
              <Typography variant="body3">
                Connect your account to save based on your roster...
              </Typography>

              <Box mt={2}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Paper variant="outlined" sx={{p: 3}}>
                      <Stack spacing={2} alignItems="center" justifyContent="center">
                        <Stack spacing={1} alignItems="center">
                          <img src="https://sleepercdn.com/landing/web2021/img/sleeper-app-logo-2.png"
                            style={{
                              height: "50px"
                            }} />

                          <Typography variant="body3" fontWeight={600}>
                            Sleeper
                          </Typography>
                        </Stack>


                        <Button variant="contained" size="small" onClick={() => {
                          toggleOpen()
                        }}>
                          {
                            !!integration.access_token ?
                              "Connected" :
                              "Connect"
                          }
                        </Button>
                      </Stack>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </Box>
        }
      </Box>

      <Sleeper
        {...props}
        integration={integration}
        open={open}
        onClose={toggleOpen} />
    </Container>
  )
}

const select = $$state => _.pick($$state, "user", "integrations");
export default connect(select, {
  ...integrationActions,
})(withRouter(AccountFantasy));
