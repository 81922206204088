export default (props) => {
  return (
    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Seatgeek_logo.svg/2560px-Seatgeek_logo.svg.png"
      style={{
        height: 40,
        borderRadius: 5,
        ...props
      }} />
  )
}
