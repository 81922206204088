import _ from "lodash";
import * as React from "react";
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';

import request from '../../utils/request';
import AuthButton from '../user/AuthButton';
import PlaidBuilt from '../../components/PlaidBuilt';

export default function RewardsExplanation(props) {
  const {
    open,
    onClose,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box mt={2}>
          <Typography variant="h2" fontWeight={600}>
            This is only a demo
          </Typography>
          <Typography variant="h3" fontWeight={400}>
            You can not redeem this demo Gift
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
      </DialogContent>
      <DialogActions sx={{
        p: 3,
      }}>
        <LoadingButton
          onClick={onClose}
          size="large">
          Close
        </LoadingButton>
      </DialogActions>
    </Dialog>

  )
}
