import _ from 'lodash';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function DialogResponsive(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={fullScreen}
      {..._.omit(props, ["children"])}>
     {props.children}
    </Dialog>
  );
}