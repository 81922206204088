import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function How({open, onClose}) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>
        <Box mt={2}>
          <Typography variant="h3" fontWeight={600}>
            How this work at checkout?
          </Typography>

          <Typography variant="body1" fontWeight={400}>
            With most major apps, you can check out with your cash balance (Uber example below).
          </Typography>
        </Box>

      </DialogTitle>
      <DialogContent sx={{
        maxHeight: 300
      }}>
        <img
          style={{
            width: "100%"
          }}
          src="https://p70.p4.n0.cdn.zight.com/items/YEub0Yqo/346de590-aec0-4416-b946-40705f18d29b.png?source=viewer&v=16282c3dfa7a58cf4bb1ee50fcf9a938" />
      </DialogContent>
    </Dialog>
  )
}

export default function PourInstructiounsOverview(props) {
  const {
    merchant,
  } = props;

  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => setOpen(!open);

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h4" fontWeight={600} lineHeight={1.3}>
          Adding funds to is EASY
        </Typography>
        <Typography variant="body1" lineHeight={1.3}>
          Gravy charges your credit card
          and instantly adds cash to your balance in the {merchant.name} app.
          There are <b>ZERO extra fees</b>.
        </Typography>
      </Box>

      <How open={open} onClose={toggleOpen} />
    </Box>
  );
}
