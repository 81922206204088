import { combineReducers } from "redux";

import alerts from './alerts';
import cards from './cards';
import integrations from './integrations';
import merchantOffers from './merchantOffers';
import notifications from './notifications';
import offers from './offers';
import referrals from './referrals';
import regions from './regions';
import sports from './sports';
import feedItems from './feedItems';
import leagues from './leagues';
import leaderboards from './leaderboards';
import teams from './teams';
import paymentMethods from './paymentMethods';
import payouts from './payouts';
import picks from './picks';
import players from './players';
import points from './points';
import propositions from './propositions';
import surveys from './surveys';
import transactions from './transactions';
import user from './user';
import userGroups from './userGroups';

export default combineReducers({
  alerts,
  cards,
  integrations,
  leaderboards,
  merchantOffers,
  notifications,
  offers,
  referrals,
  regions,
  sports,
  feedItems,
  leagues,
  paymentMethods,
  payouts,
  picks,
  players,
  points,
  propositions,
  surveys,
  teams,
  transactions,
  user,
  userGroups
});
