import _ from 'lodash';
import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';

import TextField from './TextField';

export default function AutocompleteTeams(props) {
  const { teams, value, onChange, label } = props;

  return (
    <Autocomplete
      fullWidth
      disablePortal
      onChange={onChange}
      value={_.find(teams, t => t.id == value)}
      options={
        _.map(teams, team => {
          return {
            id: team.id,
            label: team.name
          }
        })
      }
      renderInput={(params) => <TextField {...params} label={label || "Choose a team"} />}
    />
  );
}
