import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import Loader from '../../components/Loader';

export default function InstructionsShop(props) {
  const {
    user,
    merchantOffers,
    card,
    next,
  } = props;

  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => setOpen(!open);

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h4" fontWeight={700} lineHeight={1.3}>
          Ready to Pour (aka Spend) Gravy?
        </Typography>
        <Typography variant="body1" lineHeight={1.3}>
          Use your Gravy card and you'll
          receive a text with instructions
          to win up to 100% cash back.
        </Typography>
      </Box>
    </Box>
  );
}
