const url = window.location.href;

const isDevelopment = url.includes('localhost') || url.includes('127.0.0.1');

export const production = !isDevelopment;

export const debugModeEnabled = () => isDevelopment;

export const httpsHost = production ?
  "https://itsgravy.co" :
  "https://localhost:3000";

export const APIHost = production ?
  "https://tailriskai-backend-5d7693bf1193.herokuapp.com" :
  "http://localhost:5000";

export const VercelHost = production ?
  "https://gravy-nextjs.vercel.app" :
  "http://localhost:3000";

export const stripeApiKey = isDevelopment ?
  "pk_live_51OK7SxLcHb7erG9PG9tmwVns8FGfcG2ciC0xWvKgTamTBtSKc9absihv83PKUuadPaWVZqASWYXW8FbhNs4KyQhA00ZRfsqaDW" :
  "pk_live_51OK7SxLcHb7erG9PG9tmwVns8FGfcG2ciC0xWvKgTamTBtSKc9absihv83PKUuadPaWVZqASWYXW8FbhNs4KyQhA00ZRfsqaDW"
