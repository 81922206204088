import _ from "lodash";

import actionTypes from "../constants/index";

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.notifications.INDEX:
      return payload.notifications;
    case actionTypes.notifications.DELETE:
      return _.reject($$state, d => d.id == payload.notification.id)
    default:
      return $$state;
  }
};
