import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

export const fetchPoints = () => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`points`)

    if (!data.errors) {
      dispatch({
        type: actionTypes.points.SHOW,
        payload: data
      });
    }

    return data;
  }
};


export const deletePoints = points => {
  return {
    type: actionTypes.points.DELETE,
    payload: {
      points
    },
  };
};