import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { useOutletContext, Link as RouterLink, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Loader from '../../components/Loader';
import Link from '../../components/Link';
import withRouter from '../../utils/withRouter';
import * as notificationActions from '../../actions/notifications';

import Details from './Details';

function NotificationsList(props) {
  const { notifications, deleteNotification, history} = props;

  return (
    <Box pt={{
      xs: 4,
      sm: 4
    }} pb={10}>
      <Container maxWidth="md">
        <Box mt={2} mb={2}>
          <Typography variant="h4" fontWeight={400}>
            Notifications
          </Typography>
        </Box>

        <Stack spacing={1}>
          {
            _.chain(notifications)
              .sortBy("created_at")
              .reverse()
              .map(notification => (
                <Details
                  key={notification.id}
                  notification={notification}
                  deleteNotification={deleteNotification} />
              ))
              .value()
          }
        </Stack>
      </Container>

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "notifications");
export default connect(select, {
  ...notificationActions
})(withRouter(NotificationsList));
