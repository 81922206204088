import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

import Loader from '../../components/Loader';
import * as feedItemsActions from '../../actions/feedItems';
import * as teamsActions from '../../actions/teams';
import * as offersActions from '../../actions/offers';
import * as propositionActions from '../../actions/propositions';
import * as picksActions from '../../actions/picks';
import withRouter from '../../utils/withRouter';

function FeedItemsMain(props) {
  const {
    user,
    feedItems,
    fetchFeedItems,
    offers,
    fetchOffers,
    picks,
    fetchPicks,
    propositions,
    fetchPropositions,
    teams,
    fetchTeams,
    history
  } = props;

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let pollingPicksId;
    let pollingFeedItemsId;

    if (teams.length == 0) fetchTeams()

    fetchData().finally(() => {
      setLoading(false)

      pollingFeedItemsId = pollFeedItems();
    })
    // Cleanup function to clear the interval on component unmount
    return () => {
      clearInterval(pollingFeedItemsId)
    };
  }, []);

  let polledTimes = 0;

  const pollFeedItems = () => {
    const pollingId = setInterval(() => {
      fetchFeedItems(user.id)
    }, 1000 * 60 * 60);

    return pollingId;
  };

  const fetchData = async () => {
    fetchOffers()

    return await Promise.all([
      fetchFeedItems(user.id),
    ])
  };

  return (
    <Box>
      <Container maxWidth="sm">
        {
          feedItems.length == 0 ?
            <Loader /> :
            <Outlet />
        }
      </Container>
    </Box>
  );
}


const select = $$state => _.pick($$state, "user", "feedItems", "propositions", "offers", "teams");
export default connect(select, {
  ...feedItemsActions,
  ...picksActions,
  ...propositionActions,
  ...offersActions,
  ...teamsActions,
})(withRouter(FeedItemsMain));
