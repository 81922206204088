import React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {Provider} from "react-redux";

import './index.css';
import theme from './theme';
import createStore from './store';

import Routing from './containers/Routing';

export default function App(props) {
    const store = createStore(props);

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Routing/>
            </ThemeProvider>
        </Provider>
    );
}
