import _ from "lodash";
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as React from "react";
import { connect } from 'react-redux';
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Soup, Zap, ChartColumnIncreasing } from 'lucide-react'

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import TextField from '../../components/TextField';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import FullScreenDialog from '../../components/FullScreenDialog';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';
import request from '../../utils/request';
import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import * as merchantOffersActions from '../../actions/merchantOffers';
import * as payoutActions from '../../actions/payouts';
import * as alertActions from '../../actions/alerts';
import * as integrationActions from '../../actions/integrations';
import { fShortenNumber } from '../../utils/format-number';

import OrdersNew from '../orders/New';

function GiftForm(props) {
  const { user } = props;

  const validationSchema = yup.object({
    user_name: yup
      .string(),
    name: yup
      .string()
      .required(),
    email: yup
      .string(),
    phone: yup
      .string()
      .required(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      user_name: user.name
    },
    validationSchema,
    onSubmit: props.onSubmit
  });

  return (
    <form onSubmit={formik.handleSubmit} id="recipient-form">
      <Stack spacing={1}>
        {
          !!user.name ||
            <TextField
              fullWidth
              name="user_name"
              label="Your name"
              value={formik.values.user_name}
              onChange={formik.handleChange}
              error={formik.touched.user_name && Boolean(formik.errors.user_name)}
              helperText={formik.touched.user_name && formik.errors.user_name} />
        }

        <TextField
          fullWidth
          name="name"
          label="Recipient Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name} />

        <TextField
          fullWidth
          name="phone"
          label="Recipient Phone #"
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone} />

        <TextField
          fullWidth
          name="email"
          label="Recipient Email (Optional)"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email} />

      </Stack>
    </form>
  )
}


function PurchaseForm(props) {
  const formik = useFormik({
    initialValues: {
    },
    onSubmit: props.onSubmit
  });

  return (
    <form onSubmit={formik.handleSubmit} id="recipient-form">
    </form>
  )
}

function Recipient(props) {
  const { user, recipient, onSubmit } = props;

  const [gift, setGift] = React.useState(true);

  return (
    <Box>
       <Typography variant="h4" fontWeight={400} lineHeight={1.2}>
        Is this a gift?
      </Typography>

      <Box mt={2} mb={2}>
        <Alert color="primary">
          <Typography variant="body2" fontWeight={600}>
            You earn one Pour as the buyer
            and whoever receives the card earns one Pour per swipe.
          </Typography>
        </Alert>
      </Box>

      <Box mb={2}>
        <ButtonGroup sx={{mt: 1}} fullWidth size="large">
          <Button fullWidth variant={gift ? "contained" : "outlined"} onClick={() => setGift(true)}>
            Gift
          </Button>

          <Button fullWidth variant={!gift ? "contained" : "outlined"} onClick={() => setGift(false)}>
            For ME
          </Button>
        </ButtonGroup>
      </Box>

      {

          gift ?
            <GiftForm
              user={user}
              onSubmit={onSubmit} /> :
            <PurchaseForm onSubmit={onSubmit} />
      }
    </Box>
  )
}

function Amount(props) {
  const { user, amount, setAmount, merchant } = props;

  const amounts = [25, 50, 75, 100];

  return (
    <Box>
      <Typography variant="h4" fontWeight={400} lineHeight={1.2}>
        Up to 100% back per swipe when cool things happen in the sports and culture.
      </Typography>

      <Box mt={2} mb={2}>
        <Alert color="primary">
          <Typography variant="body2" fontWeight={600}>
            How much card value would you like?
          </Typography>
        </Alert>
      </Box>

      <Box mb={2}>
        <ButtonGroup fullWidth size="large">
          {
            _.chain(amounts)
              .slice(0, 4)
              .map(a =>
                <Button key={a} fullWidth variant={a == amount ? "contained" : "outlined"} onClick={() => setAmount(a)}>
                  ${a}
                </Button>
              ).value()
          }


        </ButtonGroup>
      </Box>
    </Box>
  )
}

function MerchantDetails(props) {
  const {
    user,
    merchantOffers,
    createAlert,
    createPayout,
    params,
    history
  } = props;

  const onClose = () => {
    setStep(0)
    history.replace("../");
  }

  const [step, setStep] = React.useState(0);
  const [amount, setAmount] = React.useState(50);
  const [recipient, setRecipient] = React.useState({})

  const merchantOffer = _.find(merchantOffers, mo => mo.id == params.merchantOfferId);
  const merchant = merchantOffer?.merchant;

  return (
    <FullScreenDialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h2" fontWeight={600}>
          Buy {
            merchant?.name || "Gravy"
          } Card
        </Typography>

        {
          step == 0 &&
            <Amount
              {...props}
              merchant={merchant}
              amount={amount}
              setAmount={setAmount} />
        }

        {
          step == 1 &&
            <Recipient
              {...props}
              recipient={recipient}
              merchant={merchant}
              onSubmit={recipient => {
                setRecipient(recipient)
                setStep(2)
              }} />
        }

      <OrdersNew
        {...props}
        recipient={recipient}
        amount={amount}
        open={step == 2}
        onClose={() => setStep(1)}
        merchant={merchant} />

      </DialogContent>
      <DialogActions sx={{
        p: 3,
      }}>
        {
          step > 0 &&
            <Button onClick={() => {
              setStep(step - 1)
            }}>
              Back
            </Button>
        }

        {
          step == 0 &&
            <Button variant="contained" onClick={() => {
              setStep(step + 1)
            }}>
              Next
            </Button>
        }

        {
          step == 1 &&
            <Button variant="contained" type="submit" form="recipient-form">
              Next
            </Button>
        }

      </DialogActions>

    </FullScreenDialog>

  )
}


const select = $$state => _.pick($$state, ["user", "merchantOffers", "integrations", "points"]);
export default connect(select, {
  ...alertActions,
  ...integrationActions,
  ...alertActions,
  ...merchantOffersActions,
  ...payoutActions,
})(withRouter(MerchantDetails));
