import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function DialogCloseButton({onClose}) {
  return (
    <IconButton onClick={onClose} sx={{p: 0}}>
      <CloseIcon />
    </IconButton>
  );
}