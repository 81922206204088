import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import withRouter from '../../utils/withRouter';
import * as leaguesActions from '../../actions/leagues';
import * as alertActions from '../../actions/alerts';
import * as userActions from '../../actions/user';

import Link from '../../components/Link';

function AccountLeagues(props) {
  const {
    leagues,
    fetchLeagues,
    saveFavorites,
    createAlert,
    user,
    history
  } = props;

  const onClose = () => history.replace("../");

  const [loading, setLoading] = React.useState(true);

  const relevantLeagues = _.reject(leagues, l => _.includes(["mls", "wnba"], _.lowerCase(l.abbrv)))
  const favoriteLeagues = _.chain(user)
    .get(["favorites"], [])
    .filter(f => f.favoritable_type == "League")
    .map(f => f.favoritable_id)
    .value();

  const formik = useFormik({
    initialValues: {
      leagues: favoriteLeagues
    },
    enableReinitialize: true,
    onSubmit: async ({leagues}) => {
      try {
        const results = await saveFavorites(
          user,
          _.map(leagues, id => {
            return {
              favoritable_id: id,
              favoritable_type: "League"
            }
          })
        )

        if (results.errors) {
          alert(results.errors.message)
        } else {
          createAlert({title: "Favorites updated"})
        }
      } catch(err) {
        alert(err.message)
      }
    },
  });


  React.useEffect(() => {
    if (leagues.length == 0) {
      fetchLeagues().finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, []);

  return (
    <Box>
        <Box>
          <form onSubmit={formik.handleSubmit}>
              <List direction="column">
                {
                  _.chain(relevantLeagues)
                    .map((league, i) => {
                      return (
                        <ListItem key={i} disablePadding divider={i < (relevantLeagues.length - 1)}>
                          <ListItemButton onClick={() => {
                            formik.setFieldValue(
                              "leagues",
                              _.xor(formik.values.leagues, [league.id])
                            )
                          }}>
                            <ListItemIcon>
                              <Checkbox
                                color="secondary"
                                disableRipple
                                edge="start"
                                checked={_.includes(formik.values.leagues, league.id)}
                                tabIndex={-1}
                              />
                            </ListItemIcon>

                            <ListItemText>
                              <Typography variant="body2" color="text.primary" fontWeight={500}>
                                {
                                  _.upperCase(league.abbrv)
                                }
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      )
                    })
                    .value()
                  }
                </List>
              <Stack sx={{mt: 2}} alignItems="end">
                <LoadingButton
                  disabled={formik.values.leagues.length == 0}
                  type="submit"
                  loading={formik.isSubmitting}
                  variant="contained">
                  Save
                </LoadingButton>
              </Stack>
          </form>
        </Box>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "leagues");
export default connect(select, {
  ...alertActions,
  ...leaguesActions,
  ...userActions
})(withRouter(AccountLeagues));
