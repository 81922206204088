import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink, useNavigate, useParams, useOutletContext } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import request from '../../utils/request';
import TextField from "../../components/TextField";

const validationSchema = yup.object({
  access_token: yup
    .string()
    .required(),
});

export default function IntegrationsSleeper(props) {
  const { integration, open, createIntegration, updateIntegration, onClose } = props;

  const formik = useFormik({
    initialValues: {
      access_token: _.get(integration, ["access_token"], ""),
      type: "Integration::Sleeper"
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const results = await (
          integration.id ?
          updateIntegration(integration.id, values) :
          createIntegration(values)
        )

        if (results.errors) {
          alert(results.errors.message)
        } else {
          onClose()
        }
      } catch(err) {
        alert(err.message)
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box>
          <Typography variant="body3" fontWeight={600}>
            Your Sleeper username
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>

        <form onSubmit={formik.handleSubmit} id="sleeper-form">
          <TextField
            autoFocus
            fullWidth
            name="access_token"
            value={formik.values.access_token}
            onChange={formik.handleChange}
            error={formik.touched.access_token && Boolean(formik.errors.access_token)}
            InputProps={{
              startAdornment: <InputAdornment position="start">@</InputAdornment>,
            }} />
        </form>

      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <LoadingButton
          loading={formik.isSubmitting}
          variant="contained"
          type="submit"
          form="sleeper-form">
          Save
        </LoadingButton>
      </DialogActions>

    </Dialog>
  );
}
