import _ from 'lodash';
import * as React from 'react';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Logo from '../../components/Logo';
import Link from '../../components/Link';

import SideNavBar from './SideNavBar';
import AuthButton from '../user/AuthButton';
import AccountPopover from '../user/AccountPopover';

import PlaidLogo from '../../icons/PlaidLogo';
import SampleOffers from './SampleOffers';

const getPage = pathname => {
  switch(true) {
    case /about/.test(pathname):
      return "about"
    case /pricing/.test(pathname):
      return "pricing"
    case /shopppers/.test(pathname):
      return "shoppers"
    default:
      return "home";
  }
}

export default function TopNavBar(props) {
  const { location, user, logout } = props;

  const navigate = useNavigate();
  const page = getPage(location.pathname)

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [scrolled, setScrolled] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box>
      <AppBar position={"relative"} sx={{
        background: location.pathname == "/" ? "black" : "#f5f5f5",
        color: "#fff",
        boxShadow: "none",
        pt: 2,
        pb: 2
      }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center"  sx={{
            justifyContent: "space-between",
          }}>

              <Stack direction="row" spacing={{
                xs: 4,
                md: 10
              }} alignItems="center">

                <Logo path="/" />

              </Stack>


              <Stack direction="row" spacing={2} alignItems="center">
                <Link
                  component={RouterLink}
                  to="/about"
                  fontWeight={600}
                  variant="body3"
                  color={
                    location.pathname == "/" ? "#fff" : "black"
                  }>
                  About
                </Link>

                <AuthButton screen={"login"} onAuthentication={() => {
                  navigate("/app")
                }}>
                  <Button
                    sx={{
                      border: location.pathname == "/" ? "1px solid white" : "1px solid black",
                      color: location.pathname == "/" ? "#fff" : "black"
                    }}
                    color="secondary"
                    size="small">
                    Log In
                  </Button>
                </AuthButton>

                <Button component={RouterLink} to="/onboarding" size="small" variant="contained" color="secondary" sx={{
                  color: "#fff"
                }}>
                  Start Saving
                </Button>
              </Stack>

          </Stack>
        </Container>
      </AppBar>
    </Box>
  );
};
