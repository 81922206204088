import request from '../utils/request';

import actionTypes from "../constants/index";
import { VercelHost } from '../environment';

export const fetchFeedItems = (userId) => {
  return async (dispatch, _getState) => {
    const options = {
      baseURL: VercelHost,
      params: {
        userId
      }
    }
    const { data } = await request.get(`get-data/feed-items`, options)

    if (!data.errors) {
      dispatch({
        type: actionTypes.feedItems.INDEX,
        payload: data,
      });
    }

    return data;
  }
};
