import _ from "lodash";
import * as React from 'react';
import { connect } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as RouterLink, Outlet } from "react-router-dom";
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import withRouter from '../../utils/withRouter';
import * as cardActions from '../../actions/cards';

import Card from './Card';

function CardsList(props) {
  const {cards, saveCardWaitlist, history} = props;

  return (
    <Box pt={2} pb={10}>
      <Stack spacing={2}>
        {
          _.chain(cards)
            .map(card => (
              <Card key={card.id} {...props} {...card} />
            ))
            .sortBy(c => c.source)
            .reverse()
            .value()
        }
      </Stack>

      <Outlet context={{
        cards
      }} />
    </Box>
  );
}

const select = $$state => _.pick($$state, "cards");
export default connect(select, {
  ...cardActions,
})(withRouter(CardsList));
