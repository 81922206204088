import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

export const fetchNotifications = () => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`user_notifications`);

    if (!data.errors) {
      dispatch({
        type: actionTypes.notifications.INDEX,
        payload: {
          notifications: _.map(data, c => {
            return {
              id: c.id,
              ...c.attributes
            }
          })
        }
      });
    }

    return data;
  }
};

export const deleteNotification = (id) => {
  return async (dispatch, getState) => {
    const { data } = await request.delete(`user_notifications/${id}`);

    if (!data.errors) {
      dispatch({
        type: actionTypes.notifications.DELETE,
        payload: {
          notification: {
            id
          }
        }
      });
    }

    return data;
  }
};