import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import withRouter from '../../utils/withRouter';
import * as alertActions from '../../actions/alerts';
import * as teamsActions from '../../actions/teams';
import * as userActions from '../../actions/user';

import Link from '../../components/Link';

function AccountTeams(props) {
  const {
    user,
    createAlert,
    saveFavorites,
    leagues,
    teams,
    fetchTeams
  } = props;

  const [loading, setLoading] = React.useState(true);

  const favoriteTeams = _.chain(user)
    .get(["favorites"], [])
    .filter(f => f.favoritable_type == "Team")
    .map(f => f.favoritable_id)
    .value();

  const teamsByLeague = _.reduce(favoriteTeams, (acc, teamId) => {
    // Find the team with the current teamId
    const team = _.find(teams, { id: teamId });
    if (team) {
      // Find the league corresponding to the team's league_id
      const league = _.find(leagues, { id: team.league_id });
      if (league) {
        // Use the league abbreviation as a key in the accumulator object
        const leagueAbbr = league.abbrv;
        // Ensure the key exists and push the teamId into its array
        acc[leagueAbbr] = teamId;
      }
    }
    return acc;
  }, {});

  const formik = useFormik({
    initialValues: !_.isEmpty(teamsByLeague) ? teamsByLeague : {
      nfl: "",
      mlb: "",
      nba: "",
      nhl: "",
    },
    enableReinitialize: true,
    onSubmit: async values => {
      try {
        const ids = _.chain(values)
          .values()
          .reject(id => !id)
          .value();

        const results = await saveFavorites(
          user,
          _.map(ids, id => {
            return {
              favoritable_id: id,
              favoritable_type: "Team"
            }
          })
        )

        if (results.errors) {
          alert(results.errors.message)
        } else {
          createAlert({title: "Favorites updated"})
        }
      } catch(err) {

      }
    },
  });

  React.useEffect(() => {
    if (teams.length == 0) {
      fetchTeams().finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, []);

  const leaguesWithTeam = ["nfl", "mlb", "nba", "nhl"];

  const relevantLeagues = _.chain(leagues)
    .filter(l => _.includes(leaguesWithTeam, _.toLower(l.abbrv)))
    .value()

  return (
    <Box my={2}>

        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              {
                _.map(relevantLeagues, league => {
                  const leagueTeams = _.chain(teams)
                    .filter(t => t.league_id == league.id)
                    .sortBy("name")
                    .value();

                  return (
                    <Box key={league.id}>
                      <Typography
                        variant="body4"
                        fontWeight={600}
                        gutterBottom>
                        {`Favorite ${_.toUpper(league.abbrv)} team`}
                      </Typography>

                      <Select
                        fullWidth
                        name={league.abbrv}
                        value={formik.values[league.abbrv]}
                        onChange={formik.handleChange}>
                          {
                            _.map(leagueTeams, l => {
                              return <MenuItem key={l.id} value={l.id}>
                                <Typography variant="body3">{l.name}</Typography>
                              </MenuItem>
                            })
                          }

                        </Select>
                      </Box>
                    )
                  })
                }
              </Stack>
          <Stack sx={{mt: 2}} alignItems="end">
            <LoadingButton
              type="submit"
              loading={formik.isSubmitting}
              variant="contained">
              Save
            </LoadingButton>
          </Stack>
        </form>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "leagues", "teams");
export default connect(select, {
  ...alertActions,
  ...teamsActions,
  ...userActions
})(withRouter(AccountTeams));
