import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Link from '../../components/Link';

export default function UserGroupMembers(props) {
  const {userGroup} = props;

  return (
    <Box>
      <Stack spacing={2}>
        {
          _.map(userGroup.users, user => {
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar>
                  {
                    _.capitalize(_.first(user.username))
                  }
                </Avatar>
                <Link component={RouterLink} to={`/app/profiles/${user.username}`} variant="body3" fontWeight={600}>
                  {
                    user.username
                  }
                </Link>
              </Stack>
            )
          })
        }
      </Stack>
    </Box>
  );
}
