import _ from "lodash";
import * as React from "react";
import {
  Link as RouterLink,
} from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import Link from "../../components/Link";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";

export default function OfferDetails(props) {
  const {
    prizeStatus
  } = props;

  return (
    <Alert color="primary">
      <Typography variant="body2" fontWeight={600}>
        You've won this offer!
      </Typography>
    </Alert>
  );
}
