import _ from "lodash";
import * as React from 'react';
import { connect } from 'react-redux';
import { Outlet, Navigate } from "react-router-dom";
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';

import withRouter from '../../utils/withRouter';
import * as analytics from '../../utils/analytics';
import * as userActions from '../../actions/user';

import Footer from './Footer';


function OnboardingTeams(props) {
  const {
    saveFavorites,
    leagues,
    teams,
    history,
    user
  } = props;

  const onClose = () => history.replace("../");

  const activeLeagues =  _.chain(leagues)
    .reject(l => _.includes(["wnba"], _.lowerCase(l.abbrv)))
    .map(l => l.id)
    .value()

  const favoriteTeams = _.chain(user)
    .get(["favorites"], [])
    .filter(f => f.favoritable_type == "Team")
    .map(f => f.favoritable_id)
    .value();

  const teamsByLeague = _.reduce(favoriteTeams, (acc, teamId) => {
    // Find the team with the current teamId
    const team = _.find(teams, { id: teamId });
    if (team) {
      // Find the league corresponding to the team's league_id
      const league = _.find(leagues, { id: team.league_id });
      if (league) {
        // Use the league abbreviation as a key in the accumulator object
        const leagueAbbr = league.abbrv;
        // Ensure the key exists and push the teamId into its array
        acc[leagueAbbr] = teamId;
      }
    }
    return acc;
  }, {});

  const city =_.get(user, ["city"])
  const cityTeams = _.filter(teams, t => t.region_name == city);

  const leagueIds = _.reduce(leagues, (acc, l) => {
    acc[l.abbrv] = l.id
    return acc
  }, {})

  const formik = useFormik({
    initialValues: !_.isEmpty(favoriteTeams) ? favoriteTeams : {
      nfl: "",
      mlb: "",
      nba: "",
      nhl: "",
    },
    onSubmit: async (values) => {
      const ids = _.chain(values)
        .values()
        .reject(id => !id)
        .value();

      if (ids.length == 0) {
        return history.push("/app");
      }

      try {
        const teamFavorites = _.map(ids, id => {
          return {
            favoritable_id: id,
            favoritable_type: "Team"
          }
        });

        const leagueFavorites = _.map(activeLeagues, id => {
          return {
            favoritable_id: id,
            favoritable_type: "League"
          }
        })

        const results = await saveFavorites(
          user,
          [...teamFavorites, ...leagueFavorites]
        )

        if (results.errors) {

          history.push("/app")
        } else {
          history.push("/app")
        }
      } catch(err) {
        history.push("/app")
      }
    },
  });

  const leaguesWithTeam = ["nfl", "mlb", "nba", "nhl"];

  const relevantLeagues = _.chain(leagues)
    .filter(l => _.includes(leaguesWithTeam, _.toLower(l.abbrv)))
    .value()

  React.useEffect(() => {
    if (!!city && _.isEmpty(favoriteTeams)) {
      const toTeam = league => _.chain(cityTeams).find(t => t.league_id == leagueIds[league]).get("id", "").value();

      formik.resetForm({
        values: {
          nfl: toTeam("nfl"),
          mlb: toTeam("mlb"),
          nba: toTeam("nba"),
          nhl: toTeam("nhl")
        }
      });
    }
  }, [city]);

  return (
    <Box>
      <Box>
        <Box mb={2}>
          <Typography variant="body1" fontWeight={600} lineHeight={1.3}>
            Who are your favorite teams?
          </Typography>

          <Typography variant="body2" color="text.secondary" fontWeight={500}>
            If you don't follow specific leagues,
            you can still pick local teams
            to save in fun ways on everyday expenses.
          </Typography>
        </Box>

        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            {
              _.map(relevantLeagues, league => {
                const leagueTeams = _.chain(teams)
                  .filter(t => t.league_id == league.id)
                  .sortBy("name")
                  .value();

                return (
                  <Box key={league.id}>
                    <InputLabel sx={{mb: 1}}>{
                      <Typography variant="body3" fontWeight={600}>{`Favorite ${_.toUpper(league.abbrv)} team`}</Typography>
                    }</InputLabel>

                    <Select
                      fullWidth
                      name={league.abbrv}
                      value={formik.values[league.abbrv]}
                      onChange={formik.handleChange}>
                        {
                          _.map(leagueTeams, l => {
                            return <MenuItem key={l.id} value={l.id}>
                              <Typography variant="body3">{l.name}</Typography>
                            </MenuItem>
                          })
                        }

                      </Select>
                    </Box>
                )
              })
            }
          </Stack>

          <Footer
            disabled={
              _.chain(formik.values)
                .values()
                .filter(id => !!id)
                .value().length == 0
            }
            goBack={history.goBack}
            loading={formik.isSubmitting}
            progress={66} />
        </form>
      </Box>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "leagues", "teams");
export default connect(select, {
  ...userActions
})(withRouter(OnboardingTeams));
