import _ from 'lodash';
import React, { useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';

import request from '../utils/request';

const PlaidLinkButton = ({ button, fullWidth, onSuccess, onExit, saving }) => {
  const [linkToken, setLinkToken] = React.useState(null);

  React.useEffect(() => {
    fetchLinkToken();
  }, []);

  const fetchLinkToken = async () => {
    try {
      const { data } = await request.post('/plaid/link_token');

      if (data.errors) {

      } else {
        setLinkToken(data.link_token);
      }
    } catch(err) {
      console.log(err)
    }
  };

  const config = {
    token: linkToken,
    onSuccess,
    onExit: () => {
      if (onExit != null) onExit()
    }
    // Optionally, you can add other configuration parameters here:
    // env: 'sandbox',
    // clientName: 'Gravy Rewards',
  };

  const { open, ready } = usePlaidLink(config);

  const onClick = () => {
    open();
  }

  if (saving) return <LoadingButton fullWidth={fullWidth} variant="contained">Connecting...</LoadingButton>

  return button ?
      React.cloneElement(button, { onClick }) :
      <Button fullWidth={fullWidth} variant="contained" onClick={onClick} sx={{
        background: "black !important"
      }} startIcon={<img src="https://cdn-images-1.medium.com/v2/resize:fit:1200/1*7B-88PmnmGE5J7oRQscIeg.png" style={{height: 30}} />}>
        Connect
      </Button>
};

export default PlaidLinkButton;
