import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Loader from '../../components/Loader';
import request from '../../utils/request';

import Details from '../leaderboard/Details';

export default function LeaderBoardMain(props) {
  const { userGroup } = props;

  const [leaders, setLeaders] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [period, setPeriod] = React.useState(0);
  const periods = ["week", "month", "all"];

  const fetchLeaders = async period => {
    try {
      setLoading(true);

      const { data } = await request.get(`leaderboards`, {
        params: {
          period: periods[period],
          usernames: _.map(userGroup.users, u => u.username)
        }
      })

      if (data.errors) {
        alert(data.errors.message)
      } else {
        setLeaders(data)
      }
    } catch(err) {

    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    fetchLeaders(period)
  }, [period]);

  return (
    <Box pt={1} pb={2}>
      <Details
        leaders={leaders}
        loading={loading} />
    </Box>
  );
}
