import _ from "lodash";

import actionTypes from "../constants/index";

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.user.CREATE:
      return payload.user;
    case actionTypes.user.SHOW:
    case actionTypes.user.UPDATE:
      return {
        ...$$state,
        ...payload.user
      }
    case actionTypes.user.DELETE:
      return {};
    default:
      return $$state;
  }
};
