import _ from 'lodash';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function FullScreenDialog(props) {
  const {maxWidth} = props;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={mobile}
      maxWidth={maxWidth}
      {..._.omit(props, ["children"])}>
     {props.children}
    </Dialog>
  );
}