import _ from "lodash";
import * as React from 'react';
import { connect } from 'react-redux';
import { Outlet, Navigate } from "react-router-dom";
import { useFormik } from 'formik';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckMarkIcon from '@mui/icons-material/Check';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import AddIcon from '@mui/icons-material/Add';

import withRouter from '../../utils/withRouter';
import * as analytics from '../../utils/analytics';
import * as userActions from '../../actions/user';
import ClickablePaper from '../../components/ClickablePaper';

import Footer from './Footer';

function OnboardingInterests(props) {
  const {
    saveFavorites,
    saveUser,
    history,
    user
  } = props;

  const onClose = () => history.replace("../");

  const interests = [{
    name: "Sports",
    description: "Save when you favorite teams and players win big.",
    value: "sports",
    icon: <SportsFootballIcon sx={{fontSize: 34}} />
  }, {
    name: "Pop Culture",
    description: "Save based on pop cultural icons, tv shows, etc.",
    value: "culture",
    icon: <LocalActivityIcon sx={{fontSize: 34}} />
  }, {
    name: "News",
    description: "Save when funny or weird things happen in the world.",
    value: "news",
    icon: <NewspaperIcon sx={{fontSize: 34}} />
  }, {
    name: "Crypto",
    description: "Save when Bitcoin and Ethereum go to the moon.",
    value: "crypto",
    icon: <ShowChartIcon sx={{fontSize: 34}} />
  }]

  const formik = useFormik({
    initialValues: {
      interests: ["sports", "culture", "news"],
    },
    onSubmit: async (values) => {
      const results = await saveUser(user, values);
      if (results.errors) {

      } else {
        history.push("../football")
      }
    },
  });

  return (
    <Box>
      <Box pb={12}>
        <Box mb={4}>
          <Typography variant="body1" fontWeight={600} lineHeight={1.3}>
            Save on sports and more.
          </Typography>

          <Typography variant="body3" color="text.secondary" fontWeight={500}>
            With Gravy, you save big on your every day expenses
            when big things happen in sports, culture, news, and the world as a whole.
          </Typography>
        </Box>

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            {
              _.map(interests, interest => {
                const added = _.includes(formik.values.interests, interest.value);

                return (
                  <Grid item key={interest.value} xs={6}>
                    <ClickablePaper sx={{
                      height: 200,
                      p: 2,
                      display: "flex"
                    }} onClick={() => {
                      if (interest.value != "sports") formik.setFieldValue(
                        "interests",
                        _.xor(formik.values.interests, [interest.value])
                      )
                    }}>
                      <Stack justifyContent="center" sx={{flexGrow: 1}}>
                        <Box mb={1}>
                          {interest.icon}
                        </Box>

                        <Typography variant="body2" fontWeight={600}>
                          {interest.name}
                        </Typography>
                        <Typography variant="body3">
                          {interest.description}
                        </Typography>

                        <Box mt={2}>
                          <Avatar sx={{
                            background: added ? "#2c7fc9" : "default",
                            height: 30,
                            width: 30
                          }}>
                            {
                              added ?
                                <CheckMarkIcon /> :
                                <AddIcon />
                            }
                          </Avatar>
                        </Box>
                      </Stack>

                    </ClickablePaper>
                  </Grid>
                )
              })
            }
          </Grid>

          <Footer
            disabled={
              _.chain(formik.values.interests)
                .filter(id => !!id)
                .value().length == 0
            }
            goBack={history.goBack}
            loading={formik.isSubmitting}
            progress={66} />
        </form>
      </Box>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user");
export default connect(select, {
  ...userActions
})(withRouter(OnboardingInterests));
