import _ from "lodash";
import * as React from "react";
import {connect} from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Loader from '../../components/Loader';

import Alert from '../../components/Alert';
import * as leaderboardActions from '../../actions/leaderboards';
import withRouter from '../../utils/withRouter';

import Details from './Details';

function LeaderBoardMain(props) {
  const { leaderboards, fetchLeaderboards } = props;

  const [loading, setLoading] = React.useState(false);
  const [period, setPeriod] = React.useState(0);

  const periods = ["week", "month", "all"];

  React.useEffect(() => {
    if (leaderboards.length == 0) setLoading(true)

    fetchLeaderboards(periods[period]).finally(() => {
      setLoading(false)
    })
  }, [period]);

  console.log(leaderboards)
  return (
    <Box pt={2} pb={12}>
      <Container maxWidth="md">
        <Box mb={2}>
          <Typography variant="h2" fontWeight={700} textAlign="center" textTransform="uppercase">
            All-Time
          </Typography>

          <Typography variant="h1" fontWeight={700} textAlign="center" textTransform="uppercase">
            Legends
          </Typography>
        </Box>

        <Stack alignItems="center">
          <Box mb={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs variant="scrollable" value={period} onChange={(e, period) => {
                setPeriod(period)
              }}>
                {
                  _.map(periods, t => {
                    return (
                      <Tab key={t} sx={{
                        textTransform: "inherit",
                        fontWeight: 600,
                        fontSize: 14
                      }} label={
                        <Typography variant="body3" fontWeight={600} textTransform="uppercase">
                          {
                            t
                          }
                        </Typography>
                        }  />
                    )
                  })
                }
              </Tabs>
            </Box>
          </Stack>


          {
            period == 0 &&
              <Alert color="primary">
                <Typography variant="body3" fontWeight={600}>
                  Every Sunday, top 5 weekly savers each win $50
                </Typography>
              </Alert>
          }

        <Box mt={2}>
          {
            loading ?
              <Loader /> :
              <Details
                leaders={leaderboards} />
          }
        </Box>
      </Container>
    </Box>
  );
}

const select = $$state => _.pick($$state, ["leaderboards"]);
export default connect(select, {
  ...leaderboardActions
})(withRouter(LeaderBoardMain));
