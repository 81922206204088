import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

export const fetchIntegrations = () => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`integrations`)

    if (!data.errors) {
      dispatch({
        type: actionTypes.integrations.INDEX,
        payload: {
          integrations: _.map(data, d => {
            return {
              id: d.id,
              ...d.attributes
            }
          })
        }
      });
    }

    return data;
  }
};

export const createIntegration = (values={}) => {
  return async (dispatch, getState) => {
    const { data } = await request.post(`integrations`, values)

    if (!data.errors) {
      dispatch({
        type: actionTypes.integrations.CREATE,
        payload: {
          integration: {
            id: data.id,
            ...data.attributes
          }
        }
      });
    }

    return data;
  }
};

export const updateIntegration = (id, values={}) => {
  return async (dispatch, getState) => {
    const { data } = await request.put(`integrations/${id}`, values)

    if (!data.errors) {
      dispatch({
        type: actionTypes.integrations.UPDATE,
        payload: {
          integration: {
            id: data.id,
            ...data.attributes
          }
        }
      });
    }

    return data;
  }
};