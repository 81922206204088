import _ from "lodash";
import React, { useState, useEffect } from 'react';
import {
  Box,
  Chip,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  Paper,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import request from '../../utils/request';
import ClickablePaper from '../../components/ClickablePaper';


function Checkout(props) {
  const { reward, merchant, nextMerchant, open, onClose } = props;

  const { title, propositions } = _.get(reward, ["parlay"], {});

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{p: 3}}>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box mt={2}>
          <Typography variant={"h3"} fontWeight={600} lineHeight={1.3}>
            Use CODE ABZ234 at checkout
          </Typography>
          <Typography variant={"h4"} fontWeight={400} lineHeight={1.3}>
            100% refund on your order if your bet wins.
          </Typography>
        </Box>

      </DialogTitle>
      <DialogContent sx={{p: 3}}>
      </DialogContent>
      <DialogActions sx={{p: 3}}>
        <Button variant="contained" size="large">
          Copy COde
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function Offer(props) {
  const { reward, merchant, nextMerchant, open, onClose } = props;

  const { title, propositions } = _.get(reward, ["parlay"], {});

  const [checkoutOpen, setCheckoutOpen] = useState(false);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{p: 3}}>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box mt={2}>
          <Typography variant={"h3"} fontWeight={600} lineHeight={1.3}>
           Get this Crumbl for free!

          </Typography>
          <Typography variant={"h4"} fontWeight={400} lineHeight={1.3}>
            You'll get a 100% refund on your order if...
          </Typography>
        </Box>

      </DialogTitle>
      <DialogContent sx={{p: 3}}>
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            borderRadius: 2,
          }}>
            <Box>
              <Box mb={3}>
                <Box mt={1}>
                  <Typography mb={1} variant="body2" fontWeight={600}>
                    {title}
                  </Typography>
                  <Stack spacing={2}>
                    {
                      _.map(propositions, (prop, i) => {
                        const { win_description } = prop;

                        return (
                          <Stack direction="row" spacing={2} alignItems="center">
                            <Chip sx={{width: 60}} size="small" label={
                              <Typography variant={"body4"} fontWeight={600}>
                                {
                                  i == 0 ? "IF" : "AND"
                                }
                              </Typography>
                            } />

                            <Box key={prop.id}>
                              <Typography variant={"body1"} fontWeight={500} lineHeight={1.3}>
                                {
                                  win_description
                                }
                              </Typography>

                            </Box>
                          </Stack>
                        )
                      })

                    }
                  </Stack>
                </Box>
              </Box>
            </Box>
        </Paper>
      </DialogContent>
      <DialogActions sx={{p: 3}}>
        <Button variant="contained" size="large" onClick={() => setCheckoutOpen(true)}>
          I want this
        </Button>
      </DialogActions>

      <Checkout
        merchant={merchant}
        reward={reward}
        open={checkoutOpen}
        onClose={() => setCheckoutOpen(false)} />
    </Dialog>
  )


}

export default function DemosDraftkings(props) {
  const [open, setOpen] = useState(false);
  const [reward, setReward] = useState(null);
  const [checkout, setCheckout] = useState(false);
  const [merchantIndex, setMerchantIndex] = useState(0)
  const [merchantOffers, setMerchantOffers] = useState([]);

  const toggleCheckout = () => setCheckout(!checkout);

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchReward()
    fetchMerchantOffers()
  }, [])

  const fetchMerchantOffers = async () => {
    const { data } = await request.get(`merchant_offers`)
    if (!data.errors) {
      setMerchantOffers(
        _.map(data, d => {
          return {
            ...d,
            ...d.attributes
          }
        })
      )
    }
  }

  const fetchReward = async () => {
    setReward({
      parlay: {
        id: "123",
        title: "Pistons vs. Jazz",
        propositions: [{
          win_description: "Tobias Harris scores 30+"
        }, {
          win_description: "Tobias Harris dishes 8+ assists"
        }, {
          win_description: "Pistons win by 10+"

        }]
      }
    });
  }

  const merchant = _.get(merchantOffers, [merchantIndex, "merchant"], {});

  return (
    <Box>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundImage: `url('https://p70.p4.n0.cdn.zight.com/items/Apuql7Pz/86b36acb-f86b-4617-ba32-99aabb596974.png?source=viewer&v=005b28ddd0f8cdc0b3bcb025e696a8ac')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 0, // Ensure the background is behind the dialog
        }}
      />

      <Offer
        reward={reward}
        merchant={merchant}
        nextMerchant={() => setMerchantIndex(merchantIndex + 1)}
        open={open}
        onClose={() => setOpen(false)} />
    </Box>
  );
};
