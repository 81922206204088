import * as React from "react";
import {
  useNavigate,
  useLocation,
  useParams
} from "react-router-dom";

const withRouter = WrappedComponent => props => {
  const location = useLocation();
  const params = useParams();
  const match = { params };
  const navigate = useNavigate();

  const history = {
    back: () => navigate(-1),
    goBack: () => navigate(-1),
    location,
    push: (url, state) => navigate(url, { state }),
    replace: (url, state) => navigate(url, {
      replace: true,
      state
    })
  };

  return (
    <WrappedComponent
     	history={history}
      location={location}
      params={params}
      match={match}
      navigate={navigate}
      {...props}
    />
  );
};

export default withRouter

