import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";
import card from '../utils/raincard';
import * as environment from '../environment';


export const fetchCards = () => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`cards`)

    if (!data.errors) {
      dispatch({
        type: actionTypes.cards.INDEX,
        payload: {
          cards: _.map(data, d => {
            return {
              id: d.id,
              ...d.attributes
            }
          })
        }
      });
    }

    return data;
  }
};

export const fetchCardBalance = id => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`cards/${id}/balance`)

    if (!data.errors) {
      dispatch({
        type: actionTypes.cards.UPDATE,
        payload: {
          card: {
            id,
            balance: data.balance
          }
        }
      });
    }

    return data;
  }
};

export const fetchCardNumbers = id => {
  return async (dispatch, getState) => {
    const pem = environment.production ?
      `
      -----BEGIN PUBLIC KEY-----
      MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCeZ9uCoxi2XvOw1VmvVLo88TLk
      GE+OO1j3fa8HhYlJZZ7CCIAsaCorrU+ZpD5PUTnmME3DJk+JyY1BB3p8XI+C5uno
      QucrbxFbkM1lgR10ewz/LcuhleG0mrXL/bzUZbeJqI6v3c9bXvLPKlsordPanYBG
      FZkmBPxc8QEdRgH4awIDAQAB
      -----END PUBLIC KEY-----` :
     `
      -----BEGIN PUBLIC KEY-----
      MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCAP192809jZyaw62g/eTzJ3P9H
      +RmT88sXUYjQ0K8Bx+rJ83f22+9isKx+lo5UuV8tvOlKwvdDS/pVbzpG7D7NO45c
      0zkLOXwDHZkou8fuj8xhDO5Tq3GzcrabNLRLVz3dkx0znfzGOhnY4lkOMIdKxlQb
      LuVM/dGDC9UpulF+UwIDAQAB
      -----END PUBLIC KEY-----
    `
    const strippedPem = pem
      .trim() // Remove leading and trailing whitespace
      .replace(/[\n\r]/g, '') // Remove all newlines
      .replace(/\s+/g, ' '); // Replace multiple spaces with a single space

    const { secretKey, sessionId} = await card.generateSessionId(strippedPem);

    const { data } = await request.post(`cards/${id}/secrets`, {
      session_id: sessionId
    });

    const cvcSecret = data.encryptedCvc;
    const panSecret = data.encryptedPan;

    const cvc = await card.decryptSecret(cvcSecret.data, cvcSecret.iv, secretKey);
    const pan = await card.decryptSecret(panSecret.data, panSecret.iv, secretKey);

    if (!data.errors) {
      dispatch({
        type: actionTypes.cards.UPDATE,
        payload: {
          card: {
            id,
            cvc,
            pan
          }
        }
      });
    }

    return data;
  }
};
