import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import Alert from '../../components/Alert';
import Link from '../../components/Link';
import Loader from '../../components/Loader';
import ClickablePaper from '../../components/ClickablePaper';

import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import * as analytics from '../../utils/analytics';
import * as userActions from '../../actions/user';
import * as alertActions from '../../actions/alerts';

import FeedItemCard from '../feedItems/Card';
import Card from '../cards/Card';

export default function InstructionsShop(props) {
  const {
    user,
    savePlaidUser,
    merchantOffers,
    card,
    next,
  } = props;

  return (
    <Box>
      <Box>
        {
          card?.source == "gravy"  ?
            <Box>
              <Typography variant="h4" fontWeight={700} lineHeight={1.3}>
                Add this card to your wallet
              </Typography>
              <Typography variant="body1">
                Apple or Google wallets supported.
              </Typography>
            </Box> :
            <Box>
              <Typography variant="h4" fontWeight={700} lineHeight={1.2}>
                Connect your card to Gravy
              </Typography>
              <Typography variant="body1">
                Every time you shop with your card, you earn a chance
                to save on that order if something cool happens in sports,
                news, or culture.
              </Typography>

            </Box>
        }

      </Box>

      <Box mt={3}>

        {
          card?.source == "gravy" &&
            <Card {...props} {...card} skipClick={true}  />
        }
      </Box>

    </Box>
  );
}
