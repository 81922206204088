import * as React from 'react';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

export default function PopoverPopupState({action, content}) {
  return (
    <PopupState variant="popover">
      {(popupState) => {
        let actionComponent = React.cloneElement(action, {
          ...bindTrigger(popupState)
        })

        return (
          <React.Fragment>
            {actionComponent}

            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}>
              {
                content(popupState)
              }
            </Popover>
          </React.Fragment>
        )
      }}
    </PopupState>
  );
}