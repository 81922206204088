import _ from "lodash";
import * as React from "react";
import {
  Link as RouterLink,
} from "react-router-dom";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import HelpIcon from '@mui/icons-material/Help';

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';
import currencyFormatter from '../../utils/currencyFormatter';
import FullScreenDialog from '../../components/FullScreenDialog';

import {usePostHog} from "posthog-js/react";
import request from '../../utils/request';

export default function ErrorMessage({cards, fetchCards, open, onClose, error}) {
  React.useEffect(() => {
    if (cards.length == 0) fetchCards()
  }, []);

  const [refreshing, setRefreshing] = React.useState(false);
  const [refreshed, setRefreshed] = React.useState(false);

  const toggleRefreshing = () => setRefreshing(!refreshing);

  const refresh = async () => {
    try {
      setRefreshing(true)
      const { data } = await request.post(`plaid/transactions_refresh`)
      if (data.errors) {
        alert(data.errors)
        setRefreshing(false)
      } else {
        setTimeout(() => {
          setRefreshing(false)
          setRefreshed(true)
        }, 1000 * 20)
      }
    } catch(err) {
      alert("Something went wrong, please try again later")
      setRefreshing(false)
    }
  }

  const refreshable = _.filter(cards, c => c.source == "plaid").length > 0;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box mt={1} mb={2}>
          <Typography variant="h4" fontWeight={600}>
            Pick required
           </Typography>
          <Typography variant="body1" lineHeight={1.3}>
            Adding picks is simple.
           </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
      </DialogContent>
      <DialogActions sx={{p: 3}}>
        <Button size="large" variant="contained" startIcon={<AddIcon />} component={RouterLink} to="/app/picks">
          Add Pick
        </Button>
      </DialogActions>
    </Dialog>
  )
}