const initialState = {
  alerts: [],
  cards: [],
  integrations: [],
  notifications: [],
  offers: [],
  referrals: [],
  regions: [],
  sports: [],
  feedItems: [],
  leagues: [],
  leaderboards: [],
  merchantOffers: [],
  paymentMethods: [],
  payouts: [],
  players: [],
  picks: [],
  propositions: [],
  surveys: [],
  teams: [],
  transactions: [],
  user: {},
  userGroups: [],
  points: null
};

export default initialState;
