import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

export const fetchSurveys = () => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`surveys`)

    if (!data.errors) {
      dispatch({
        type: actionTypes.surveys.INDEX,
        payload: {
          surveys: _.map(data, d => {
            return {
              id: d.id,
              ...d.attributes
            }
          })
        }
      });
    }

    return data;
  }
};

export const updateSurvey = (survey, update) => {
  return async (dispatch, getState) => {
    const { data } = await request.put(`surveys/${survey.id}`, update)

    if (!data.errors) {
      dispatch({
        type: actionTypes.surveys.UPDATE,
        payload: {
          surveys: _.map(data, d => {
            return {
              id: d.id,
              ...d.attributes
            }
          })
        }
      });
    }

    return data;
  }
};

export const deleteSurvey = id => {
  return {
    type: actionTypes.surveys.DELETE,
    payload: {
      id
    },
  };
};