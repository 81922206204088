import _ from "lodash";
import * as React from "react";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Link from '../../../components/Link';

export default function BanterSms(props) {
  return (
    <Paper variant="outlined" sx={{p: 3}}>
      <Typography variant="body2" color="text.secondary">
        Brands are viewing these offers as an alternative to
        their normal fixed discounts, however, these are much harder to distribute
        existing and new customers. We charge brands fees to make the process
        of creating and distributing these offers simple.
      </Typography>
    </Paper>
  );
}
