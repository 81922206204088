import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';

export default function InstructionsPour(props) {
  const { merchant } = props;

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h3" fontWeight={600}>
          Want to fund {merchant.name}?
        </Typography>

        <Typography variant="body1" fontWeight={400}>
          This feature is waitlisted, get to the front of the line!
        </Typography>
      </Box>
    </Box>
  );
}
