import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Link from './Link';

export default props => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { hideLogo } = props;

  return (
    <Paper sx={{p: 1}} variant={
      props.variant || "outlined"
    }>
      <Stack direction="row" alignItems="center" justifyContent={
        mobile ? "start" : "center"
      }>
        {
          hideLogo ||
            <img src="https://www.logo.wine/a/logo/Plaid_(company)/Plaid_(company)-Vertical-Logo.wine.svg"
              style={{
                height: 40,
                position: "relative",
                top: 3
              }} />
        }

        <Typography variant={
          props.size == "small" ? "body3" : "body3"
        } fontWeight={500}>
          Gravy Built by the same team behind <Link color="inherit" color="secondary" fontWeight={600} target="_blank" href="/about">Plaid</Link>
        </Typography>
      </Stack>
    </Paper>
  )
}
