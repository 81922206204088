import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Loader from '../../components/Loader';

import withRouter from '../../utils/withRouter';
import * as offersActions from '../../actions/offers';

const OffersMain = props => {
  return (
    <Box>
      <Container maxWidth="md">
        <Outlet />
      </Container>
    </Box>
  );
}

const select = $$state => _.pick($$state, ["offers"]);
export default connect(select, {
  ...offersActions,
})(withRouter(OffersMain));
