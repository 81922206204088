import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function CarsdWallet(props) {
  const {
    pan,
    cvc,
    expiration_month,
    expiration_year,
    open,
    onClose
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body2" fontWeight={600}>
            Wallet Instructions
          </Typography>

          <Typography variant="body2" color="text.secondary" fontWeight={500}>
            Open your mobile wallet, select add debit or credit card,
            and then paste the number below.
          </Typography>

          <Box mt={3}>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" spacing={2}>
                <Stack spacing={1}>
                  <Typography variant="body3" fontWeight={600}>Expires</Typography>
                  <Typography variant="body3">{
                    `${expiration_month}/${expiration_year}`
                  }</Typography>
                </Stack>

                <Stack spacing={1}>
                  <Typography variant="body3" fontWeight={600}>CVC</Typography>

                  <Typography variant="body2">{cvc}</Typography>
                </Stack>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}>

                <Stack spacing={1}>
                  <Typography variant="body3" fontWeight={600}>Number</Typography>
                  <Typography variant="body2">{pan}</Typography>
                </Stack>

              </Stack>

            </Stack>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{p: 3}}>
        <CopyToClipboard
          text={pan}
          onCopy={() => {
            alert("copied")
          }}>

            <Button variant="contained">
              Copy Card Number
            </Button>
        </CopyToClipboard>
      </DialogActions>
    </Dialog>
  );
}
