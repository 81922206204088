import _ from "lodash";
import qs from 'qs';
import Confetti from 'react-confetti';
import { connect } from 'react-redux';
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { Soup, BookOpenText, Activity, Share, CreditCard, ShoppingBasket, Zap } from 'lucide-react';

import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import Link from '../../components/Link';
import FullScreenDialog from '../../components/FullScreenDialog';
import * as cardActions from '../../actions/cards';
import * as alertActions from '../../actions/alerts';
import withRouter from '../../utils/withRouter';
import request from '../../utils/request';

function EarnList(props) {
  const { user, picks, cards, fetchCards, createAlert, location, history } = props;

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );

  const [loading, setLoading] = React.useState(true);
  const [isExploding, setIsExploding] = React.useState(search.p == 1);

  const onClose = () => history.push(`/app`, {replace: true})

  React.useEffect(() => {
    if (cards.length == 0) {
      fetchCards().finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [])

  const gravyCard = _.find(cards, c => c.source == "gravy");
  const plaidCard = _.find(cards, c => c.source == "plaid");

  const allowExternalConnect = plaidCard == null;

  const earnPicks = async operation => {
   return request.post(`picks`, { operation })
  }

  return (
    <Box>
      <Typography variant="h2">
        Earn more Gravy Picks
      </Typography>
      <Typography variant="body1">
        Picks get you points which turn into <Link
          fontWeight={600}
          color="secondary"
          component={RouterLink} to="/app/merchants">free shopping
        </Link>.
      </Typography>

      <Box mt={3}>
        <Stack spacing={2}>
          <Paper variant="outlined" sx={{p: 2}}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                <Stack direction="row"  spacing={2} alignItems="center">
                  <Soup size={34} />

                  <Box>
                    <Typography variant="body1" fontWeight={600} lineHeight={1.2}>
                      Complimentary Picks
                    </Typography>
                    <Typography variant="body2">
                      Gravy gifts Picks weekly
                    </Typography>
                  </Box>
                </Stack>
              </Stack>

              <Button
                sx={{width: 120}}
                variant="contained"
                component={RouterLink}
                to="/app/feed">
                Pick
              </Button>
            </Stack>
          </Paper>

          <Paper variant="outlined" sx={{p: 2}}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
              <Stack direction="row"  spacing={2} alignItems="center">
                <BookOpenText size={34} />

                <Box>
                  <Typography variant="body1" fontWeight={600} lineHeight={1.2}>
                    Complete surveys
                  </Typography>
                  <Typography variant="body2">
                    Pick per study completed
                  </Typography>
                </Box>
              </Stack>


              <Button sx={{width: 120}} variant="contained" component={RouterLink} to={
                "/app/account/surveys"
              }>
                Start
              </Button>
            </Stack>
          </Paper>
        </Stack>
      </Box>
    </Box>
  )
}

const select = $$state => _.pick($$state, "user", "cards", "picks");
export default connect(select, {
  ...cardActions,
  ...alertActions
})(withRouter(EarnList));
