import _ from "lodash";
import * as React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Loader from '../../components/Loader';
import Logo from '../../components/Logo';
import DialogCloseButton from '../../components/DialogCloseButton';
import * as leagueActions from '../../actions/leagues';
import * as teamActions from '../../actions/teams';
import withRouter from '../../utils/withRouter';


function OnboardingMain(props) {
  const { user, fetchLeagues, fetchTeams, location, history} = props;

  const [loading, setLoading] = React.useState(false);

  const onClose = () => {
    history.replace("../../")
  }

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  const fetchData = async () => {
    return Promise.all([
      fetchLeagues(),
      fetchTeams()
    ])
  }

  const params = new URLSearchParams(location.search);
  const referralToken = params.get('ref');
  if (referralToken) {
    window.localStorage.setItem('referralToken', referralToken);
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullScreen={true}>

      <DialogContent>
        <Container maxWidth="sm">
          <img
            src="https://res.cloudinary.com/dxxog3y9j/image/upload/v1726686197/gravy_1_we9oi7.png"
            style={{
              height: 40
            }}/>

          <Box mt={2}>
            {
              loading ?
                <Loader /> :
                <Outlet />
            }
          </Box>
        </Container>
      </DialogContent>
    </Dialog>
  )
}

const select = $$state => _.pick($$state, "user", "leagues", "teams");
export default connect(select, {
  ...teamActions,
  ...leagueActions,
})(withRouter(OnboardingMain));
