import _ from "lodash";
import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function ConditionalRebateDescription(props) {
  return (
    <Paper variant="outlined" sx={{p: 3}}>
      <Typography variant="body2" color="text.secondary">
        Brands are finding it harder and harder to gain attention
        and discount codes are so standard and common place that they
        don't move the needle for shoppers. Tying offers to local sports
        events is a gamified, hyper fun way to offer deals to shoppers.
      </Typography>
    </Paper>
  );
}
