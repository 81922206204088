import _ from "lodash";
import * as React from "react";
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { Gift } from 'lucide-react'

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import request from '../../utils/request';
import currencyFormatter from '../../utils/currencyFormatter';
import * as dates from '../../utils/dates';

import Confirmation from './Confirmation';
import Explanation from './Explanation';
import AuthButton from '../user/AuthButton';

export default function RewardsDetails(props) {
  const context = useOutletContext();

  const {
    user,
    reward,
    feedItems,
    fetchFeedItems,
    params,
    history
  } = context;

  const [open, setOpen] = React.useState(false)
  const [selectedProposition, setSelectedProposition] = React.useState(null);
  const toggleOpen = () => setOpen(!open)

  const { merchant, amount_cents, cost_cents, sender_name, propositions } = reward;

  const proposition = propositions[0];

  const { event, eligibility_ends_at, win_description } = proposition;

  function convertQuestionToStatement(sentence) {
    // Check if the sentence starts with "Will" and ends with a question mark
    if (sentence.startsWith("Will") && sentence.endsWith("?")) {
      // Remove "Will" from the start and the question mark from the end
      let statement = sentence.slice(4, -1).trim();

      // Split the statement into words to detect the verb
      let words = statement.split(' ');

      // Find the first verb and convert it to the present tense
      const verbMapping = {
        'win': 'wins',
        'be': 'is',
        'have': 'has',
        'do': 'does',
        'go': 'goes',
        'make': 'makes',
        'take': 'takes',
        'give': 'gives',
        'get': 'gets',
        'say': 'says'
        // Add more verb mappings as needed
      };

      // Loop through the words to find the verb
      for (let i = 0; i < words.length; i++) {
        let word = words[i].toLowerCase();
        if (verbMapping[word]) {
          // Replace the verb with its present tense form
          words[i] = verbMapping[word];
          break;
        }
      }

      // Reassemble the sentence and append a period
      return words.join(' ') + '.';
    }

    // If the sentence doesn't start with "Will", replace the question mark with a period if it exists
    return sentence.endsWith('?') ? sentence.slice(0, -1) + '.' : sentence;
  }

  return (
    <Box>
      <Stack spacing={2} direction="column" alignItems="center" justifyContent="center">
        <Typography variant="h2" fontWeight={400} textAlign="center">
          {
            sender_name
          } sent you a Gift
        </Typography>

        <Box>
          <Typography variant="h1" fontWeight={600} textAlign="center" mt={0.5}>
            {
              currencyFormatter(amount_cents / 100, 0)
            } Gift Card When...
          </Typography>

          <Typography variant="h2" fontWeight={400} textAlign="center" mt={0.5}>
            Cool things happen in sports or culture
          </Typography>
        </Box>
      </Stack>

      {
        false &&
          <Box mt={2}>
            <Alert color="secondary">
              <Link fontWeight={600} variant="body2" component={RouterLink} to="" color="secondary" textDecoration="italic" onClick={() => {
                toggleOpen()
              }}>How does this work exactly?</Link>
            </Alert>
          </Box>

      }

      <Box mt={6}>
        <Stack alignItems="center">
          <Button variant="contained" size="large" color="secondary" startIcon={<Gift />} component={
            RouterLink
          } to="./category">
            open your gift
          </Button>
        </Stack>
      </Box>


      <Outlet context={{
        reward,
        ...context
      }} />

      <Confirmation
        user={user}
        reward={reward}
        proposition={selectedProposition}
        open={!!selectedProposition}
        onClose={() => setSelectedProposition(null)}
        history={history} />

    </Box>
  )
}
