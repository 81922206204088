import _ from "lodash";
import * as React from "react";
import { Navigate, Link as RouterLink, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import withRouter from '../../utils/withRouter';
import * as userActions from '../../actions/user';

import Referrals from '../onboarding/Referrals';
import Alert from '../../components/Alert';

function AccountDetails(props) {
  const { user, logout, destroyPlaidUser, history } = props;

  const [referralsOpen, setReferralsOpen] = React.useState(false);
  const [plaidOpen, setPlaidOpen] = React.useState(false);

  return (
    <Container maxWidth="sm">
      <Stack direction="row" sx={{
        alignItems: "center",
      }} spacing={2}>
        <Avatar
          src={user.avatar_url}
          sx={{
           width: 60,
           height: 60,
          }} />

        <Box>
          <Typography variant="h5">Account</Typography>
          <Stack spacing={1} direction="row" sx={{
            alignItems: "center"
          }}>
            <Typography
              color="text.secondary"
              variant="body2">{user.phone}</Typography>
          </Stack>
        </Box>
      </Stack>

      <Stack spacing={4} sx={{mt: 2}}>
        <Paper variant="outlined">
          <List
            dense
            sx={{
              width: "100%",
              maxWidth: "100vw"
             }}>

           {
              false &&
                <ListItem disablePadding divider>
                  <ListItemButton onClick={() => {
                    history.push("./surveys")
                  }}>
                    <ListItemText>Surveys</ListItemText>
                    <ArrowForwardIosIcon sx={{
                      fontSize: 14,
                      color: "text.secondary"
                    }}  />
                  </ListItemButton>
                </ListItem>
           }

            <ListItem disablePadding divider>
              <ListItemButton onClick={() => {
                history.push("/app/points")
              }}>
                <ListItemText>Points</ListItemText>
                <ArrowForwardIosIcon sx={{
                  fontSize: 14,
                  color: "text.secondary"
                }}  />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding divider>
              <ListItemButton onClick={() => {
                history.push("./groups")
              }}>
                <ListItemText>Groups</ListItemText>
                <ArrowForwardIosIcon sx={{
                  fontSize: 14,
                  color: "text.secondary"
                }}  />
              </ListItemButton>
            </ListItem>


            <ListItem disablePadding divider>
              <ListItemButton onClick={() => {
                history.push("./savings")
              }}>
                <ListItemText>Savings</ListItemText>
                <ArrowForwardIosIcon sx={{
                  fontSize: 14,
                  color: "text.secondary"
                }}  />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding divider>
              <ListItemButton onClick={() => {
                history.push("./referrals")
              }}>
                <ListItemText>Referrals</ListItemText>
                <ArrowForwardIosIcon sx={{
                  fontSize: 14,
                  color: "text.secondary"
                }}  />
              </ListItemButton>
            </ListItem>

            {
              false &&
                <ListItem disablePadding divider>
                  <ListItemButton onClick={() => {
                    history.push("./fantasy")
                  }}>
                    <ListItemText>Fantasy</ListItemText>
                    <ArrowForwardIosIcon sx={{
                      fontSize: 14,
                      color: "text.secondary"
                    }}  />
                  </ListItemButton>
                </ListItem>
            }

            {
              false &&
                <ListItem disablePadding divider>
                  <ListItemButton onClick={() => {
                    setReferralsOpen(true)
                  }}>
                    <ListItemText>Referrals</ListItemText>
                    <ArrowForwardIosIcon sx={{
                      fontSize: 14,
                      color: "text.secondary"
                    }}  />
                  </ListItemButton>
                </ListItem>
            }

            <ListItem disablePadding divider>
              <ListItemButton onClick={() => {
                alert("Please email help@itsgravy.co")
              }}>
                <ListItemText>Help</ListItemText>
                <ArrowForwardIosIcon sx={{
                  fontSize: 14,
                  color: "text.secondary"
                }}  />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton onClick={() => {
                logout();
              }}>
                <ListItemText fontWeight={600}>Logout</ListItemText>
                <ArrowForwardIosIcon sx={{
                  fontSize: 14,
                  color: "text.secondary"
                }}  />
              </ListItemButton>
            </ListItem>
          </List>
        </Paper>
      </Stack>

      <Referrals open={referralsOpen} onClose={() => {
        setReferralsOpen(false);
      }} />

      <Outlet />
    </Container>
  );
}

const select = $$state => _.pick($$state, "user");
export default connect(select, userActions)(withRouter(AccountDetails));
