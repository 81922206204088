import _ from "lodash";
import moment from 'moment';
import qs from 'qs';
import * as React from "react";
import { connect } from 'react-redux';
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';
import * as surveyActions from '../../actions/surveys';
import withRouter from '../../utils/withRouter';

function SurveysList(props) {
  const {
    surveys,
    updateSurvey,
    history,
    location
  } = props;

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );


  React.useEffect(() => {
    if (!!search.title) {
      const survey = _.find(surveys, s => {
        return _.lowerCase(s.title) == _.lowerCase(search.title)
      })

      if (!!survey && survey.status == "pending") {
        updateSurvey(survey, {status: "reviewing"})
      }
    }
  }, [search.title])

  return (
    <Box>
      <Box mt={2}>
        <Stack spacing={1}>
          {
            _.map(surveys, (survey, i) => {
              return (
                  <ClickablePaper key={i} variant="outlined" onClick={() => {
                    if (survey.status == "pending") {
                      window.open(survey.url, {target: "_blank"})
                    }
                  }}>
                    <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between">
                      <Box>
                        <Typography variant="h2" lineHeight={1}>
                          +1 Pick
                        </Typography>
                        <Typography variant="h5" fontWeight={500} color="text.secondary">
                          {survey.title}
                        </Typography>
                      </Box>

                      {
                        survey.status == "pending" ?
                          <Chip label={
                            <Typography variant="body4" fontWeight={600} textTransform="uppercase">
                              {survey.seconds / 60} Minutes
                            </Typography>

                          } /> :
                          <Chip label={
                            <Typography variant="body4" fontWeight={600} textTransform="uppercase">
                              {survey.status}
                            </Typography>

                          } />

                      }

                    </Stack>
                  </ClickablePaper>
              )
            })
          }
        </Stack>
      </Box>
    </Box>

  )
}


const select = $$state => _.pick($$state, ["surveys"]);
export default connect(select, {
  ...surveyActions
})(withRouter(SurveysList));
