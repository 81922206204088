import _ from "lodash";
import qs from 'qs';
import * as React from 'react';
import { Link as RouterLink, Outlet, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import * as yup from 'yup';
import { useFormik, Field } from 'formik';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';

import Alert from '../../components/Alert';
import PhoneInput from '../../components/PhoneInput';
import Link from '../../components/Link';
import TextField from '../../components/TextField';
import GravyVideo from '../../components/GravyVideo';
import withRouter from '../../utils/withRouter';
import * as userActions from '../../actions/user';
import * as alertActions from '../../actions/alerts';

import AuthButton from '../user/AuthButton';
import Footer from './Footer';
import Preview from './Preview';

const validationSchema = yup.object({
  phone: yup
    .string()
    .required()
    .length(10, 'Must be exactly 10 digits')
});

function OnboardingIdentity(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { user, createUser, offers, history, location } = props;

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );

  const onClose = () => history.replace("../");

  const offer = _.chain(offers)
    .filter(p => p.status == "pending")
    .first()
    .value() || {};

  const formik = useFormik({
    initialValues: {
      phone: _.get(
        user,
        ["phone"],
        _.get(search, ["phone"], "")
      ),
    },
    validationSchema,
    onSubmit: async ({phone}) => {
      try {
        const results = await createUser({
          phone,
        });

        if (!results.errors) {
          history.push("./activation")
        } else if (results.errors.message == "ALREADY_EXISTS") {
          alert("This account already exists, please click LOG IN")
        } else {
          alert(results.errors.message)
        }
      } catch(err) {
        alert("There was an error")
      } finally {
      }
    },
  });

  return (
    <Box pb={12}>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={3}>
          <Typography variant="h3" fontWeight={600}>
            What's your phone number?
          </Typography>

          <Typography variant="body1" color="text.secondary" fontWeight={500}>
            Already have an account, <AuthButton>
              <Link color="secondary" fontWeight={600}>please login</Link>
            </AuthButton>.
          </Typography>
        </Box>

        <Box>
          <TextField
            autoFocus
            type="number"
            fullWidth
            name="phone"
            onChange={formik.handleChange}
            value={formik.values.phone}
            label="Your Phone"
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}/>
        </Box>

        <Box mt={3} mb={3}>
            <Stack direction="row" alignItems="center" justifyContent={
              mobile ? "start" : "center"
            }>
              <img src="https://www.logo.wine/a/logo/Plaid_(company)/Plaid_(company)-Vertical-Logo.wine.svg"
                style={{
                  height: 40,
                  position: "relative",
                  top: 3
                }} />
              <Link variant="body3" fontWeight={600} color="primary" component={RouterLink} to="/about" target="_blank">
                Gravy is built by the team behind Plaid
              </Link>
            </Stack>
        </Box>


        <Footer
          loading={formik.isSubmitting}
          progress={50} />
      </form>


      <GravyVideo />

      <Outlet />
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "offers");
export default connect(select, {
  ...userActions,
  ...alertActions
})(withRouter(OnboardingIdentity));
