import _ from "lodash";
import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import withRouter from '../../utils/withRouter';
import * as leaguesActions from '../../actions/leagues';
import * as alertActions from '../../actions/alerts';

import Link from '../../components/Link';

function AccountLeagues(props) {
  const {
    user,
    history
  } = props;

  const onClose = () => history.replace("../");

  return (
    <Box>
      <List direction="column">
        {
          _.chain(user)
            .get("favorites")
            .filter(f => f.favoritable_type == "Player")
            .map(f => f.favoritable)
            .map((player, i) => {
              return (
                <ListItem key={i} disablePadding divider={true}>
                    <ListItemText>
                      <Typography variant="body3" color="text.primary" fontWeight={500}>
                        {
                          player.name
                        }
                      </Typography>
                    </ListItemText>
                </ListItem>
              )
            })
            .value()
          }
        </List>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "players");
export default connect(select, {
})(withRouter(AccountLeagues));
