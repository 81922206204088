import _ from "lodash";
import * as React from "react";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Link from '../../../components/Link';

export default function ConsumerFees(props) {
  return (
    <Paper variant="outlined" sx={{p: 3}}>
      <Typography variant="body2" color="text.secondary">
        Shoppers never pay fees with us. We only ever charge merchants
        as a service fee for helping them launch these variable sports offers.
      </Typography>
    </Paper>
  );
}
