import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import * as alertActions from '../actions/alerts';

function Alerts(props) {
  return (
    <React.Fragment>
      {
        _.map(props.alerts, alert => {
          return (
            <Snackbar
              autoHideDuration={alert.autoHideDuration || 6000}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              sx={{zIndex: 10000000}}
              open={true}
              onClose={(event, reason) => {
                if (reason === "clickaway") return;
                props.deleteAlert(alert)
              }}
              key={alert.id}>
              <Alert
                severity={alert.severity || null}
                color={alert.color || "primary"}
                onClose={() => {
                  props.deleteAlert(alert)
                }}
                variant="filled"
                sx={{ width: '100%' }}>
                {alert.title}
              </Alert>
            </Snackbar>
          )
        })
      }
    </React.Fragment>
  );
}

const select = $$state => _.pick($$state, "alerts");
export default connect(select, alertActions)(Alerts);
