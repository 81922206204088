import _ from "lodash";
import * as React from "react";
import Promise from 'bluebird';
import { Outlet, Navigate } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import withRouter from '../../utils/withRouter';
import * as environment from '../../environment';
import * as userActions from '../../actions/user';

import TopNavBar from '../marketing/TopNavBar';
import Footer from './Footer';

function MarketingMain(props) {
  const {
    user,
    history,
    location
  } = props;

  React.useEffect(() => {
    // Extract referral token from URL
    const params = new URLSearchParams(location.search);
    const referralToken = params.get('ref');

    if (referralToken) {
      // Set the referral token in local storage
      window.localStorage.setItem('referralToken', referralToken);
    }
  }, []);

  return (
    <Box>
      <TopNavBar
        {...props}
        user={user}
        history={history}
        location={location} />

      <Box>
        <Box sx={{
          minHeight: "calc(100vh - 150px)",
        }}>
          <Outlet />
        </Box>

        <Footer />
      </Box>
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user"]);
export default connect(select, {
  ...userActions,
})(withRouter(MarketingMain));
