import _ from "lodash";
import { Helmet } from 'react-helmet';
import * as React from "react";
import Promise from 'bluebird';
import { Outlet, Navigate } from "react-router-dom";
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import withRouter from '../../utils/withRouter';
import * as environment from '../../environment';
import * as analytics from '../../utils/analytics';
import * as userActions from '../../actions/user';
import * as offersActions from '../../actions/offers';
import request from '../../utils/request';

import TopNavBar from '../marketing/TopNavBar';
import Footer from '../marketing/Footer';
import Loader from '../../components/Loader';

function PlayersMain(props) {
  const {
    user,
    saveFavorites,
    offers,
    fetchOffers,
    history,
    location,
    params
  } = props;

  const [player, setPlayer] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  const fetchPlayer = async playerId => {
    const { data } = await request.get(`players/${playerId}`)

    if (!!data.id) {
      setPlayer({
        id: data.id,
        ...data.attributes
      })
    }
  };

  const fetchData = async () => {
    await fetchPlayer(params.playerId);
  }

  const title = `Gravy | Save big on everyday expenses when ${_.get(player, ["name"], "")} plays big`

  return (
    <Box>
      <Helmet>
        {/* Standard title tag */}
        <title>
          {title}
        </title>

        <meta property="og:title" content={title} />
        <meta property="og:description" content={title} />
      </Helmet>

      <TopNavBar
        user={user}
        history={history}
        location={location} />

      <Box sx={{
        minHeight: "calc(100vh - 150px)",
      }}>
        {
          loading ?
            <Loader /> :
            <Outlet context={{
                user,
                player,
                saveFavorites,
                offers
              }} />

        }
      </Box>


      <Footer />
    </Box>
  );
}

const select = $$state => _.pick($$state, ["user", "offers"]);
export default connect(select, {
  ...userActions,
  ...offersActions
})(withRouter(PlayersMain));
