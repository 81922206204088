import _ from "lodash";
import Promise from 'bluebird';
import * as React from "react";
import { connect } from 'react-redux';
import { Link as RouterLink, Outlet, Navigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import * as transactionActions from '../../actions/transactions';
import withRouter from '../../utils/withRouter';

import Alerts from '../Alerts';

function TransactionsMain({user, transactions, fetchTransactions}) {
  const location = useLocation();

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetchData().finally(() => setLoading(false))
  }, []);

  const fetchData = async () => {
    return Promise.all([
      fetchTransactions(),
    ])
  }

  return (
    <Box>
      <Box sx={{background: "#f7f7f7"}} minHeight={"100vh"}>
        <Container maxWidth="sm">
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}

const select = $$state => _.pick($$state, "user", "transactions");
export default connect(select, {
  ...transactionActions,
})(withRouter(TransactionsMain));
