import _ from "lodash";
import moment from 'moment';
import { useSwipeable } from 'react-swipeable';
import * as React from "react";
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Outlet, useOutletContext, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import HelpIcon from '@mui/icons-material/Help';
import { Soup, ChartColumnIncreasing } from 'lucide-react'

import Link from '../../components/Link';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import ClickablePaper from '../../components/ClickablePaper';
import * as time from '../../utils/time';
import * as dates from '../../utils/dates';
import request from '../../utils/request';
import withRouter from '../../utils/withRouter';
import currencyFormatter from '../../utils/currencyFormatter';
import * as offerActions from '../../actions/offers';
import * as pickActions from '../../actions/picks';
import * as cardActions from '../../actions/cards';
import FullScreenDialog from '../../components/FullScreenDialog';

import OffersCreate from '../offers/Create';
import OffersCreateError from '../offers/CreateError';

import {useEffect} from "react";
import {usePostHog} from "posthog-js/react";

function FeedItemDetails(props) {
  const {
    cards,
    fetchCards,
    feedItems,
    offers,
    createOffer,
    picks,
    teams,
    user,
    params,
    history
  } = props;

  const posthog = usePostHog();

  const [storyOpen, setStoryOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [activeProposition, setActiveProposition] = React.useState(null);

  const toggleErrorOpen = () => setErrorOpen(!errorOpen);
  const toggleStory = () => setStoryOpen(!storyOpen);

  const feedItem = _.find(feedItems, fi => fi.feedItem.id == params.feedItemId);

  if (_.isEmpty(feedItem)) return <Box />;

  const { story, title, startAt, imageUrl, feedableType, propositions } = feedItem;
  const started = time.getTimeTill(startAt) == "Expired";

  const pick = _.last(picks);

  const onClose = () => history.replace("../");

  const userFavoriteTeams = _.chain(user.favorites)
    .filter(f => f.favoritable_type === "Team")
    .map(f => f.favoritable_id)
    .value()

  const relevantTeams = feedableType === "SportsEvent" ?
    _.chain(propositions)
      .groupBy("teamId")
      .keys()
      .map(id => _.find(teams, t => t.id === id))
      .filter(_.identity)
      .sortBy(t => {
        return _.includes(userFavoriteTeams, t.id) ? 0 : Number.MAX_VALUE;
      })
      .value() :
      [];

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>
        <Box>
          <Typography variant="h6" fontWeight={700} sx={{
            lineHeight: 1.3,
            fontSize: "20px",
            textTransform: "uppercase",
          }}>
            {
              title
            }
          </Typography>

          <Typography mt={0.5} variant="body3" fontWeight={600}>
            {
              started ?
                "This event has started" :
                `Ends ${dates.tzAwareDateTime(startAt, 'dddd, hh:mm A')}`
            }
          </Typography>


            {
              !!story &&
                <Box onClick={toggleStory}>
                  <Typography mt={3} variant="body2" fontWeight={400} color="rgba(255, 255, 255, 1)" style={{
                    fontSize: 20
                  }}>
                    {
                      _.truncate(
                        story, {
                          'length': storyOpen ? story.length : 32,
                          'omission': '...'
                        }
                      )

                    }

                  </Typography>


                  <Stack alignItems="center" justifyContent="center">
                    <IconButton onClick={toggleStory}>
                      {
                        storyOpen ?
                        <ArrowUpwardIcon sx={{
                            color: "#fff",
                              fontSize: 25
                            }} />  :
                          <ArrowDownwardIcon sx={{
                            color: "#fff",
                              fontSize: 25
                            }} />
                      }
                    </IconButton>
                  </Stack>
              </Box>
            }
        </Box>
      </DialogTitle>
      <DialogContent sx={{
        p: 2,
      }}>

        <Box>
          <Box>
            <Stack spacing={2}>
              {
                relevantTeams.length > 0 &&
                  <Tabs value={activeTab} onChange={(e, tab) => {
                    setActiveTab(tab)
                  }}>
                    {
                      _.map(relevantTeams, t => {
                        return (
                          <Tab key={t} sx={{
                            textTransform: "inherit",
                            fontWeight: 600,
                            fontSize: 14
                          }} label={
                            _.capitalize(t.name)
                          }  />
                        )
                      })
                    }
                  </Tabs>
              }

              {
                _.chain(propositions)
                  .filter(p => {
                    const team = relevantTeams[activeTab]
                    if (!!team) return team.id == p.teamId;

                    const offer = _.find(offers, o => o.proposition_id == p.id);

                    if (!!offer) return false;

                    return true
                  })
                  .map((proposition, i) => {
                    const offer = _.find(offers, o => o.proposition_id == proposition.id);

                    const probability = _.get(
                      proposition,
                      ["propositionProbabilities", 0, "probability"], 0
                    )

                    const prize = (pick == null || probability == null) ?
                      0 :
                      _.ceil(pick.revenue_cents / probability) * 10

                    if (!!offer) return <Box />;

                    return (
                      <ClickablePaper sx={{py: 4, px: 3}} key={i} onClick={() => {
                        if (pick == null) {
                          history.push(`/app/earn`)
                        } else {
                          setActiveProposition(proposition)
                        }
                      }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={5}>
                          <Box>
                            <Typography variant="h3" fontWeight={500} lineHeight={1.3}>
                              <b>{!!prize ? `${prize} points` : "Save"}</b> if {
                                _.upperFirst(proposition.winDescription)
                              }
                            </Typography>
                          </Box>
                          <Box>
                            <Button variant="contained" size="small">
                              Pick
                            </Button>
                          </Box>
                        </Stack>

                      </ClickablePaper>
                    )
                  })
                  .value()
              }

            </Stack>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{
        p: 2,
        justifyContent: "start"
      }}>
        <Button onClick={onClose} startIcon={<ArrowBackIosNewIcon />} sx={{fontWeight: 600}}>
          Back
        </Button>
      </DialogActions>

      <Outlet />

      <OffersCreateError
        {...props}
        open={errorOpen}
        onClose={toggleErrorOpen} />

      <OffersCreate
        {...props}
        pick={pick}
        open={!!activeProposition}
        onClose={() => setActiveProposition(null)}
        createOffer={createOffer}
        feedItem={feedItem}
        proposition={activeProposition} />

    </Dialog>

  )
}


const select = $$state => _.pick($$state, ["user", "offers", "cards", "teams", "feedItems", "picks", "merchantOffers"]);
export default connect(select, {
  ...offerActions,
  ...cardActions,
  ...pickActions
})(withRouter(FeedItemDetails));
