import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";
import * as alerts from './alerts';

export const fetchUserGroups = () => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`user_groups`, {
    });

    if (!data.errors) {
      dispatch({
        type: actionTypes.userGroups.INDEX,
        payload: {
          userGroups: _.map(data, c => {
            return {
              id: c.id,
              ...c.attributes
            }
          })
        }
      });
    }

    return data;
  }
};

export const createUserGroup = (payload) => {
  return async (dispatch, getState) => {
    const { data } = await request.post(`user_groups`, payload)

    if (!data.errors && data.id) {
      dispatch({
        type: actionTypes.userGroups.CREATE,
        payload: {
          userGroup: {
            id: data.id,
            ...data.attributes
          }
        }
      });
    }

    return data
  }
}

export const deleteUserGroup = (id) => {
  return async (dispatch, getState) => {
    const { data } = await request.delete(`user_groups/${id}`);

    if (!data.errors) {
      dispatch({
        type: actionTypes.userGroups.DELETE,
        payload: {
          offer: {
            id,
          }
        }
      });
    }

    return data
  }
}