import _ from 'lodash';
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import { connect } from 'react-redux';

import * as analytics from './analytics';
import * as account from './account';
import * as cards from './cards';
import * as dashboard from './dashboard';
import * as demos from './demos';
import * as earn from './earn';
import * as feedItems from './feedItems';
import * as leaderboard from './leaderboard';
import * as onboarding from './onboarding';
import * as help from './help';
import * as marketing from './marketing';
import * as shop from './shop';
import * as picks from './picks';
import * as players from './players';
import * as sportsEvent from './players';
import * as notifications from './notifications';
import * as offers from './offers';
import * as payouts from './payouts';
import * as profiles from './profiles';
import * as referrals from './referrals';
import * as receipts from './receipts';
import * as rewards from './rewards';
import * as surveys from './surveys';
import * as transactions from './transactions';
import * as user from './user';
import * as userGroups from './userGroups';

import * as userActions from '../actions/user';

function Routing(props) {
  const [rendered, setRendered] = React.useState(false);

  let pollingId;

  React.useEffect(() => {
    const user = window.localStorage.getItem("user");

    if (!!user) {
      const parsedUser = JSON.parse(user);

      props.updateUser(parsedUser);

      pollUser(parsedUser)
    }

    setRendered(true);

    return () => clearInterval(pollingId);
  }, [])

  const pollUser = (user) => {
    const pollingId = setInterval(() => {
      props.pingUser(user)
    }, 1000 * 60 * 60);

    return pollingId;
  };

  if (!rendered) return <React.Fragment />;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<analytics.Main />}>

          <Route path="context" element={<user.Contextify />} />

          <Route path="demos">
            <Route path="albertson" element={<demos.Albertson />} />
            <Route path="amazon" element={<demos.Amazon />} />
            <Route path="bofa" element={<demos.Bofa />} />
            <Route path="cashapp" element={<demos.CashApp />} />
            <Route path="crumbl" element={<demos.Crumbl />} />
            <Route path="cowboys" element={<demos.Cowboys />} />
            <Route path="doordash" element={<demos.DoorDash />} />
            <Route path="draftkings" element={<demos.Draftkings />} />
            <Route path="gametime" element={<demos.Gametime />} />
            <Route path="giants" element={<demos.Giants />} />
            <Route path="instacart" element={<demos.Instacart />} />
            <Route path="rakuten" element={<demos.Rakuten />} />
            <Route path="ranchers" element={<demos.Ranchers />} />
            <Route path="rhone" element={<demos.Rhone />} />
            <Route path="hotel" element={<demos.Hotel />} />
            <Route path="sleeper" element={<demos.Sleeper />} />
            <Route path="travel" element={<demos.Travel />} />
            <Route path="tickets" element={<demos.Tickets />} />
            <Route path="littlecaesars" element={<demos.LittleCaesars />} />
            <Route path="tomthumb" element={<demos.TomThumb />} />
            <Route path="ubereats" element={<demos.UberEats />} />
          </Route>

          <Route path="l">
            <Route path=":offerId" element={<offers.Landing />} />
          </Route>

          <Route path="p">
            <Route path=":offerId" element={<offers.Details />}>
            </Route>
          </Route>

          <Route path="app" element={<dashboard.Main />}>
            <Route path="account" element={<account.Main />}>
              <Route path="" element={<account.Details />}>
                <Route path="plaid" element={<account.Plaid />} />
              </Route>

              <Route path="interests" element={<account.Interests />} />

              <Route path="surveys" element={<surveys.Main />}>
                <Route path="" element={<surveys.List />} />
              </Route>

              <Route path="referrals" element={<account.Referrals />}>
                <Route path="" element={<referrals.List />}>
                  <Route path="new" element={<referrals.New />} />
                </Route>
              </Route>

              <Route path="savings" element={<payouts.Main />}>
                <Route path="" element={<payouts.List />}>
                  <Route path=":payoutId" element={<payouts.Details />} />
                </Route>
              </Route>

              <Route path="groups" element={<userGroups.Main />}>
                <Route path="" element={<userGroups.List />}>
                  <Route path="new" element={<userGroups.New />} />
                </Route>

                <Route path=":userGroupId" element={<userGroups.Details />}>
                  <Route path="invite" element={<userGroups.Invite />} />
                </Route>
              </Route>

              <Route path="favorites" element={<account.Favorites />} />

              <Route path="fantasy" element={<account.Fantasy />} />

            </Route>

            <Route path="cards" element={<cards.Main />}>
              <Route path="new" element={<cards.New />} />
              <Route path="issues" element={<shop.Issues />} />
              <Route path="gravy" element={<shop.Details />} />
              <Route path="instructions" element={<cards.Instructions />} />
              <Route path=":cardId" element={<cards.Details />} />
            </Route>

            <Route path="profiles">
              <Route path=":username" element={<profiles.Main />} />
            </Route>

            <Route path="leaderboard" element={<leaderboard.Main />} />

            <Route path="activities" element={<offers.Main />}>
              <Route path="" element={<offers.List />}>

                <Route path=":offerId" element={<offers.Details />}>
                  <Route path="instructions" element={<offers.Instructions />} />
                </Route>
              </Route>
            </Route>

            <Route path="transactions" element={<transactions.Main />}>
              <Route path="" element={<transactions.List />} />
            </Route>

            <Route path="">
              <Route path="" element={<dashboard.Home />}>
                <Route path="points" element={<shop.Points />} />]
                <Route path="receipts" element={<receipts.Details />} />
                <Route path="payout" element={<payouts.New />} />
                <Route path="transactions" element={<transactions.List />} />
              </Route>

              <Route path="shop" element={<shop.Main />}>
                <Route path="" element={<shop.List />}>
                  <Route path=":merchantOfferId" element={<shop.Details />} />
                </Route>
              </Route>

              <Route path="earn" element={<earn.Main />}>
                <Route path="" element={<earn.List />}>
                </Route>
              </Route>

              <Route path="feed" element={<feedItems.Main />}>
                <Route path="" element={<feedItems.List />}>
                  <Route path=":feedItemId" element={<feedItems.Details />}>
                    <Route path=":offerId" element={<offers.Details />}>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>

          </Route>

          <Route path="waitlist" element={<marketing.Waitlist />} />


          <Route path="" element={<marketing.Main />}>
            <Route path="" element={<marketing.Home />} />

            <Route path="how-it-works" element={<marketing.How />} />
            <Route path="about" element={<marketing.About />} />
            <Route path="pricing" element={<marketing.Pricing />} />
            <Route path="privacy" element={<marketing.Privacy />} />
            <Route path="terms" element={<marketing.Terms />} />

            <Route path="faq" element={<help.Main />}>
              <Route index element={<help.Home />} />

              <Route path=":category">
                <Route index element={<help.Category />} />
                <Route path=":page" element={<help.Details />} />
              </Route>
            </Route>
          </Route>

          <Route path="groups">
            <Route path=":userGroupId" element={<userGroups.Join />} />
          </Route>

          <Route path="players" element={<players.Main />}>
            <Route path=":playerId" element={<players.Details />} />
          </Route>

          <Route path="onboarding" element={<onboarding.Main />}>
            <Route path="" element={<onboarding.Signup />}>
              <Route path="activation" element={<onboarding.Activation />} />
            </Route>

            <Route path="identity" element={<onboarding.Identity />}>
              <Route path="football" element={<onboarding.Football />} />

            </Route>

            <Route path="interests" element={<onboarding.Interests />} />
            <Route path="leagues" element={<onboarding.Leagues />} />
            <Route path="teams" element={<onboarding.Teams />} />
          </Route>

          <Route path="rewards" element={<rewards.Main />}>
            <Route path=":rewardId" element={<rewards.Details />}>
              <Route path="category" element={<rewards.Category />} />
              <Route path="favorites" element={<rewards.Favorites />} />
              <Route path="merchants" element={<rewards.Merchants />} />
              <Route path="feed" element={<rewards.Feed />} />
            </Route>
          </Route>

        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const select = $$state => _.pick($$state, "user");
export default connect(select, {
  ...userActions,
})(Routing);
