import _ from "lodash";
import * as React from "react";
import { Link as RouterLink, useOutletContext } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { connect } from 'react-redux';
import { Navigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Loader from '../../components/Loader';
import Link from '../../components/Link';
import Alert from '../../components/Alert';
import GravyVideo from '../../components/GravyVideo';
import AuthButton from '../user/AuthButton';
import withRouter from '../../utils/withRouter';
import * as environment from '../../environment';
import * as time from '../../utils/time';
import request from '../../utils/request';
import currencyFormatter from '../../utils/currencyFormatter';

import TopNavBar from '../marketing/TopNavBar';

function UserGroupsJoin(props) {
  const {
    params,
    user,
    history,
    location
  } = props;

  React.useEffect(() => {
    if (!!user.id) {
      joinGroup(params.userGroupId)
    } else {
      window.localStorage.setItem("user_group_id", params.userGroupId);
      history.push("/");
    }
  }, [user])

  const joinGroup = async (userGroupId) => {
    if (!!user.id) {
      const { data} = await request.post(`user_groups/${userGroupId}/users`)

      if (data.errors) {
        alert(data.errors.message)
      } else {
        history.push(`/app/offers`)
      }
    } else {
      window.localStorage.setItem("user_group_id", userGroupId);
      history.push("/onboarding");
    }
  }

  return <Box />;
}

const select = $$state => _.pick($$state, "user");
export default connect(select, {

})(withRouter(UserGroupsJoin));
