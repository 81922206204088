import _ from "lodash";
import React, { useState, useEffect } from 'react';
import {
  Box,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  Paper,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import request from '../../utils/request';

function Checkout(props) {
  const { open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{p: 3}}>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>

        <Box mt={2}>
          <Typography variant={"h3"} fontWeight={600} lineHeight={1.3}>
            4256A
          </Typography>
          <Typography variant={"h4"} fontWeight={400} lineHeight={1.3}>
            Apply this code at checkout to
            get 100% off your purchase if the Ranchers cook.
          </Typography>
        </Box>

      </DialogTitle>
      <DialogContent sx={{p: 3}}>

      </DialogContent>
      <DialogActions sx={{p: 3}}>
        <Button variant="contained">
          Copy code
        </Button>

      </DialogActions>
    </Dialog>
  )


}

export default function DemoRanchers(props) {
  const [open, setOpen] = useState(false);
  const [reward, setReward] = useState(null);
  const [checkout, setCheckout] = useState(false);

  const toggleCheckout = () => setCheckout(!checkout);

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchReward()
  }, [])


  const fetchReward = async () => {
    setReward({
      parlay: {
        id: "123",
        title: "Ranchers vs. Mad Drops (4/29/2024)",
        propositions: [{
          win_description: "Christian Alshon hits 3 ATPs"
        }, {
          win_description: "Ranchers win 3 of 5"
        }]
      }
    });
  }

  const { title, propositions } = _.get(reward, ["parlay"], {});

  return (
    <Box>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundImage: `url('https://p70.p4.n0.cdn.zight.com/items/E0uOJ68w/eeee6ac1-2b63-4f53-84f3-a10a1e181906.png?v=e5cb3fe3fee03a2d60cdfc4061fb01ae')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 0, // Ensure the background is behind the dialog
        }}
      />

      <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={{p: 3}}>
          <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box />

              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>

            </Stack>
          </Box>

          <Box mt={2}>
            <Typography variant={"h3"} fontWeight={600} lineHeight={1.3}>
              Rancher Fan Special
            </Typography>
            <Typography variant={"h4"} fontWeight={400} lineHeight={1.3}>
              100% back on your next purchase IF...
            </Typography>
          </Box>

        </DialogTitle>
        <DialogContent sx={{p: 3}}>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              borderRadius: 2,
            }}>
              <Box>
                <Box mb={3}>
                  <Box mt={1}>
                    <Typography variant="body2" fontWeight={600}>
                      Ranchers vs. Mad Drops (04/19, 4pm CST)
                    </Typography>
                    <Typography mb={2} variant="body2" >
                      You win if each of these happens
                    </Typography>

                    <Stack spacing={2}>
                      {
                        _.map(propositions, (prop, i) => {
                          const { win_description } = prop;

                          return (
                            <Stack direction="row" spacing={2} alignItems="center">
                              <Chip sx={{width: 60}} size="small" label={
                                <Typography variant={"body4"} fontWeight={600}>
                                  {
                                    i == 0 ? "IF" : "AND"
                                  }
                                </Typography>
                              } />

                              <Box key={prop.id}>
                                <Typography variant={"body1"} fontWeight={500} lineHeight={1.3}>
                                  {
                                    win_description
                                  }
                                </Typography>

                              </Box>
                            </Stack>
                          )
                        })

                      }
                    </Stack>
                  </Box>
                </Box>
                <Stack spacing={1}>
                  <Button fullWidth variant="contained" size="large" color="secondary" onClick={toggleCheckout}>
                    I want this
                  </Button>
                  <Button fullWidth variant="contained" size="large" color="primary">
                    More choices
                  </Button>
                </Stack>
              </Box>
          </Paper>
        </DialogContent>
        <DialogActions>

        </DialogActions>
      </Dialog>

      <Checkout
        open={checkout}
        onClose={toggleCheckout} />
    </Box>
  );
};
