import _ from "lodash";
import qs from 'qs';
import * as React from "react";
import { connect } from 'react-redux';
import Intercom from '@intercom/messenger-js-sdk';
import { Link as RouterLink, Outlet, Navigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Logo from '../../components/Logo';
import Loader from '../../components/Loader';
import * as feedItemsActions from '../../actions/feedItems';
import * as teamsActions from '../../actions/teams';
import * as offersActions from '../../actions/offers';
import * as propositionActions from '../../actions/propositions';
import * as picksActions from '../../actions/picks';
import withRouter from '../../utils/withRouter';
import * as environment from '../../environment';

import Alerts from '../Alerts';
import AccountPopover from '../user/AccountPopover';
import BottomNavBar from '../navigation/BottomNavBar';
import TopNavBar from '../navigation/TopNavBar';

import Rewarded from '../offers/Rewarded';

function DashboardMain(props) {
  const {
    user,
    feedItems,
    fetchFeedItems,
    location,
    history
  } = props;

  const [loading, setLoading] = React.useState(false);

  const search = qs.parse(
    _.join(_.slice(location.search, 1), "")
  );

  const [plaidOpen, setPlaidOpen] = React.useState(
    search.plaid == 1
  )

  React.useEffect(() => {
    if (!!user.id) {
      // Intercom({
      //   app_id: 'f5a4145w',
      //   user_id: user.id,
      //   name: user.name,
      //   email: user.email,
      //   phone: user.phone,
      //   created_at: user.created_at, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      // });
    }
  }, [user])

  if (!user.id) return <Navigate to="/" />;
  if (user.status == "unregistered") return <Navigate to="/onboarding/identity" />;
  if (!user.beta) return <Navigate to="/waitlist" />;

  return (
    <Box sx={{
      minHeight: "100vh",
      background: "#f5f5f5"
    }}>
      <TopNavBar />

      <Box>

        {
          loading ?
            <Loader /> :
            <Outlet />
        }
      </Box>

      <Alerts />

      <BottomNavBar />

      <Rewarded />
    </Box>
  );
}


const select = $$state => _.pick($$state, "user", "feedItems", "picks", "propositions", "offers", "teams");
export default connect(select, {
  ...feedItemsActions,
  ...picksActions,
  ...propositionActions,
  ...offersActions,
  ...teamsActions,
})(withRouter(DashboardMain));
