import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Link from '../../components/Link';

export default function NotificationDetails(props) {
  const { notification, deleteNotification } = props;

  const notificationMessage = notification => {
    switch(notification.notifiable_type) {
      case "DatingProfileReview":
        return "Your dating profile has been reviewed."
      case "DatingProfileExpertRequest":
        return "Your expert request to design a dating profile has an update."
      case "Test":
        return "Your content experiment has completed."
      case "TestVote":
        return "New content experiments require your review."
      default:
        return notification.content.message;
    }
  }

  const notificationUrl = notification => {
    switch(notification.notifiable_type) {
      case "DatingProfileReview":
        return `/app/profiles/${notification.content.dating_profile_id}/edit/reviews/${notification.notifiable_id}`;
      case "DatingProfileExpertRequest":
        return `/app/profiles/requests/${notification.notifiable_id}`;
      case "Test":
        return "/app/tests";
      case "TestVote":
        return "/app/tests/votes"
      default:
        return null
    }
  }

  const message = notificationMessage(notification);
  const url = notificationUrl(notification);

  return (
    <Paper variant="outlined" sx={{
      p: {
        xs: 1,
        sm: 2
      }
    }}>
      <Stack direction="row" justifyContent="space-between" spacing={3}>
        <Box>
          {
            !!url ?
              <Link color="secondary" variant="body3"
                fontWeight={500}
                component={RouterLink}
                to={url}>
                {
                  message
                }
              </Link> :
              <Typography variant="body3" fontWeight={500}>
                { message }
              </Typography>
          }

          <Typography variant="body3" color="text.secondary">
            {
              moment.unix(notification.created_at).format("MMMM Do")
            }
          </Typography>
        </Box>

        <IconButton onClick={() => deleteNotification(notification.id)}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </Paper>
  );
}
