import _ from "lodash";
import request from '../utils/request';

import actionTypes from "../constants/index";

export const fetchPayouts = query => {
  return async (dispatch, getState) => {
    const { data } = await request.get(`payouts`, {
      params: {
        limit: 20,
        offset: 0,
        ...query,
      }
    });

    if (!data.errors) {
      dispatch({
        type: actionTypes.payouts.INDEX,
        payload: {
          payouts: _.map(data, d => {
            return {
              id: d.id,
              ...d.attributes
            }
          })
        }
      });
    }

    return data;
  }
};

export const createPayout = payload => {
  return async (dispatch, getState) => {
    const { data } = await request.post(`payouts`, payload)

    if (!data.errors) {
      dispatch({
        type: actionTypes.payouts.CREATE,
        payload: {
          payout: {
            id: data.id,
            ...data.attributes
          }
        }
      });

      dispatch({
        type: actionTypes.points.UPDATE,
        payload: {
          points: payload.amount_cents
        }
      });
    }

    return data;
  }
};