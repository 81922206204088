import _ from "lodash";
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Link from '../../components/Link';
import Alert from '../../components/Alert';
import FullScreenDialog from '../../components/FullScreenDialog';

import GravyLogo from '../../icons/GravyLogo';
import UberEatsLogo from '../../icons/UberEatsLogo';
import RoLogo from '../../icons/RoLogo';
import SeatgeekLogo from '../../icons/SeatgeekLogo';
import DoorDashLogo from '../../icons/DoorDashLogo';

import GravyCard from '../../assets/images/GravyCard.png';

import AuthButton from '../user/AuthButton';


export default function OffersPreview(props) {
  const { open, onClose } = props;

  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const offers = [{
    icon: <UberEatsLogo />,
    title: "100% refund on your next order if",
    proposition: "Your favorite PG scores 50+"
  }, {
    icon: <RoLogo />,
    title: "100% refund on your next order if",
    proposition: "Your favorite pitcher throws a shutout"
  }, {
    icon: <SeatgeekLogo height={27} />,
    title: "100% refund on your next order if",
    proposition: "Coco Gauff wins Wimbledon"
  }]

  return (
    <FullScreenDialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box />

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box pb={2}>
          <Typography variant="body2" fontWeight={600}>
            Gravy offers
          </Typography>

          <Typography variant="body3" color="text.secondary" fontWeight={500}>
            Slick deals from big brands on Gravy
          </Typography>
        </Box>

        <Box pb={4}>
          <Stack spacing={1}>
            {
              _.map(offers, offer => {
                return (
                  <Paper key={offer.title} variant="outlined" sx={{p: 2}}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      {
                        offer.icon
                      }

                      <Box>
                        <Typography variant="body3" fontWeight={600}>
                          {offer.title}
                        </Typography>
                        <Typography variant="body3" color="text.secondary" fontWeight={500}>
                          {offer.proposition}
                        </Typography>
                      </Box>
                    </Stack>
                  </Paper>
                )
              })
            }
          </Stack>
        </Box>
      </DialogContent>
    </FullScreenDialog>
  );
}
