import _ from "lodash";

import actionTypes from "../constants/index";

export default ($$state = {}, action) => {
  const { type, name, payload } = action;

  switch (type) {
    case actionTypes.picks.CREATE:
      return [
        payload.pick,
        ...$$state,
      ]
    case actionTypes.picks.INDEX:
      return payload.picks
    case actionTypes.picks.DELETE:
      return _.reject($$state, p => p.id == payload.id)
    default:
      return $$state;
  }
};
