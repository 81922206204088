import _ from "lodash";
import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function ConditionalRebateHistory(props) {
  return (
    <Paper variant="outlined" sx={{p: 3}}>
      <Typography variant="body2" color="text.secondary">
        Brands have been offering these sports based conditional offers
        for over 15 years. Two famous examples are
          <Link href="https://www.jordans.com/thecycle/faq" target="_blank">
            Jordan's Cycle offer
          </Link> and Mattress Mack. For most sport fans though, if you've attended live
          games at stadiums, you've no doubt seen offers for free food at various restaurants if something
          happens in a game and others like it.
      </Typography>
    </Paper>
  );
}
