import _ from "lodash";
import qs from 'qs';
import { connect } from 'react-redux';
import * as React from "react";
import { useLocation } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';

import FullScreenDialog from '../../../components/FullScreenDialog';
import request from '../../../utils/request';

import Reward from './Reward';
import Usage from './Usage';
import Overview from './Overview';
import OptIn from './OptIn';

function Progress({progress}) {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress color="secondary" variant="determinate" value={progress} />
    </Box>
  )
}

export default function PourInstructionsMain(props) {
  const { merchant, createAlert, open, onClose } = props;

  const [step, setStep] = React.useState(0)
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    setStep(0)
  }, [open]);

  const save = async () => {
    try {
      setSaving(true)

      const { data } = await request.post(`merchants/${merchant.id}/waitlist`)
      if (data.errors) {

      } else {
        createAlert({
          title: `Added to waitlist for ${merchant.name}!`
        })

        onClose()
      }
    } catch(err) {
      alert("There was an error")
    } finally {
      setSaving(false)
    }
  }
  return (
    <FullScreenDialog open={open} fullWidth maxWidth="xs">
      <Progress progress={(step + 1) / 4 * 100} />

      <DialogTitle>

      </DialogTitle>
      <DialogContent>
        <Box mt={2}>

          {
            step == 0 &&
              <Reward  {...props} />
          }

          {
            step == 1 &&
              <Overview {...props} />
          }

          {
            step == 2 &&
              <Usage {...props}  />
          }

          {
            step == 3 &&
              <OptIn {...props}   />
          }

        </Box>
      </DialogContent>
      {
        step < 3 ?
          <DialogActions sx={{p: 3}}>
            <Button size="large"onClick={() => {
              if (step == 0) {
                onClose();
              } else {
                setStep(step -1)
              }
            }}>
              Back
            </Button>

            <Button size="large" variant="contained" color="secondary" onClick={() => {
              if (step < 3) {
                setStep(step + 1)
              } else {
                onClose()
              }
            }}>
              Next
            </Button>

          </DialogActions> :
          <DialogActions sx={{p: 3}}>
            <Button variant="outlined" size="large" onClick={onClose}>
              No Thanks
            </Button>

            <LoadingButton
              loading={saving}
              onClick={save}
              size="large"
              variant="contained"
              color="secondary">
              Join Waitlist
            </LoadingButton>

          </DialogActions>
      }


    </FullScreenDialog>

  )
}
